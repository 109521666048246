import { getFetcher } from "@/services/global";
import useSWR from "swr";

function useHomeJson(lang, fetchHomeJson) {
  const res = useSWR(
    () => (fetchHomeJson ? `/api/getHomeJson?lang=${lang}` : null),
    getFetcher,
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateOnFocus: false,
    }
  );
  return {
    data: res?.data,
    isLoading: !res?.error && !res?.data,
    isError: res?.error,
  };
}

export default useHomeJson;
