import { AiOutlineClose } from "react-icons/ai";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";

import Login from "./Login";
import Register from "./Register";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import LoginMobile from "./LoginMobile";

function Auth({ Context }) {
  const [login, setLogin] = useState(false);
  const [loginMobile, setLoginMobile] = useState(true);
  const { query } = useRouter();
  const { t } = useTranslation("common");
  useEffect(() => {
    query?.event === "register" && setLogin(false);
  }, [query]);
  return (
    <section className="fixed z-[3000] top-0 left-0 w-screen h-screen bg-lightblack flex items-center justify-center">
      <Toaster position="bottom-center" reverseOrder={true} />
      <div className="bg-white p-3 rounded-md w-[600px] max-[600px]:w-full max-h-[95vh] overflow-y-auto red-scrollbar">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-extrabold">
            {t(!login && !loginMobile ? "CreateNewAccount" : "Login")}
          </h1>
          <AiOutlineClose
            size={24}
            className="cursor-pointer"
            onClick={() => Context?.dispatch({ type: "authModal" })}
          />
        </div>
        <div className="grid grid-cols-2 gap-2 my-2">
          <button
            onClick={() => {
              setLogin(true);
              setLoginMobile(false);
            }}
            className={`w-full ${
              login || loginMobile
                ? "bg-red hover:bg-lightred text-white border-red"
                : "bg-white hover:bg-red hover:text-white text-zinc-500"
            } p-2 rounded-md border`}
          >
            {t("Login")}
          </button>
          <button
            onClick={() => {
              setLogin(false);
              setLoginMobile(false);
            }}
            className={`w-full ${
              !login && !loginMobile
                ? "bg-red hover:bg-lightred text-white border-red"
                : "bg-white hover:bg-red hover:text-white text-zinc-500"
            } p-2 rounded-md border`}
          >
            {t("Register")}
          </button>
        </div>
        {login ? (
          <LoginMobile
            Context={Context}
            setLogin={setLogin}
            setLoginMobile={setLoginMobile}
            loginMobile={loginMobile}
          />
        ) : loginMobile ? (
          <Login
            Context={Context}
            setLogin={setLogin}
            setLoginMobile={setLoginMobile}
            login={login}
          />
        ) : (
          <Register setLogin={setLogin} />
        )}
      </div>
    </section>
  );
}

export default Auth;
