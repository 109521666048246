import { createContext } from "react";
import { intialState } from "./provider";

// Create the Cart context
export const CheckoutContext = createContext();

// checkout reducer function
export const cartReducer = (state, action) => {
  switch (action.type) {
    case "GET_BILLADDRESS":
      return {
        ...state,
        billing_address: action?.payload,
      };
    case "EDIT_BILLING_ADDRESS":
      return {
        ...state,
        billing_address: action?.payload,
      };
    case "GET_ALL":
      return {
        ...state,
        cart: action?.payload,
        items: action?.payload?.items,
      };
    case "GET_SHIPPING_METHODS":
      return {
        ...state,
        shipping_methods: action?.payload,
      };
    case "PAYMENTS":
      const hideInstallments = (state?.cart?.totals?.items || [])?.some(
        (item) => item?.extension_attributes?.is_pre_order === true
      );
      const disabledPaymentMethods = [
        "bs_checkout",
        "madfu_checkout",
        "mispaymethod",
        "tabby_installments",
        // "tamara_pay_by_instalments_4",
        // "tamara_pay_by_instalments_6",
      ];
      const filteredPayments = Object.values(action?.payload || {}).filter(
        (payment) => payment.is_available
      );
      const filteredPayments2 = filteredPayments.filter(
        (payment) => !disabledPaymentMethods.includes(payment.code)
      );
      return {
        ...state,
        payments: hideInstallments ? filteredPayments2 : filteredPayments,
      };
    case "GET_REGIONS":
      return {
        ...state,
        shipping_methods: action?.payload,
      };
    case "GET_TEL":
      return {
        ...state,
        tel: action?.payload,
      };
    case "VERIFY_MOBILE":
      const { number: mobile, status } = action.payload;
         return {
        ...state,
        mobiles: { ...state.mobiles, [mobile]: status },
      };
    case "RESET_CHECKOUT":
      return intialState
    default:
      return state;
  }
};