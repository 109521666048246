import { useSession } from "next-auth/react";
import { createContext, useCallback, useLayoutEffect, useState } from "react";
import store from "store";

const AWSPersonalizeCtx = createContext({
  isDirectConnectToAWS: false,
  firstActionAfterLoginHandler: () => {},
  sendPersonalizeEvent: () => {},
});

export const AWSPersonalizeProvider = ({ children }) => {
  // used if I am not authenticated
  const [sessionId, setSessionId] = useState(store.get("sessionId") || "");
  const [isFirstActionAfterLogin, setIsFirstActionAfterLogin] = useState(false);
  const { data: userSession, status } = useSession();

  useLayoutEffect(() => {
    if (status === "loading") return;

    if (userSession?.user && !isFirstActionAfterLogin) {
      setSessionId("");
      store.remove("sessionId");
    } else if (!sessionId && !userSession?.user) {
      const generateSessionId = () => {
        return (
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          Date.now()
        );
      };

      const createdSessionId = generateSessionId();
      store.set("sessionId", createdSessionId);
      setSessionId(createdSessionId);
    }
  }, [sessionId, userSession?.user, isFirstActionAfterLogin, status]);

  const firstActionAfterLoginHandler = () => {
    setIsFirstActionAfterLogin(true);
  };

  // helper for events
  // data param should be Object { itemId: 'prod sku', eventType: 'View' / 'Purchase' }
  const modifyDataHandler = useCallback(
    (data) => {
      const modifiedData = {
        ...(sessionId
          ? { sessionId }
          : userSession?.user
          ? { sessionId: String(userSession?.user?.id) }
          : {}),
        ...(userSession?.user ? { userId: String(userSession?.user?.id) } : {}),
        ...data,
      };
      return modifiedData;
    },
    [sessionId, userSession?.user]
  );

  /// events ///
  // direct connect with aws
  const sendPersonalizeEvent = async (eventList) => {
    const personalizeEvent = modifyDataHandler({
      eventList,
    });

    try {
      const response = await fetch("/api/aws/event", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(personalizeEvent),
      });

      if (!response.ok) {
        throw new Error("Failed to send event to API");
      }

      const data = await response.json();
      setIsFirstActionAfterLogin(false);
      console.log("Event sent successfully:", data);
    } catch (error) {
      console.error("Error sending event to API route:", error);
    }
  };

  return (
    <AWSPersonalizeCtx.Provider
      value={{
        isDirectConnectToAWS:
          status !== "loading" && (!!sessionId || !!userSession?.user?.id),
        isFirstActionAfterLogin,
        firstActionAfterLoginHandler,
        sendPersonalizeEvent,
      }}
    >
      {children}
    </AWSPersonalizeCtx.Provider>
  );
};

export default AWSPersonalizeCtx;
