import { AppContext } from "@/contexts/appContexts";
import { useContext } from "react";
import store from "store";

const SavedWords = ({ savedSearchWords, title = "", applySearch }) => {
  const { setSavedSearchWords } = useContext(AppContext);

  const savedSearchWordsChecker = (item, index) =>
    !savedSearchWords?.some(
      (otherWord, otherIdx) => otherIdx !== index && otherWord?.startsWith(item)
    );

  const removeSavedSearchWords = () => {
    setSavedSearchWords([]);
    store.set("savedSearchWords", []);
  };

  return (
    <div className="flex-1 border-b-2 border-gray-200 bg-blue-100 py-6 ">
      <div className="flex justify-between">
        <h2 className="text-md font-semibold text-gray-600 mb-3">{title}</h2>
        <button
          className="text-red font-bold rounded"
          onClick={removeSavedSearchWords}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            version="1"
            viewBox="0 0 512 512"
            fill="none"
          >
            <path
              fill="currentColor"
              d="M1871 5109c-128-25-257-125-311-241-37-79-50-146-50-259v-89h-389c-222 0-411-4-442-10-187-36-330-184-361-376-30-181 66-371 231-458l69-36 6-58c4-31 65-763 136-1627 72-863 134-1587 139-1608 36-155 177-295 335-332 91-22 2561-22 2652 0 158 37 299 177 335 332 5 21 67 745 139 1608 71 864 132 1596 136 1627l6 58 69 36c165 87 261 277 231 458-31 192-174 340-361 376-31 6-220 10-442 10h-389v89c0 48-5 112-10 142-34 180-179 325-359 359-66 12-1306 12-1370-1zm1359-309c60-31 80-78 80-190v-90H1810v90c0 110 20 159 78 189 36 19 60 20 670 21 615 0 634-1 672-20zm1200-600c45-23 80-80 80-130s-35-107-80-130c-39-20-55-20-1870-20s-1831 0-1870 20c-45 23-80 80-80 130 0 48 35 107 78 129 36 20 65 20 1870 21 1818 0 1833 0 1872-20zm-236-622c-2-24-63-747-134-1606-140-1694-129-1598-193-1646l-28-21H1281l-28 21c-64 48-53-48-193 1646-71 859-132 1582-134 1606l-6 42h3280l-6-42z"
              transform="matrix(.1 0 0 -.1 0 512)"
            ></path>
            <path
              fill="currentColor"
              d="M1587 3299c-25-13-45-34-58-62l-21-43 76-1234c85-1381 76-1299 155-1340 51-25 91-25 142 0 27 14 46 34 60 63l21 43-76 1234c-85 1381-76 1299-155 1340-50 25-95 25-144-1zM2488 3299c-23-12-46-35-58-59-20-39-20-57-20-1280 0-1224 0-1241 20-1280 23-45 80-80 130-80s107 35 130 80c20 39 20 56 20 1280s0 1241-20 1280c-37 73-127 99-202 59zM3387 3299c-77-41-68 37-153-1339l-76-1234 21-43c14-29 33-49 60-63 51-25 91-25 142 0 79 41 70-41 155 1340l76 1234-21 43c-37 75-127 103-204 62z"
              transform="matrix(.1 0 0 -.1 0 512)"
            ></path>
          </svg>
        </button>
      </div>
      <div className="flex flex-wrap gap-2">
        {savedSearchWords?.map(
          (item, index) =>
            savedSearchWordsChecker(item, index) && (
              <button
                key={index}
                onClick={() => {
                  applySearch(item);
                }}
                className="bg-gray-200 px-3 pt-1 pb-1.5 rounded-full hover:bg-red hover:text-white"
              >
                {item}
              </button>
            )
        )}
      </div>
    </div>
  );
};

export default SavedWords;
