// components/CookieConsent.js
import { getCookie, setCookie } from "cookies-next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { MdCheck } from "react-icons/md";
import CookieSettingModal from "./CookieSettingModal";

const CookieConsent = () => {
  const { locale } = useRouter();
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const consent = getCookie("cookieConsent");
    if (!consent) {
      setShowPopup(true);
    }
  }, []);

  const acceptCookies = () => {
    setCookie("cookieConsent", "true", { maxAge: 86400 * 365 });
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <>
      <section className="fixed bottom-0 start-0 w-screen bg-white z-[999999999] shadow-inner pt-12 max-[768px]:pt-6 pb-8 max-[768px]:pb-3">
        <div className="flex items-center justify-between w-11/12 mx-auto max-[768px]:gap-5 max-[768px]:flex-col">
          <div className="flex flex-col w-3/4 gap-2 max-[768px]:w-full">
            <h2 className="text-lg font-semibold max-[768px]:text-sm">
              {locale === "ar"
                ? " المنيع يقدر خصوصيتك !"
                : "Almanea values ​​your privacy!"}
            </h2>
            <p className="text-sm max-[768px]:text-[9px]">
              {locale === "ar"
                ? `على هذا الموقع نستخدم ملفات تعريف الإرتباط ووظائف مماثله لمعالجه
            المعلومات والبيانات الشخصية ( على سبيل المثال عنوان IP ومعلومات
            المتصفح ). تستخدم المعالجه لأغراض مثل دمج وتخصيص المحتوى والتحليل
            ال‘حصائى / القياس والإعلانات المخصصة ودمج وسائل التواصل الإجتماعى.
            هذه الموافقه طوعيه وليست مطلوبه لاإستخدام موقعنا ويمكن إلغاؤها فى اى
            وقت من تفضيلات الإستخدام`
                : `On this site we use cookies and similar functions to process personal information and data (e.g. IP address and browser information). The processing is used for purposes such as combining and personalizing content, statistical analysis/measurement, personalized advertising and social media integration. This consent is voluntary and is not required to use our site and can be revoked at any time from the usage preferences`}
            </p>
          </div>
          <div className="flex flex-col items-center gap-4 max-[768px]:gap-2">
            <button
              onClick={acceptCookies}
              className="flex items-center justify-center w-40 gap-1 p-1.5 text-white rounded-md bg-blue"
            >
              <MdCheck />
              <span className="text-sm max-[768px]:text-[9px] font-semibold">
                {locale === "ar" ? "قبول الكل" : "Accept all"}
              </span>
            </button>
            <button
              onClick={() => setShowModal(true)}
              className="w-40 max-[768px]:text-[9px] p-1.5 text-sm font-semibold text-white rounded-md bg-blue"
            >
              {locale === "ar" ? "الإعدادات" : "Settings"}
            </button>
            <div className="flex items-center gap-4">
              <Link
                href="/static/privacy-policy"
                className="text-xs max-[768px]:text-[8px] text-gray-500"
              >
                {locale === "ar" ? "تعرف على المزيد" : "Learn more"}
              </Link>
              <span className="text-gray-500"> | </span>
              <Link
                href="/static/terms-conditions"
                className="text-xs max-[768px]:text-[8px] text-gray-500"
              >
                {locale === "ar" ? "الشروط والأحكام" : " Terms & Conditions"}
              </Link>
              <span className="text-gray-500"> | </span>
              <Link
                href="/static/privacy-policy"
                className="text-xs max-[768px]:text-[8px] text-gray-500"
              >
                {locale === "ar" ? "إشعار قانونى" : "Legal notice"}
              </Link>
            </div>
          </div>
        </div>
      </section>
      {showModal ? (
        <CookieSettingModal {...{ setShowModal, acceptCookies, locale }} />
      ) : null}
    </>
  );
};

export default CookieConsent;

/*
<div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center z-[1000]">
      <p className="text-sm">
        {locale === "ar"
          ? "نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك. بمواصلة التصفح، فإنك توافق على استخدامنا لملفات تعريف الارتباط."
          : "We use cookies to improve your experience. By continuing to browse, you accept our use of cookies."}
      </p>
      <button
        onClick={acceptCookies}
        className="px-4 py-2 text-white rounded bg-blue hover:bg-blue-600"
      >
        {locale === "ar" ? "موافق" : "Accept"}
      </button>
    </div>

*/
