import React from "react";

export default function PreOrderLabel({isPreOrder, label, className = '', fromPDP = false}) {
  return (
    <div className={`${!fromPDP ? 'h-6' : ''} ${ className}`}>
      {isPreOrder && label && (
        <div className="w-full relative group inline-flex items-center rounded-md border border-emerald-500 bg-emerald-50/50 px-2.5 py-0.5 text-emerald-700">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="-ms-1 me-1.5 size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <p className="w-full text-xs line-clamp-1 text-ellipsis">{label}</p>
       
        </div>
      )}
    </div>
  );
}
