import { useContext, useRef, useState } from "react";
import { addItemToCart, createCart } from "@/services/cart/cart";
import {
  getAmastyProductsBySkus,
  getProductsBySkuV2,
} from "@/services/elasticsearch/getProducts";
import { useCart } from "@/contexts/cart/CartContext";
import { ModalContext } from "@/pages/_app";
import { toast } from "react-hot-toast";
import { AppContext } from "@/contexts/appContexts";
import { useRouter } from "next/router";
import { ADD_PRODUCT_FEES } from "@/services/cart";

// import Spinner from "../icons/Spinner";
import Cookies from "js-cookie";
// import useGetRelatedProducts from "@/hooks/global/useGetRelatedProducts";
import { event_add_to_cart, event_cart_updated } from "@/utils/gtm";
import Link from "next/link";

const AddtoCart = ({
  product,
  sku,
  productOptions,
  qty = 1,
  // relatedProducts,
  fromPDP = false,
}) => {
  const selectedCity = Cookies.get("region_id");
  const handshake = Cookies.get("handshake");
  const mage = Cookies.get("mage");
  const [quote, region_id] = ["quote", "region_id"].map((cookie) =>
    Cookies.get(cookie)
  );
  const [loading, setLoading] = useState(false);
  const { locale, pathname, push } = useRouter();
  const Context = useContext(ModalContext);

  const { setOpenSelectCityPopUp, setProductInfo, isSearch, setIsSearch } =
    useContext(AppContext);
  // const { trigger: triggerGetRelatedProducts } = useGetRelatedProducts();
  const { dispatch, cart } = useCart();
  const addToCartRef = useRef();
  const handleAddProdFees = async (id, options, itemId) => {
    const params = { id, options, itemId };
    const { error } = await ADD_PRODUCT_FEES(params); //id, options,itemId
    if (error === null) {
      return toast.success(
        locale === "ar"
          ? "تم إضافة الخدمة بنجاح"
          : "The service has been added successfully"
      );
    }
  };
  const handleAutoAddProductFees = (itemId) => {
    if (product?.product_fees?.length) {
      product?.product_fees?.forEach((fee) => {
        const feeOptions = Object.values(fee?.options) || [];
        if (feeOptions?.length) {
          feeOptions?.forEach(({ fee_id, fee_option_id }) =>
            handleAddProdFees(fee_id, fee_option_id, itemId)
          );
        }
      });
    }
    return null; // no product fees for this product
  };

  const handleAddItemToCart = (isFast = false) => {
    if (product?._configurables?.length && !pathname.startsWith("/product/")) {
      push(`/product/${product?.rewrite_url}`);
      if (isSearch) return setIsSearch("");
    }
    setLoading(isFast === true ? "fast" : "add");
    // const selectedAtteributes = [
    //   "sku",
    //   "name",
    //   "option_text_brand",
    //   "prices_with_tax",
    //   "image",
    //   "stock",
    //   "label",
    //   "rewrite_url",
    //   "attributes",
    //   "eligible_for_tabby",
    //   "eligible_for_tamara",
    //   "product_fees",
    //   "back_orders",
    //   "custom_related_products",
    //   "_configurables",
    //   "_category_",
    //   "_media_",
    // ];

    // check if user selected city or not
    if (
      selectedCity &&
      selectedCity !== "NaN" &&
      !isNaN(parseInt(selectedCity))
    ) {
      // 1- add product to cart
      const add_to_Cart = () => {
        addItemToCart(
          handshake,
          mage,
          quote,
          sku ? sku : product?.sku,
          qty,
          region_id,
          productOptions
        )
          .then(({ error, data, err, status }) => {
            if (status === 401) {
              dispatch({ type: "RESET_CART" });
              createCart(handshake, mage).then((res) => {
                res?.data?.quote && Cookies.set("quote", res?.data?.quote);
              });
            }
            if (error !== null) {
              return toast.error(
                err?.response?.data?.error?.message ||
                  (locale === "ar"
                    ? "عفوا الطلب مرتفع على هذا المنتج وأصبح غير متاح للطلب، سيتم توفيره قريبًا."
                    : "Product that you are trying to add is out of stock")
              );
            }

            // auto add product fees
            data?.cart?.item_id &&
              handleAutoAddProductFees(data?.cart?.item_id);

            // 2- check if quote equal our current quote ===> if not reset cart and save new quote
            if (data?.quoteId !== quote) {
              dispatch({ type: "RESET_CART" });
            }
            Cookies.set("quote", data?.quoteId);

            // 3- add product to cart context after added to cart
            const image = `${
              process.env.NEXT_PUBLIC_IMAGE_BASEURL
            }/media/catalog/product${
              product?.image ? product?.image?.at(0) : ""
            }`;
            const productInfo = {
              ...data?.cart,
              ...product,
              image,
              prices_with_tax: product?.prices_with_tax,
              back_orders: product?.back_orders,
            };
            dispatch({
              type: "ADD_ITEM",
              payload: productInfo,
            });

            const productName = Array.isArray(product?.name)
              ? product?.name?.at(0)
              : product?.name;
            const productBrand = Array.isArray(product?.option_text_brand)
              ? product?.option_text_brand?.at(0)
              : product?.option_text_brand;
            event_add_to_cart(
              sku ? sku : product?.sku,
              productName,
              qty,
              product?.prices_with_tax?.price,
              image,
              productBrand,
              product?.type_id,
              product?._category_?.main_category?.category_id,
              product?._category_?.main_category?.name,
              product?._category_?.sub_categories?.at(0)?.category_id,
              product?._category_?.sub_categories?.at(0)?.name
            );
            console.log("product", product, cart?.cartItems);
            const items = [
              ...(cart?.cartItems || []),
              { ...product, prices_incl_tax: product.prices_with_tax, qty },
            ].map((item) => ({
              ...item,
              price_incl_tax: item.price || item?.prices_with_tax?.price,
              name: Array.isArray(item.name) ? item.name?.at(0) : item.name,
              categoryname: item?._category_?.main_category?.name,
              subcategoryname: item?._category_?.sub_categories?.at(0)?.name,
            }));
            const skus = items?.map((item) => item?.sku)?.join(", ");
            const names = items?.map((item) => item?.name)?.join(", ");
            const grand_total = items?.reduce(
              (a, item) => a + +item?.prices_with_tax?.price,
              0
            );
            const subtotal = 0.85 * grand_total;
            const tax = 0.15 * grand_total;
            console.log("xyz________________________", {
              grand_total,
              subtotal,
              tax,
            });
            const cartData = [
              items,
              items?.length,
              skus,
              names,
              Number(subtotal.toFixed(2)),
              Number(tax.toFixed(2)),
              Number(grand_total.toFixed(2)),
            ];
            event_cart_updated(...cartData);
            getAmastyProductsBySkus(
              handshake,
              product?.sku,
              "product_into_related"
            ).then(({ data: getAmastyProductsBySkusData }) => {
              const currRelatedProducts =
                getAmastyProductsBySkusData?.data?.[0]?.related;

              if (currRelatedProducts?.length) {
                getProductsBySkuV2(currRelatedProducts, handshake, [
                  // "custom_related_products",
                ]).then(({ data: getProductsBySkuV2Data }) => {
                  if (getProductsBySkuV2Data?.length) {
                    isFast === true
                      ? push("/checkout")
                      : setTimeout(() => {
                          Context?.dispatch({
                            type: "cartModal",
                            payload: product?.sku,
                            payloadRelatedProducts: getProductsBySkuV2Data,
                            productInfo,
                          });
                        }, 0);
                  } else {
                    isFast === true
                      ? push("/checkout")
                      : setTimeout(() => {
                          Context?.dispatch({
                            type: "cartModal",
                            payload: product?.sku,
                            payloadRelatedProducts: [],
                            productInfo,
                          });
                        }, 0);
                  }
                });
              } else {
                isFast === true
                  ? push("/checkout")
                  : setTimeout(() => {
                      Context?.dispatch({
                        type: "cartModal",
                        payload: product?.sku,
                        payloadRelatedProducts: [],
                        productInfo,
                      });
                    }, 0);
              }
            });
          })
          .finally(() => {
            setLoading(false);
          });
      };
      if (product?._configurables?.length) {
        if (productOptions === null) {
          toast.error(
            locale === "ar"
              ? "يرجى تحديد خيارات المنتج أولاً قبل إضافته لعربة التسوق"
              : "Please select product options and add it to cart again"
          );
          return setLoading(false);
        } else {
          add_to_Cart();
        }
      } else {
        add_to_Cart();
      }
    } else {
      setOpenSelectCityPopUp(true);
      product?.sku && setProductInfo({ product, addToCartRef });
      return setLoading(false);
    }
  };
  const regionId =
    region_id && region_id != "NaN" && region_id != "undefined"
      ? region_id
      : "1101";
  if (product?.not_for_sale?.[0] == true)
    return (
      <div className="w-full p-2 my-2 text-sm text-center text-white transition-colors duration-500 rounded-full bg-red hover:bg-lightred disabled:bg-lightred">
        {locale === "ar" ? "متاح بالمتاجر فقط" : "Available on stores"}
      </div>
    );

  const is_in_stock = product && product[regionId]?.value > 0;
  const is_preorder = product && product?.back_orders === 101;

  // redirect to PDP if product not for sale
  if (product?.not_for_sale?.[0] === true && !fromPDP) {
    return (
      <Link
        href={`/product/${product?.rewrite_url}`}
        className="w-full px-2 my-2 text-sm text-center text-white rounded-full bg-zinc-500"
      >
        {locale === "en" ? "Show more" : "عرض المزيد"}
      </Link>
    );
  }
  // if product is a product card
  if (!fromPDP) {
    return !is_in_stock ? (
      <button
        disabled
        className=" p-2 flex-grow text-xs text-white rounded-full cursor-not-allowed bg-zinc-500"
      >
        {locale === "en" ? "Out of stock" : "نفذ من المخزون"}
      </button>
    ) : (
      <button
        ref={addToCartRef}
        disabled={loading === "add"}
        onClick={() => handleAddItemToCart(false)}
        className={
          " w-auto p-2 duration-500 transition-colors text-white rounded-full flex-grow text-sm " +
          (!is_preorder
            ? "bg-red hover:bg-lightred disabled:bg-lightred "
            : "bg-blue hover:bg-lightblue disabled:bg-lightblue")
        }
      >
        {loading === "add"
          ? locale == "ar"
            ? "جار الإضافة ..."
            : "Adding product..."
          : !is_preorder
          ? locale == "ar"
            ? "أضف إلى السلة"
            : "Add to Cart"
          : locale == "ar"
          ? "طلب مسبق"
          : "Pre-order"}
      </button>
    );
  }

  return (
    <div className="w-full flex-grow grid grid-cols-1 md:grid-cols-2 gap-4">
      {!is_in_stock ? (
        <button
          disabled
          className="col-span-full p-3 flex-grow text-lg text-white rounded-full cursor-not-allowed bg-zinc-500"
        >
          {locale === "en" ? "Out of stock" : "نفذ من المخزون"}
        </button>
      ) : (
        <>
          <button
            ref={addToCartRef}
            disabled={loading === "fast"}
            onClick={() => handleAddItemToCart(true)}
            className={
              " p-3 duration-500 transition-colors text-white rounded-full text-lg bg-black hover:bg-gray-700 disabled:bg-gray-700 "
            }
          >
            {loading === "fast"
              ? locale == "ar"
                ? "جار الإضافة ..."
                : "Adding product..."
              : !is_preorder
              ? locale == "ar"
                ? "شراء سريع"
                : "Buy now"
              : locale == "ar"
              ? "طلب مسبق"
              : "Pre-order"}
          </button>
          <button
            ref={addToCartRef}
            disabled={loading === "add"}
            onClick={() => handleAddItemToCart(false)}
            className={
              "p-3 duration-500 transition-colors text-white rounded-full flex-grow text-lg " +
              (!is_preorder
                ? "bg-red hover:bg-lightred disabled:bg-lightred "
                : "bg-blue hover:bg-lightblue disabled:bg-lightblue")
            }
          >
            {loading === "add"
              ? locale == "ar"
                ? "جار الإضافة ..."
                : "Adding product..."
              : !is_preorder
              ? locale == "ar"
                ? "أضف إلى السلة"
                : "Add to Cart"
              : locale == "ar"
              ? "طلب مسبق"
              : "Pre-order"}
          </button>
        </>
      )}
    </div>
  );
};

export default AddtoCart;
