import axiosRequest from "@/utils/axios-utils";
const registerApiBack = async (handshake, registerData) => {
  try {
    const response = await axiosRequest.post(
      "/customer/register",
      registerData,
      {
        headers: {
          Authorization: `Bearer ${handshake}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export default registerApiBack;
