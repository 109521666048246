import React, { createContext, useContext, useEffect, useReducer } from "react";
import browserStorage from "store";
// Create the Cart context
const CartContext = createContext();

const itemExists = (cartItems, newItem) => {
  return cartItems?.some((item) => item.item_id === newItem.item_id);
}

// Cart reducer function
const cartReducer = (state, action) => {
  switch (action.type) {
    case "GET_CART":
      return {
        ...state,
        cart: action?.payload,
        cartItems: action?.payload?.items,
      };
    case "LOAD_CART":
      // Your logic to add item to cart
      return {
        ...state,
        cart: action.payload?.cart,
        cartItems: action?.payload?.cartItems,
      };
    case "ADD_ITEMS":
      const items = action.payload
      const updatedCartItems = state.cartItems?.map((item) => {
        if (itemExists(state.cartItems, item)) {
          // Item exists, update quantity
          const newItem = items?.find((newItem) => newItem.item_id === item.item_id);
          return { ...item, quantity: item.qty = newItem?.qty };
        }
        return item;
      })

      // Items that don't exist, add them to cartItems
      const newItems = items?.filter((newItem) => !itemExists(state.cartItems, newItem));
      const mergedCartItems = [...updatedCartItems, ...newItems];

      return { ...state, cartItems: mergedCartItems }
    case "ADD_ITEM":
      // Your logic to update an item in the cart
      const newItem = action?.payload;
      const itemIndex = state?.cartItems?.findIndex(
        (item) => item.item_id === newItem.item_id
      );
      itemIndex >= 0
        ? (state.cartItems[itemIndex].qty = newItem?.qty)
        : state.cartItems?.push(newItem);

      // for Cart Popup
      const cartInfoItemIndex = state?.cartInfo?.findIndex(
        (item) => item.item_id === newItem.item_id
      );

      cartInfoItemIndex >= 0
        ? (state.cartInfo[cartInfoItemIndex].qty = newItem?.qty)
        : state.cartInfo?.push(newItem);
    case "UPDATE_ITEM":
      // Your logic to update an item in the cart
      const { item_id, qty } = action.payload;
      return {
        ...state,
        cartItems: state?.cartItems?.map((item) =>
          item.item_id === item_id ? { ...item, qty: qty } : item
        ),
        cartInfo: state?.cartInfo?.map((item) =>
          item.item_id === item_id ? { ...item, qty: qty } : item
        ),
      };

    case "DELETE_ITEM":
      return {
        ...state,
        cartItems: state?.cartItems?.filter(
          (item) => item.item_id !== action.payload.item_id
        ),
        cartInfo: state?.cartInfo?.filter(
          (item) => item.item_id !== action.payload.item_id
        ),
      };
    case "RESET_CART":
      // Your logic to reset the entire cart
      return {
        cart: {},
        cartItems: [],
      };
    default:
      return state;
  }
};

// Cart context provider component
const CartProvider = ({ children }) => {
  const savedSData = browserStorage.get("cart") || { cart: {}, cartItems: [] };
  const [cart, dispatch] = useReducer(cartReducer, {
    ...savedSData,
    cartInfo: [],
  });

  // Save the cart data to localStorage whenever the cart changes
  useEffect(() => {
    browserStorage.set("cart", cart);
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCart };
