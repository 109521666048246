import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { AppContext } from "@/contexts/appContexts";
import SearchTermsBox from "./SearchTermsBox";
import useOnClickOutside from "@/hooks/useOnClickOutside";

function Search({
  searchValue,
  onSearch,
  showSearchBoxModal,
  setShowSearchBoxModal,
  searchInputRef,
  openSearchTermsRef,
}) {
  const { locale, events } = useRouter();
  const regionId = Cookies.get("region_id");
  const { setOpenSelectCityPopUp, setProductInfo } = useContext(AppContext);

  // Close search box when route changes
  useEffect(() => {
    const handleRouteChange = () => {
      clearSearch();
    };
    events.on("routeChangeStart", handleRouteChange);
    return () => {
      events.off("routeChangeStart", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this is not the main state of search, this used only for handle search input value so don't use it out side the comp
  // the main state is searchValue in layout component
  const [inputValue, setInputValue] = useState("");
  const [openSearchPopupTerms, setOpenSearchPopupTerms] = useState(false);
  useOnClickOutside([openSearchTermsRef], () => setOpenSearchPopupTerms(false));

  useEffect(() => {
    setInputValue(searchValue);
  }, [searchValue]);

  /**
   * Handle Search Functionality
   *
   * This function manages the search process in two distinct approaches:
   *
   * 1. **Initial Search (First-Time User Interaction)**:
   *    - If the user hasn't initiated a search yet, the search popup is triggered.
   *    - The state `openSearchPopupTerms` controls whether the search popup is displayed.
   *    - The user must press "Enter" to confirm and initiate the search.
   *
   * 2. **Subsequent Searches (Debounced Search)**:
   *    - Once the user has performed a search, the popup does not reappear.
   *    - The search functionality then works with a debounce mechanism, triggering the search after a delay when the user stops typing. (useEffect)
   *
   */

  // second approach
  useEffect(() => {
    // Clear any existing timers and set a new one
    const debounce = setTimeout(() => {
      if (!openSearchPopupTerms && showSearchBoxModal) {
        onSearch(inputValue); // This will trigger the `useEffect`
      }
    }, 2000);

    // Clean up previous debounce when inputValue changes
    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, showSearchBoxModal, openSearchPopupTerms]); // Effect will only trigger when searchText changes

  // first approach
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue && !showSearchBoxModal) {
      applySearch(inputValue);
    }
  };

  const handleOnClickXIcon = useCallback(() => {
    onSearch("");
    setInputValue("");
    setShowSearchBoxModal(false);
  }, [onSearch, setShowSearchBoxModal]);

  const handleInputChange = async ({ target: { value } }) => {
    if (regionId) {
      setInputValue(value);
    }
  };

  const handleInputFocus = () => {
    if (!regionId) {
      setOpenSelectCityPopUp(true);
      setProductInfo({ searchInputRef });
    } else if (!showSearchBoxModal) {
      setOpenSearchPopupTerms(true);
    }
  };

  const applySearch = useCallback(
    (value) => {
      onSearch(value);
      setShowSearchBoxModal(true);
      setOpenSearchPopupTerms(false);
    },
    [onSearch, setShowSearchBoxModal]
  );

  const clearSearch = useCallback(() => {
    onSearch("");
    setShowSearchBoxModal(false);
    setOpenSearchPopupTerms(false);
  }, [onSearch, setShowSearchBoxModal]);

  return (
    <>
      <input
        id="search-box"
        ref={searchInputRef}
        placeholder={
          locale === "ar"
            ? "ابحث فى المتجر كامل من هنا"
            : "Search For Any Product Here..."
        }
        className="w-full px-3 py-2 rounded-md outline-none max-sm:placeholder:text-xs max-sm:text-xs"
        autoComplete="off"
        value={inputValue}
        onClick={handleInputFocus}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <div
        className={`${
          inputValue ? "cursor-pointer" : "pointer-events-none"
        } absolute inset-y-0 ${
          locale === "en" ? "right-0" : "left-0"
        } flex items-center pl-3`}
      >
        {!inputValue ? (
          <AiOutlineSearch className="absolute -translate-y-1/2 max-sm:w-4 max-sm:h-4 ltr:right-3 rtl:left-3 top-1/2 text-zinc-500" />
        ) : (
          <ImCross
            onClick={handleOnClickXIcon}
            className={`h-4 w-4 text-gray-400 absolute cursor-pointer ${
              locale === "en" ? "right-3" : "left-3"
            }`}
            aria-hidden="true"
          />
        )}
      </div>

      {openSearchPopupTerms && (
        <SearchTermsBox
          {...{
            openSearchTermsRef,
            inputValue,
            applySearch,
            clearSearch,
          }}
        />
      )}
    </>
  );
}

export default Search;
