import { getFetcher } from "@/services/global";
import { useEffect } from "react";
import useSWR from "swr";
import store from "store";
import { getCookie } from "cookies-next";
import { decodeJwt } from "@/utils/global";
import { useRouter } from "next/router";
import { useSlugs } from "@/contexts/categoryTree";
import { generateSlugs } from "@/utils/category";

export function useCategoryTree(lang, setCategoryData) {
  const handshake = getCookie("handshake");
  const { setSlugs } = useSlugs();
  const { locale } = useRouter();

  const decodedToken = decodeJwt(handshake);

  const { error, data, mutate } = useSWR(
    () => `/api/category/categorynames?lang=${lang}`,
    getFetcher,
    {
      revalidateOnMount: false, // 30 minutes in milliseconds
      revalidateOnFocus: false,
      onSuccess: (res) => {
        setCategoryData(res?.data?.data);
        const slugs = generateSlugs(res?.data?.data?.children_data || []);
        setSlugs(slugs);
        store.set("categoryTreeData", {
          data: res?.data?.data,
          cacheTime: Date.now() + 30 * 60 * 1000,
        });
      },
    }
  );

  useEffect(() => {
    if (
      locale !== decodedToken?.store ||
      (store.get("categoryTreeData") &&
        store.get("categoryTreeData")?.cacheTime < Date.now())
    ) {
      mutate();
      return;
    } else if (store.get("categoryTreeData")) {
      setCategoryData(store.get("categoryTreeData")?.data);
      return;
    } else {
      mutate();
    }
  }, [decodedToken?.store, locale, mutate, setCategoryData]);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    invalidate: () => mutate(`/api/category/categorynames?lang=${lang}`),
  };
}
