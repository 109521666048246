import { useState } from "react";
import {
  AiOutlineEye,
  AiOutlineCheck,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { loginInitialValues, loginValidationSchema } from "@/constants/Auth";
import { TextError } from "../Helpers/InputContainer";
import browserStorage from "store";
import { useCheckout } from "@/contexts/checkout";
import { getSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import { event_user_login } from "@/utils/gtm";
function Login({ Context, setLogin, setLoginMobile, login }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const { locale: lang } = useRouter();
  const [remember, setRemember] = useState(false);
  const [show, setShow] = useState(false);
  const { dispatch } = useCheckout();
  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    import("next-auth/react").then(async ({ signIn }) => {
      try {
        const res = await signIn("credentials", {
          username: values.username,
          password: values.password,
          handshake: getCookie("handshake"),
          callbackUrl: `/`,
          redirect: false,
        });

        if (!res.ok && res.status !== 200) {
          throw new Error("Login failed");
        }
        const session = await getSession();
        const user = session?.user;
        const { firstname, lastname, custom_attributes, email } = user;
        const mobile =
          custom_attributes?.find(
            (item) => item?.attribute_code === "mobilenumber"
          )?.value || null;

        event_user_login(firstname, lastname, mobile, email, null);

        dispatch({ type: "RESET_CHECKOUT" });
        browserStorage.remove("cart");
        browserStorage.remove("checkout");
        Context?.dispatch({ type: "authModal" });
      } catch (error) {
        setFieldError("password", t("InvalidCredentials"));
      } finally {
        setSubmitting(false);
      }
    });
  };

  return (
    <Formik
      initialValues={loginInitialValues}
      validationSchema={loginValidationSchema(t)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="grid grid-cols-2 mb-2">
            <button type="button" className="block w-full mb-1 font-semibold ">
              <span
                onClick={() => {
                  setLogin(false);
                  setLoginMobile(true);
                }}
                className={`py-1 cursor-pointer hover:text-red ${
                  !login ? "border-b border-b-red" : ""
                } hover:border-b-red`}
              >
                {language === "en"
                  ? "Login using password"
                  : "تسجيل بإستخدام كلمة المرور"}
              </span>
            </button>
            <button
              type="button"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              <span
                onClick={() => {
                  setLogin(true);
                  setLoginMobile(false);
                }}
                className="py-1 cursor-pointer hover:text-red hover:border-b-red"
              >
                {language === "en"
                  ? "Login via Otp"
                  : "تسجيل بإستخدام رمز التحقق"}
              </span>
            </button>
          </div>
          <div className="mb-2">
            <label
              htmlFor="username"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("Email")}
            </label>
            <Field
              name="username"
              type="username"
              className="w-full p-2 border rounded-md outline-0"
              placeholder={t("Email")}
              id="username"
            />
            <ErrorMessage name="username" component={TextError} />
          </div>
          <div className="mb-2">
            <label
              htmlFor="password"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("Password")}
            </label>
            <div className="relative">
              <Field
                type={!show ? "password" : "text"}
                className="w-full p-2 border rounded-md outline-0"
                placeholder={t("Password")}
                id="password"
                name="password"
              />
              {!show ? (
                <AiOutlineEye
                  size={24}
                  onClick={() => setShow(!show)}
                  className={`absolute top-1/2 -translate-y-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } text-zinc-400 cursor-pointer`}
                />
              ) : (
                <AiOutlineEyeInvisible
                  size={24}
                  onClick={() => setShow(!show)}
                  className={`absolute top-1/2 -translate-y-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } text-zinc-400 cursor-pointer`}
                />
              )}
            </div>
            <ErrorMessage name="password" component={TextError} />
          </div>
          <div className="flex items-center justify-between mb-2">
            <div
              onClick={() => setRemember(!remember)}
              className="flex items-center"
            >
              <div className="relative w-4 h-4 bg-white border border-gray-300 rounded-sm">
                {remember && (
                  <AiOutlineCheck
                    size={13}
                    className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-red"
                  />
                )}
              </div>
              <span className="mx-2 cursor-pointer text-zinc-600">
                {t("RememberMe")}
              </span>
            </div>
            <button
              onClick={() => Context?.dispatch({ type: "forgotModal" })}
              type="button"
              className="underline text-zinc-600"
            >
              {t("ForgotPassword")}
            </button>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full p-2 font-bold text-white rounded-md bg-red hover:bg-lightred disabled:cursor-wait"
          >
            {t(isSubmitting ? "Logining" : "Login")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default Login;
