// components/Circle.js
import React from "react";
import CurrencyIcon from "./icons/CurrencyIcon";

const Circle = ({ idx, amount, locale }) => {
  const fillParts = idx + 1;

  return (
    <div className="flex flex-col items-center space-y-2">
      <svg
        width="100"
        height="100"
        viewBox="0 0 32 32"
        className="relative h-[50px] w-[50px] bg-slate-100 rounded-full"
      >
        {[...Array(6)].map((_, i) => (
          <path
            key={i}
            d={describeArc(16, 16, 15, i * 60, (i + 1) * 60)}
            fill={i < fillParts ? "currentColor" : "transparent"}
            stroke="none"
            strokeWidth="1"
          />
        ))}
      </svg>
      <h5 className="flex items-center gap-1 font-semibold text-center">
        <span>{amount}</span> <CurrencyIcon className="h-[1cap] w-auto" />
      </h5>
      <h6 className="text-sm text-center text-zinc-500">
        {handleMonthes(idx, locale)}
      </h6>
    </div>
  );
};

function handleMonthes(idx, locale) {
  switch (idx) {
    case 0:
      return locale === "en" ? "Today" : "اليوم";
    case 1:
      return locale === "en" ? "1 Monthes" : "بعد شهر";
    case 2:
      return locale === "en" ? "2 Monthes" : "بعد شهرين";
    case 3:
      return locale === "en" ? "3 Monthes" : "بعد ٣ أشهر";
    case 4:
      return locale === "en" ? "4 Monthes" : "بعد ٤ أشهر";
    case 5:
      return locale === "en" ? "5 Monthes" : "بعد ٥ أشهر";
    default:
      return "";
  }
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x, y, radius, startAngle, endAngle) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
    "L",
    x,
    y,
    "L",
    start.x,
    start.y,
  ].join(" ");

  return d;
}

export default Circle;
