import { useRouter } from "next/router";

const CommonCategory = ({ CategoriesData, title = "", clearSearch }) => {
  const { push } = useRouter();
  return (
    <div className="flex-1 border-b-2 border-gray-200 bg-blue-100 py-6">
      <h2 className="text-md font-semibold text-gray-600 mb-3">{title}</h2>
      <div className="flex flex-wrap gap-2 overflow-y-auto">
        {CategoriesData?.map((item) => (
          <button
            key={item?.id}
            onClick={() => {
              clearSearch();
              push(`/${item?.url_key}`);
            }}
            className="bg-gray-200 px-3 pt-1 pb-1.5 rounded-full hover:bg-red hover:text-white"
          >
            {item?.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CommonCategory;
