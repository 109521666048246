import ProductCard from "@/components/Helpers/ProductCard";
// import ResponsiveSort from "./ResponsiveSort";
import Side from "./Side";
import ResponsiveFilter from "./ResponsiveFilter";
import { FiFilter } from "react-icons/fi";
// import { BiSortUp } from "react-icons/bi";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { useRouter } from "next/router";
import MainLoader from "../Reusable/Loaders/MainLoader";
import { Toaster } from "react-hot-toast";

import { useTranslation } from "next-i18next";
import { event_product_searched } from "@/utils/gtm";
import useOnClickOutside from "@/hooks/useOnClickOutside";
import { handleSearch } from "@/services/header/search";
import { getCookie } from "cookies-next";
import { handleFilterArrayToString } from "@/services/category/getProductsData";
import Loading from "../Loading";
import { hadleFilterStructureData } from "@/utils/category";
import useCategoryAndSearch from "@/hooks/useCategoryAndSearch";
import { AppContext } from "@/contexts/appContexts";

import store from "store";
import { saveSearchTerm } from "@/pages/api/search/term";
import useSWR from "swr";
import { IS_TYPESENSE_SEARCH } from "@/utils/typesense/config";
import { typeSenseMultiSearch } from "@/services/typesense/multiSearch";

function Search({
  onSearch,
  searchValue,
  setShowSearchBoxModal,
  clickRef,
  searchInputRef,
  openSearchTermsRef,
}) {
  const { t } = useTranslation("common");
  const { locale, push } = useRouter();
  const PAGE_SIZE = 20;
  const region_id = getCookie("region_id");
  const handshake = getCookie("handshake");
  const { savedSearchWords, setSavedSearchWords } = useContext(AppContext);

  const [openFilterBox, setOpenFilterBox] = useState(false);

  const [searchCategories, setSearchCategories] = useState([]);
  const [suggestionWords, setSuggestionWords] = useState(null);
  const [filterBy, setFilterBy] = useState([]);

  const previousSearchValue = useRef(null);

  const [isLoadingPage, setIsLoadingPage] = useState(true);

  /// start of search term EP ---------------------------
  // this is not the main state of search, this used only for handle save term debounce EP so don't use it out side the comp
  // the main state is searchValue in layout component
  const [searchTermText, setSearchTermText] = useState("");

  // TODO remove false and condition to return save terms api to work again
  const {} = useSWR(
    false ? `save-terms/${searchTermText}/${locale}` : null,
    () => saveSearchTerm(handshake, searchTermText),
    {
      revalidateOnMount: false,
      revalidateOnFocus: false,
    }
  );

  // Debounce the search term EP with 2 second delay
  useEffect(() => {
    // Clear any existing timers and set a new one
    const debounce = setTimeout(() => {
      if (searchValue.length >= 3) {
        setSearchTermText(searchValue); // This will trigger the `useEffect`
      }
    }, 2000);

    // Clean up previous debounce when searchTermText changes
    return () => {
      clearTimeout(debounce);
    };
  }, [searchValue]); // Effect will only trigger when searchTermText changes
  /// end of search term EP ---------------------------

  // close search box once the input is empty
  useEffect(() => {
    if (!searchValue) {
      setShowSearchBoxModal(false);
    }
  }, [searchValue, setShowSearchBoxModal]);

  useEffect(() => {
    import("@/utils/global")
      .then(({ handleListenOnkeydownInWindow }) => {
        return handleListenOnkeydownInWindow(
          searchValue,
          window,
          onSearch,
          setShowSearchBoxModal,
          setUpdatedProducts
        );
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // handle search and filter using swr
  const {
    activeFilters,
    onFilter,
    pageIndex,
    setPageIndex,
    fetchData: productsData,
    isLoadingData: isLoadingProducts,
    setIsShowMore,
    showMoreRef,
    updatedProducts,
    setUpdatedProducts,
  } = useCategoryAndSearch(
    `${
      IS_TYPESENSE_SEARCH ? "typesense-" : ""
    }getSearchData/${searchValue}/${region_id}`,
    switchSearch,
    {
      revalidateOnFocus: false,
      revalidateOnMount: !!searchValue,
      onSuccess: (res) => {
        res?.data?.suggestedWords &&
          res?.data?.suggestedWords?.length > 0 &&
          setSuggestionWords(res?.data?.suggestedWords);
        pageIndex === (IS_TYPESENSE_SEARCH ? 1 : 0)
          ? setUpdatedProducts(res?.data?.products)
          : setUpdatedProducts((prev) => [...prev, ...res?.data?.products]);

        if (res?.data?.products?.length >= PAGE_SIZE) {
          setIsShowMore(true);
        } else {
          setIsShowMore(false);
        }
        setIsLoadingPage(false);
      },
    },
    [],
    true
  );

  function switchSearch() {
    return IS_TYPESENSE_SEARCH
      ? typeSenseMultiSearch(
          searchValue,
          pageIndex,
          PAGE_SIZE,
          activeFilters,
          locale,
          region_id
        )
      : handleSearch({
          token: handshake,
          region_id,
          word: searchValue,
          pageNo: pageIndex,
          sortFilterKeys: handleFilterArrayToString(activeFilters),
        });
  }

  // handle change of searchValue
  useEffect(() => {
    setIsShowMore(true);
    setPageIndex(IS_TYPESENSE_SEARCH ? 1 : 0);

    // add searched words
    if (searchValue?.length >= 3) {
      // check if any word in locale storage startsWith searchValue
      const findSearchedWord = savedSearchWords?.find((item) =>
        item?.startsWith(searchValue)
      );

      if (!findSearchedWord) {
        setSavedSearchWords((prev) => [...prev, searchValue]);
        store.set("savedSearchWords", [...savedSearchWords, searchValue]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // set filter by and search category once and change it if the search work changed
  useEffect(() => {
    if (
      previousSearchValue.current !== searchValue &&
      productsData?.data &&
      !isLoadingProducts
    ) {
      setFilterBy(
        hadleFilterStructureData(productsData?.data?.filters)?.filterKeys
      );
      setSearchCategories(productsData?.data?.categorys);
      event_product_searched(searchValue, productsData?.data?.totalProduct);
      // Update the ref to the new search value
      previousSearchValue.current = searchValue;
    }
  }, [productsData, searchValue, isLoadingProducts]);

  // ===============>> handle close search
  useOnClickOutside([clickRef, searchInputRef, openSearchTermsRef], () => {
    onSearch("");
    setShowSearchBoxModal(false);
  });

  const suggestWords = useMemo(() => {
    const words = (updatedProducts || [])
      ?.slice(0, 10)
      ?.map((item) => item?._source?.suggest)
      .flat()
      ?.filter(
        (term) =>
          term?.length >= 3 &&
          !term?.includes(",") &&
          !term?.includes("،") &&
          !term?.includes(".") &&
          !/^(?=.*\d)[A-Z0-9\-\/\.\(\)]+$/.test(term)
      )
      ?.slice(0, 20)
      ?.map((word) => word?.toLowerCase());
    return [...new Set(words)];
  }, [updatedProducts]);

  return (
    <div ref={clickRef}>
      <section className="w-11/12 max-[991px]:w-[98%] my-4 mx-auto Search-body bg-white ">
        <Toaster position="bottom-center" reverseOrder={true} />
        {isLoadingPage ? (
          <div className="loader-container min-h-[70vh] flex flex-col justify-center items-center">
            <h4 className="mx-auto w-full flex justify-center mb-[1.5rem] text-[1.5rem] font-[600]">
              {locale == "ar" ? "جار البحث" : "Loading..."}
            </h4>
            <MainLoader spinnerColor="#D9232E" />
          </div>
        ) : updatedProducts?.length ? (
          <div className="grid grid-cols-12 gap-4 ">
            <Side
              {...{
                searchCategories,
                filterBy,
                onSearch,
                activeFilters,
                onFilter,

                suggestWords: suggestionWords ? suggestionWords : suggestWords,
              }}
            />
            <div className="col-span-9 p-2 bg-white rounded-md max-[768px]:col-span-12">
              {/* <div className="flex items-center mb-2 max-[768px]:hidden">
                <div className="flex items-center">
                  <BiSortUp size="20px" />
                  <span className="mx-1">{t("SortBy")}</span>
                </div>
                <select
                  className="mx-5 bg-white border rounded-md cursor-pointer outline-0 max-[360px]:mx-0"
                  onChange={(e) =>
                    handleFetchSortedProducts(e.target.value, "sort")
                  }
                >
                  {SortByWords?.map(({ text_en, text_ar, code }, key) => (
                    <option key={key} value={code}>
                      {locale == "ar" ? text_ar : text_en}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="hidden max-[768px]:flex max-[768px]:items-center max-[768px]:justify-between max-[768px]:mb-2">
                {/* <button
                  onClick={() => setOpenSortBy(true)}
                  className="flex items-center p-2 text-white rounded-md bg-blue"
                >
                  <BiSortUp size="19px" />
                  <span className="mx-1">{t("SortBy")}</span>
                </button> */}
                <button
                  onClick={() => setOpenFilterBox(true)}
                  className="flex items-center p-2 text-white rounded-md bg-blue"
                >
                  <FiFilter size="19px" />
                  <span className="mx-1">{t("FilterResults")}</span>
                </button>
              </div>

              {isLoadingPage ? (
                <div className="loader-container min-h-[70vh] flex flex-col justify-center items-center">
                  <h4 className="mx-auto w-full flex justify-center mb-[1.5rem] text-[1.5rem] font-[600]">
                    {locale == "ar" ? "جار البحث" : "Loading..."}
                  </h4>
                  <MainLoader spinnerColor="#D9232E" />
                </div>
              ) : Array.isArray(updatedProducts) ? (
                <>
                  <div className="grid grid-cols-4 gap-3 max-[991px]:grid-cols-3 max-[768px]:grid-cols-3 2sm:grid-cols-2">
                    {updatedProducts?.map(
                      ({ _source: product, highlight }, key) => {
                        return (
                          <ProductCard
                            key={key}
                            {...{
                              t,
                              product,
                              highlight: IS_TYPESENSE_SEARCH
                                ? product?.highlight
                                : highlight,
                              handleClick: () => {
                                onSearch("");
                              },
                            }}
                          />
                        );
                      }
                    )}
                  </div>
                  {isLoadingProducts && (
                    <div className="my-8">
                      <Loading />
                    </div>
                  )}
                  <div
                    ref={showMoreRef}
                    id="show_more"
                    className="w-full"
                  ></div>
                </>
              ) : (
                <div className="error-page min-h-[70vh] items-center flex justify-center">
                  <h3 className="mx-auto text-[1.5rem] font-[600]">
                    {locale == "ar"
                      ? "عفوًا، لا توجد نتائج بحث!"
                      : "No Search Results!!"}
                  </h3>
                  <button
                    onClick={() => {
                      push("/");
                      onSearch("");
                    }}
                    className="mt-[1rem] text-[1.2rem] min-w-[280px] min-h-[60px] text-[white] font-[600] bg-[#D9232E] rounded-[4px] border-[1px] border-[#D9232E] hover:bg-[white] hover:text-[#D9232E] duration-150 flex justify-center items-center"
                  >
                    {locale == "ar" ? "متابعة التسوق" : "Continue shopping"}
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="min-h-[70vh] items-center flex flex-col justify-center">
            <h3 className="mx-auto text-[1.5rem] font-[600]">
              {locale == "ar"
                ? "عفوًا، لا توجد نتائج بحث!"
                : "No Search Results!!"}
            </h3>
            <button
              onClick={() => {
                push("/");
                onSearch("");
              }}
              className="mt-[1rem] text-[1.2rem] min-w-[280px] min-h-[60px] text-[white] font-[600] bg-[#D9232E] rounded-[4px] border-[1px] border-[#D9232E] hover:bg-[white] hover:text-[#D9232E] duration-150 flex justify-center items-center"
            >
              {locale == "ar" ? "متابعة التسوق" : "Continue shopping"}
            </button>
          </div>
        )}
      </section>
      {/* {openSortBy && <ResponsiveSort {...{ setOpenSortBy }} />} */}
      {openFilterBox && (
        <ResponsiveFilter
          {...{
            setOpenFilterBox,
            filterBy,
            activeFilters,
            onFilter,
          }}
        />
      )}
    </div>
  );
}

export default Search;
