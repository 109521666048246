import React, { createContext, useState, useEffect, useContext } from "react";
import store from "store";

const SlugContext = createContext({
  slugs: {},
  setSlugs: () => {},
});

export const useSlugs = () => {
  const { slugs, setSlugs } = useContext(SlugContext);

  useEffect(() => {
    if (slugs) {
      store.set("slugs", slugs);
    }
  }, [slugs]);

  return { slugs, setSlugs };
};

export default function SlugsProvider({ children }) {
  const [slugs, setSlugs] = useState(() => store.get("slugs") || {});

  return (
    <SlugContext.Provider value={{ slugs, setSlugs }}>
      {children}
    </SlugContext.Provider>
  );
}
