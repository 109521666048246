import React, { useContext } from "react";
import ImgTag from "../ImgTag";
import { getCookie } from "cookies-next";
import useClient from "@/hooks/global/useClient";
import { AppContext } from "@/contexts/appContexts";

const DeliveryDate = ({
  fast_delivery,
  back_orders,
  delivery_within_24_hours,
  option_text_delivery_within_24_hours,
  locale,
  t,
}) => {
  const { isCityHavefastDelivery, targetProductDate } = useContext(AppContext);
  const regionId = getCookie("region_id");
  const { isClient } = useClient();
  return (
    <>
      {isCityHavefastDelivery &&
      Array.isArray(fast_delivery) &&
      fast_delivery?.length &&
      fast_delivery[0] &&
      // false && TODO remove false to same day delivery
      regionId === "1101" ? (
        <div className="group relative flex flex-row items-center justify-center px-2 my-2 rounded-md bg-lightgold">
          <div className="relative h-6 overflow-hidden aspect-square">
            <ImgTag
              className="object-contain size-5"
              loading="eager"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=24&h=24,fit=contain,format=auto,quality=75/delivery.svg`}
              alt="Deliv-Time !"
              fill="true"
            />
          </div>
          <span
            className="mx-2 line-clamp-1 text-xs"
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            {back_orders == 101 ? t("LaunchDeliv") : t("FastDeliv")}
          </span>
        </div>
      ) : delivery_within_24_hours?.at(0) &&
        getCookie("region_id") === "1116" ? (
        <div className="bg-lightgold items-center flex gap-2 flex-row flex-nowrap rounded-md px-1 my-[.5rem] h-6">
          <ImgTag
            width={30}
            height={30}
            className="object-contain size-5"
            loading="eager"
            src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=24&h=24,fit=contain,format=auto,quality=75/delivery.svg`}
            alt="Deliv-Time !"
          />
          <span
            className="line-clamp-1 text-xs"
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            {option_text_delivery_within_24_hours?.at(0)}
          </span>
        </div>
      ) : (
        isClient &&
        regionId === "1101" && (
          <div
            className={`flex ${
              back_orders == 101 || targetProductDate?.Date
                ? "bg-lightgold"
                : ""
            } items-center flex-row-reverse rounded-md px-1 my-[.5rem] h-6`}
            dir={locale === "en" ? "rtl" : "ltr"}
          >
            {back_orders == 101 ? (
              <>
                <div className="relative h-6 overflow-hidden aspect-square">
                  <ImgTag
                    className="object-contain size-5"
                    loading="eager"
                    src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=24&h=24,fit=contain,format=auto,quality=75/delivery.svg`}
                    alt="Deliv-Time !"
                    fill="true"
                  />
                </div>
                <span
                  className="mx-2 line-clamp-1 text-[12px]"
                  dir={locale === "en" ? "ltr" : "rtl"}
                >
                  {t("LaunchDeliv")}
                </span>
              </>
            ) : targetProductDate?.Date ? (
              <div className={`w-full flex flex-row-reverse  justify-between `}>
                <span className="text-[11px] font-[600]">
                  {locale == "ar" ? "أُحصل عليه" : "Get it"}
                </span>
                <span className="text-[11px] font-[600] change-color-danger">
                  {targetProductDate?.Date.replace("undefined", "")}
                </span>
              </div>
            ) : null}
          </div>
        )
      )}
    </>
  );
};

export default DeliveryDate;
