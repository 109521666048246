import { AiOutlineClose } from "react-icons/ai";
import FilterBy from "./FilterBy";

function ResponsiveFilter({
  setOpenFilterBox,
  filterBy,
  filterSettings,
  setFilterSettings,
  handleFetchSortedProducts,
  onFilter,
}) {
  return (
    <div className="fixed left-0 z-50 flex items-center justify-center w-screen h-screen top-14 bg-lightblack">
      <div className="w-[570px] max-[570px]:w-[98%] mx-auto bg-white p-2 rounded-md max-h-[75vh] overflow-y-auto">
        <div className="flex items-center justify-between pb-1 border-b">
          <h1 className="mb-3 text-lg font-semibold text-red">Filter By</h1>
          <AiOutlineClose
            onClick={() => setOpenFilterBox(false)}
            size="20px"
            className="cursot-pointer text-red"
          />
        </div>
        <FilterBy
          {...{
            onFilter,
            filterBy,
            filterSettings,
            setFilterSettings,
            handleFetchSortedProducts,
          }}
        />
      </div>
    </div>
  );
}

export default ResponsiveFilter;
