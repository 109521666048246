import { getFetcher } from "@/services/global";
import useSWR, { mutate } from "swr";

export function useHandshake(lang, Cookies, fetchHandshake) {
  const res = useSWR(
    () => (fetchHandshake ? `/api/handshake?lang=${lang}` : null),
    getFetcher,
    {
      revalidateOnMount: 30 * 60 * 1000, // 30 minutes in milliseconds
      revalidateOnFocus: false,
    }
  );
  if (res?.data && !res?.error && Cookies) {
    Cookies.set("handshake", res?.data?.data?.token, { expires: 29 });
  }
  return {
    data: res?.data,
    isLoading: !res?.error && !res?.data,
    isError: res?.error,
    invalidate: () => mutate(`/api/handshake?lang=${lang}`),
  };
}
