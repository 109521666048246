// eslint-disable-next-line no-undef
import Layout from "@/components/Layout/Layout";
import NextNProgress from "nextjs-progressbar";
import Modals from "@/components/Modals";
import { CheckoutProvider } from "@/contexts/checkout/provider";
import { initialState, modalReducer } from "@/reducers/Modal.reducer";
import { useEffect, createContext, useReducer } from "react";
import { AppContextProvider } from "@/contexts/appContexts";
import { CartProvider } from "@/contexts/cart/CartContext";
import { SessionProvider } from "next-auth/react";
import { useRouter } from "next/router";
import { Toaster } from "react-hot-toast";
import "swiper/css";
import "swiper/css/pagination";
import "leaflet/dist/leaflet.css";
import "react-toastify/dist/ReactToastify.css";
import "@/styles/files/static-pages.css";
import "@/styles/globals.css";
import "@/styles/files/general.css";
import "@/styles/files/toaststyle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-fade";

import { ToastContainer } from "react-toastify";
import { Cairo } from "next/font/google";
import localFont from "next/font/local";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import { CompareProvider } from "@/contexts/compare/compare-context";
import CookieConsent from "@/components/CookieConsent";
import { AWSPersonalizeProvider } from "@/contexts/aws-personalize/aws-personalize-context";
import WhatsappChat from "@/components/Helpers/WhatsAppChat";
import ErrorBoundary from "@/components/ErrorBoundary";
import SlugsProvider from "@/contexts/categoryTree";
import { setCookie } from "cookies-next";

const cairoFont = Cairo({
  weight: ["200", "300", "400", "500", "600", "700", "800", "900"],
  style: ["normal"],
  subsets: ["arabic"],
  display: "swap",
  variable: "--font-cairo",
});

const neoFont = localFont({
  src: [
    {
      path: "../../public/en-font/Al-Manea-neo-sans-2.ttf",
      weight: "400",
      style: "normal",
    },
    // {
    //   path: "../../public/en-font/Al-Manea-neo-sans-3.ttf",
    //   weight: "500",
    //   style: "medium",
    // },
    // {
    //   path: "../../public/en-font/Al-Manea-neo-sans-4.ttf",
    //   weight: "600",
    //   style: "semibold",
    // },
    // {
    //   path: "../../public/en-font/Al-Manea-neo-sans-5.ttf",
    //   weight: "700",
    //   style: "bold",
    // },
    // {
    //   path: "../../public/en-font/Al-Manea-neo-sans-6.ttf",
    //   weight: "800",
    //   style: "extrabold",
    // },
  ],
  variable: "--font-neo",
});

export const ModalContext = createContext(null);

function App({ Component, pageProps }) {
  // Language
  const { locale, pathname } = useRouter();
  console.log(pathname);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  // Modal Reducer For Pop Up
  const [state, dispatch] = useReducer(modalReducer, initialState);

  // Window Configuration
  useEffect(() => {
    window.onresize = () => {
      const windowSize = window.devicePixelRatio;
      if (windowSize < 1) {
        document.body.style.width = windowSize * 100 + "%";
        document.body.style.margin = "auto";
      } else {
        document.body.style.width = "100%";
        document.body.style.margin = "auto";
      }
    };
  });

  // Direction
  useEffect(() => {
    document.body.style.direction = locale === "en" ? "ltr" : "rtl";
    setCookie("NEXT_LOCALE", locale);
  }, [locale]);

  useEffect(() => {
    if (typeof window != "undefined" && !window.webengage) {
      window.webengage = {};
    }
  }, []);

  return (
    <>
      <NextNProgress
        color="#D9232E"
        startPosition={0.3}
        stopDelayMs={200}
        height={4}
        showOnShallow={false}
      />
      <CookieConsent />
      <Script src="/service-worker.js" id="ahmed" strategy="worker" async />
      <Script id="gtm-script" strategy="lazyOnload">
        {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>
      <Script id="my-script" strategy="lazyOnload">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-44338257-4', {
                page_path: window.location.pathname,
                });
            `}
      </Script>
      <Toaster position="bottom-center z-[9999]" reverseOrder={true} />
      <SessionProvider session={pageProps.session}>
        <SlugsProvider>
          <AppContextProvider>
            <AWSPersonalizeProvider>
              <CartProvider>
                <CheckoutProvider>
                  <CompareProvider>
                    <ModalContext.Provider value={{ state, dispatch }}>
                      <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={locale.startsWith("ar")}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <main
                        className={`${cairoFont.variable} ${neoFont.variable} ${
                          locale === "en" ? "font-neo" : "font-cairo"
                        }`}
                      >
                        <Layout>
                          <ErrorBoundary>
                            <Component {...pageProps} />
                            <WhatsappChat />
                          </ErrorBoundary>
                        </Layout>
                        <Modals />
                      </main>
                    </ModalContext.Provider>
                  </CompareProvider>
                </CheckoutProvider>
              </CartProvider>
            </AWSPersonalizeProvider>
          </AppContextProvider>
        </SlugsProvider>
      </SessionProvider>
    </>
  );
}
export default appWithTranslation(App);
