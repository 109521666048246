import Link from "next/link";
import NextImage from "../NextImage";
import { validateImgSrc } from "@/utils/global";
import { IS_TYPESENSE_SEARCH } from "@/utils/typesense/config";

const SearchProductCard = ({ product, clearSearch, t, language }) => {
  return (
    <Link
      href={`/product/${product?.rewrite_url}`}
      onClick={() => {
        clearSearch();
      }}
      className="flex flex-col gap-1 p-1 pb-2 border rounded-sm"
    >
      <NextImage
        src={validateImgSrc(
          product?._media_?.image?.at(0)?.image || product?.small_image
        )}
        className="h-32 mx-auto object-contain"
        width={150}
        height={150}
        alt={
          Array.isArray(product?.name) ? product?.name?.at(0) : product?.name
        }
      />
      <h6 className="text-xs font-medium ">
        {IS_TYPESENSE_SEARCH
          ? Array.isArray(product?.brand)
            ? product?.brand?.at(0)
            : product?.brand
          : Array.isArray(product?.option_text_brand)
          ? product?.option_text_brand?.at(0)
          : product?.option_text_brand}
      </h6>
      {product?.highlight &&
      "name.standard" in product?.highlight &&
      product?.highlight["name.standard"]?.at(0) ? (
        <Link
          dangerouslySetInnerHTML={{
            __html: product?.highlight["name.standard"]?.at(0),
          }}
          href={`/product/${product?.rewrite_url}`}
          className="text-xs line-clamp-2 h-8"
        />
      ) : (
        <Link
          href={`/product/${product?.rewrite_url}`}
          className="text-xs line-clamp-2 h-8"
        >
          {Array.isArray(product?.name) ? product?.name?.at(0) : product?.name}
        </Link>
      )}
      {/* <div className="flex items-center gap-0.5 text-sm">
        <span className="font-semibold">4.5</span>
        <AiFillStar className="w-[10px] h-[10px] mx-[0.25px] text-yellow-400" />
        <span>(2071)</span>
      </div> */}
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-0.5">
          <h4 className="text-sm font-bold">
            {Number(product?.prices_with_tax?.price).toFixed(2)} {t("SAR")}
          </h4>
          {product?.prices_with_tax?.price !==
            product?.prices_with_tax?.original_price && (
            <h6 className="text-xs line-through">
              {Number(product?.prices_with_tax?.original_price).toFixed(2)}
            </h6>
          )}
        </div>
        {product?.prices_with_tax?.price !==
          product?.prices_with_tax?.original_price && (
          <span className="bg-red text-white py-1 px-1.5 rounded-md text-[9px]">
            {Number(
              ((product?.prices_with_tax?.original_price -
                product?.prices_with_tax?.price) /
                product?.prices_with_tax?.original_price) *
                100
            ).toFixed(1)}
            % {language === "en" ? "Off" : "خصم"}
          </span>
        )}
      </div>
      {/* <span className="text-red w-fit border text-xs rounded-[4px] border-red py-1 px-1.5">
        {language === "en" ? "Available" : "متوفر"}
      </span> */}
    </Link>
  );
};

export default SearchProductCard;
