import { AppContext } from "@/contexts/appContexts";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";

const usePreventScrollOnBack = () => {
  const router = useRouter()

  const {
    setApplyScrollPositionPages,
  } = useContext(AppContext);

  useEffect(() => {
    const handlePopState = (e) => {
      e.preventDefault();
      router.back;
      setApplyScrollPositionPages(true)
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [router, setApplyScrollPositionPages]);
};

export default usePreventScrollOnBack