import { getFetcher } from "@/services/global";
import useSWR from "swr";

export function useCheckIfAuth(mage, fetchCheckIfAuth) {
  const res = useSWR(
    () =>
      mage != undefined && fetchCheckIfAuth ? `/api/header/checkifauth` : null,
    getFetcher,
    {
      revalidateOnMount: 10 * 60 * 1000, // 10 minutes in milliseconds
      revalidateOnFocus: false,
    }
  );

  if (
    mage != undefined &&
    res?.data == undefined &&
    fetchCheckIfAuth &&
    !res?.isLoading
  ) {
    return "remove mage";
  } else {
    return "not remove mage";
  }
}
