import WhatsappIcon from "@/components/icons/WhatsappIcon";
import { useRouter } from "next/router";

const WhatsappChat = () => {
  const { locale } = useRouter();
  return (
    <div className="fixed w-12 h-12 min-[768px]:w-14 min-[768px]:h-14 flex items-center justify-center z-[888] bottom-8 end-4 max-[991px]:start-4 group max-[991px]:hidden">
      <span className="absolute hidden px-3 py-2 text-sm text-white transition-all -translate-y-1/2 bg-green-500 rounded-md dration-500 top-1/2 whitespace-nowrap end-16 group-hover:block">
        {locale === "en" ? "Contact with Us" : "تواصل معنا"}
      </span>
      <a
        href="https://api.whatsapp.com/send/?phone=966920008171&text&type=phone_number&app_absent=0"
        target="_blank"
      >
        <WhatsappIcon className="object-contain w-12 h-12 min-[768px]:w-14 min-[768px]:h-14" />
      </a>
    </div>
  );
};

export default WhatsappChat;
