// Used in PDP, Product Card

import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { ADD_PRODUCT_FEES, DEL_PRODUCT_FEES } from "@/services/cart";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-hot-toast";

import ProductCard from "../Helpers/ProductCard";
import { useTranslation } from "next-i18next";
import browserStorage from "store";
import NextImage from "../NextImage";
import { validateImgSrc } from "@/utils/global";
import { useCart } from "@/contexts/cart/CartContext";
import { validatePrice } from "@/utils/product";
// import useGetAwsPersonalizeProducts from "@/hooks/useGetAwsPersonalizeProducts";
// import { FREQUENTLY_BOUGHT_TOGETHER } from "@/services/global/aws-personalize";
import { getCookies } from "cookies-next";
import { ModalContext } from "@/pages/_app";
import CurrencyIcon from "../icons/CurrencyIcon";

function CartInfo({ Context }) {
  const { t } = useTranslation("common");
  const { region_id } = getCookies();
  const { push, locale } = useRouter();
  const CartItem = Context?.state?.productInfo || {};
  const { cart } = useCart();

  const [isFeesApplied, setIsFeesApplied] = useState(true);
  const [active, setActive] = useState(false);
  const [swiper, set_swiper] = useState({});
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const slidesNo = swiper?.slides?.length;
  const cartModel = useContext(ModalContext);

  const banner = browserStorage.get("popup-banner")
    ? browserStorage.get("popup-banner")[`img-${locale}`]
    : "";
  // const { setIsSearch } = useContext(AppContext);

  const handleAddProdFees = async (data) => {
    const { error } = await ADD_PRODUCT_FEES(data);
    if (error === null) {
      setIsFeesApplied(true);
      return toast.success(
        locale === "ar"
          ? "تم إضافة الخدمة بنجاح"
          : "The service has been added successfully"
      );
    }
  };

  const handleDelProdFees = async (data) => {
    const { error } = await DEL_PRODUCT_FEES(data);
    if (error === null) {
      setIsFeesApplied(false);
      return toast.success(
        locale === "ar"
          ? "تم حذف الخدمة بنجاح"
          : "The service has been removed successfully"
      );
    }
  };

  const totalPrice = cart?.cartItems?.reduce((total, item) => {
    const price = validatePrice(
      item?.prices_with_tax || {
        discounted_price_to: item?.extension_attributes?.special_price_to,
        original_price: item?.extension_attributes?.original_price,
        price: item?.extension_attributes?.special_price,
        discounted_price_from: item?.extension_attributes?.special_price_from,
      }
    );
    const final = total + (+price?.specialPrice || +price?.originalPrice || 0);

    return final;
  }, 0);
  const [, setScreenWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : null
  );
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [
    frequentlyBoughtTogetherProducts,
    setFrequentlyBoughtTogetherProducts,
  ] = useState(cartModel?.state.relatedProducts);
  useEffect(() => {
    setFrequentlyBoughtTogetherProducts(cartModel?.state.relatedProducts);
  }, [cartModel?.state.relatedProducts]);
  // const relatedRes =
  //   product?.custom_related_products?.length > 0
  //     ? null
  //     : await getAmastyProductsBySkus(
  //         handshake,
  //         product?.sku,
  //         "product_into_related"
  //       );

  // const { products: frequentlyBoughtTogetherProducts } =
  //   useGetAwsPersonalizeProducts(FREQUENTLY_BOUGHT_TOGETHER, {
  //     itemId: Context?.state?.productInfo?.sku,
  //   });

  return (
    <div className="fixed top-0 left-0 w-screen bg-lightblack z-[5000] min-h-screen overflow-y-auto red-scrollbar">
      <div className="w-[570px] min-h-screen max-[570px]:w-full bg-white absolute top-0 left-0">
        <div className="sticky top-0 z-20 flex items-center justify-between p-4 text-white bg-red">
          <div className="flex items-center">
            <AiOutlineCheckCircle size="20px" />
            <h4 className="mx-2 text-lg font-semibold">{t("GreatChoice")}</h4>
          </div>
          <AiOutlineClose
            onClick={() =>
              Context?.dispatch({ type: "closeModal", payload: "" })
            }
            size="20px"
            className="cursor-pointer"
          />
        </div>
        <div className="px-2">
          <div className="flex flex-col h-full px-4 my-4 overflow-y-auto max-h-80 gray-scrollbar">
            {cart?.cartItems?.map((item) => {
              const price = validatePrice(
                item?.prices_with_tax || {
                  discounted_price_to:
                    item?.extension_attributes?.special_price_to,
                  original_price: item?.extension_attributes?.original_price,
                  price: item?.extension_attributes?.special_price,
                  discounted_price_from:
                    item?.extension_attributes?.special_price_from,
                }
              );
              return (
                <div
                  key={item?.item_id}
                  className="flex items-center py-2 border-b last:border-none me-2"
                >
                  <NextImage
                    src={validateImgSrc(
                      `${
                        item?.image ||
                        `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product/${item?.extension_attributes?.product_image}`
                      }`
                    )}
                    className="object-contain w-24 h-24 p-2 border rounded-md"
                    height={100}
                    width={100}
                    alt="Prod Cart Img!"
                  />
                  <div className="flex flex-col gap-4 mx-2">
                    <p className="mb-1 font-semibold text-zinc-600">
                      {item?.name || "product"}
                    </p>
                    <div className="flex flex-wrap items-center gap-2 text-xs">
                      <div className="flex items-center gap-1 mx-3 font-bold text-red">
                        <span>
                          {Math.round(
                            price?.specialPrice || price?.originalPrice
                          )?.toLocaleString()}
                        </span>
                        <CurrencyIcon className="w-3 h-3" />
                      </div>

                      {price?.discount > 0 && (
                        <div className="line-through flex items-center gap-1 font-bold me-[1rem] text-zinc-500">
                          <span>
                            {Math.round(price?.originalPrice)?.toLocaleString()}{" "}
                          </span>
                          <CurrencyIcon className="w-3 h-3" />
                        </div>
                      )}
                      {price?.discount > 0 && (
                        <div className="px-3 py-1 text-xs font-bold text-white rounded-md bg-darkgold">
                          {t("Discount")} {Math.round(price?.discount)}%
                        </div>
                      )}
                      <span className="mx-2 text-xs">
                        ({t("IncludesValueAddedTax")})
                      </span>
                    </div>
                    {item?.back_orders ? (
                      <div className="text-[10px] w-fit font-semibold px-2 py-1 text-white bg-blue rounded-full text-center">
                        {t("PreOrder")}
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.product_fees?.length > 0 &&
                      item?.product_fees?.map((fee, i) => {
                        return (
                          <div className="" key={i}>
                            <h4 className="mb-2 font-semibold text-red">
                              {fee?.labels
                                ? fee?.labels[locale === "en" ? "2" : "14"]
                                    ?.title
                                : ""}
                            </h4>
                            <p className="mb-2 text-zinc-500">
                              {fee?.labels
                                ? fee?.labels[locale === "en" ? "2" : "14"]
                                    ?.description
                                : ""}
                            </p>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 max-[420px]:grid-cols-2">
                              {Object.values(fee?.options || {}).map(
                                (option, idx) => (
                                  <div
                                    key={idx}
                                    onClick={() => {
                                      if (!isFeesApplied) {
                                        handleAddProdFees({
                                          id: option.fee_id,
                                          options: option?.fee_option_id,
                                          itemId: CartItem?.item_id,
                                        });
                                      } else {
                                        handleDelProdFees({
                                          id: option.fee_id,
                                          itemId: CartItem?.item_id,
                                        });
                                      }
                                      setActive(active === idx ? false : idx);
                                    }}
                                    className="cursor-pointer border p-1.5 rounded-md relative"
                                  >
                                    <h3 className="mb-1 text-sm font-semibold">
                                      {locale === "ar"
                                        ? option?.title_ar || ""
                                        : option?.title || ""}
                                    </h3>
                                    <h5 className="text-xs font-bold">
                                      {parseInt(option.price || 0)?.toFixed(1)}{" "}
                                      {t("SAR")}
                                    </h5>
                                    <AiOutlineCheckCircle
                                      size="24px"
                                      className={`${
                                        isFeesApplied
                                          ? "text-emerald-700"
                                          : "text-slate-300"
                                      } absolute top-2 end-2`}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>

          <NextImage
            // src={banner}
            src={
              // validateImgSrc(
              banner ||
              "https://m.dev-almanea.com/media/json/images/slider/_/-/_-01_4.webp"
              // )
            }
            className="object-contain w-full h-auto"
            height={260}
            width={560}
            alt="Tamara Sheet Img!"
          />

          {frequentlyBoughtTogetherProducts.length ? (
            <h3 className="my-2 text-lg font-bold text-blue">
              {t("Frequently Bought Together Products")}
            </h3>
          ) : null}
          {frequentlyBoughtTogetherProducts?.length > 0 ? (
            <div className="relative p-7">
              {/* <button
                onClick={() => swiper.slidePrev()}
                className="absolute z-50 -translate-y-1/2 bg-white border rounded-full top-1/2 -right-2"
              >
                <RiArrowLeftSLine size="22px" className="rotate-180" />
              </button> */}

              {slidesNo > 2
                ? (locale === "en" ? !isEnd : !isBeginning) && (
                    <button
                      onClick={() =>
                        locale === "en"
                          ? swiper?.slideNext()
                          : swiper?.slidePrev()
                      }
                      aria-label="Prev Button"
                      className={`absolute ${
                        locale === "ar" ? "-start-1" : "-end-1"
                      } z-50 -translate-y-1/2 bg-white border rounded-full top-1/2 p-0.5 cursor-pointer`}
                    >
                      <RiArrowLeftSLine size="22px" className="rotate-180" />
                    </button>
                  )
                : null}
              <Swiper
                onInit={(e) => set_swiper(e)}
                spaceBetween={10}
                breakpoints={{
                  640: {
                    slidesPerView: 1.5,
                    slidesPerGroup: 1,
                  },
                  768: {
                    slidesPerView: 1.8,
                    slidesPerGroup: 1.8,
                  },
                  1024: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                }}
                onSlideChange={(swiper) => {
                  setIsBeginning(swiper.isBeginning);
                  setIsEnd(swiper.isEnd);
                }}
              >
                {frequentlyBoughtTogetherProducts?.map(({ _source }, key) => {
                  return _source?.[region_id || "1101"]?.value > 0 ? (
                    <SwiperSlide key={key}>
                      <ProductCard {...{ t, product: _source }} />
                    </SwiperSlide>
                  ) : (
                    ""
                  );
                })}
              </Swiper>

              {slidesNo > 2
                ? (locale === "en" ? !isBeginning : !isEnd) && (
                    <button
                      onClick={() =>
                        locale === "en"
                          ? swiper?.slidePrev()
                          : swiper?.slideNext()
                      }
                      aria-label="Next Button"
                      className={`absolute ${
                        locale === "ar" ? "-end-1" : "-start-1"
                      } z-50 -translate-y-1/2 bg-white border rounded-full top-1/2 p-0.5 cursor-pointer`}
                    >
                      <RiArrowLeftSLine size="22px" />
                    </button>
                  )
                : null}
            </div>
          ) : null}
          <div className="sticky bottom-0 z-50 py-4 bg-white border-t">
            <div className="flex items-center justify-between mx-2 mb-2">
              <h3 className="text-xl font-bold">{t("Total")}</h3>
              <h4 className="flex items-center gap-2 text-xl font-bold text-red">
                <span>{Math.round(totalPrice || 0)?.toLocaleString()}</span>
                <CurrencyIcon className="w-4 h-4" />
              </h4>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <button
                onClick={() => {
                  push("/cart");
                  // setIsSearch("");
                  Context?.dispatch({ type: "cartModal", payload: "" });
                }}
                className="w-full p-2 font-semibold text-white border rounded-full bg-red border-red"
              >
                {t("GoToCartPage")}
              </button>
              <button
                onClick={() =>
                  Context?.dispatch({ type: "cartModal", payload: "" })
                }
                className="w-full p-2 font-semibold border rounded-full text-red border-red"
              >
                {t("ContinueShopping")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartInfo;
