import { getFetcher } from "@/services/global";
import useSWRMutation from "swr/mutation";
import browserStorage from "store";
import { useEffect, useState } from "react";

function useRegions(lang) {
  const [isLoading, setIsLoading] = useState(false);

  // Define the mutation function with useSWRMutation
  const { trigger, data, error } = useSWRMutation(
    `/api/getRegions?lang=${lang}`,
    getFetcher,
    {
      onSuccess: (res) => {
        if (Array.isArray(res?.data)) {
          const regions = res?.data?.map(({ label, value }) => ({ label, value }));
          browserStorage.set("regions", regions);
        }
      },
    }
  );

  // Fetch regions on mount
  useEffect(() => {
    setIsLoading(true);
    trigger() // Trigger the mutation manually
      .finally(() => setIsLoading(false));
  }, [lang, trigger]);

  return {
    regions: data?.data?.data,
    isLoading,
    isError: !!error,
    invalidate: () => {
      trigger(); // Manually trigger the fetch again when needed
    },
  };
}

export default useRegions;
