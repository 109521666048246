import { useRouter } from "next/router";

const TopSearchTerms = ({ topSearchTermsData, title = "", applySearch }) => {
  const { locale } = useRouter();
  const localeTopSearchTerms = topSearchTermsData?.filter(
    (item) => item?.store_code === locale
  );

  return (
    <div className="flex-1 bg-blue-100 py-6 ">
      <div className="flex justify-between">
        <h2 className="text-md font-semibold text-gray-600 mb-3">{title}</h2>
      </div>
      <div className="flex flex-wrap gap-2">
        {localeTopSearchTerms?.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              applySearch(item?.query_text);
            }}
            className="bg-gray-200 px-3 pt-1 pb-1.5 rounded-full hover:bg-red hover:text-white"
          >
            {item?.query_text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TopSearchTerms;
