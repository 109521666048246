import { IS_TYPESENSE_SEARCH } from "@/utils/typesense/config";
import { useRouter } from "next/router";
import React from "react";
import SuggestedCategories from "./SuggestedCategories";
import SuggestedBrands from "./SuggestedBrands";
import { highlightText } from "@/utils/global";

const SuggestWords = ({
  applySearch,
  suggestions,
  setSuggestedText,
  inputValue,
}) => {
  const { locale } = useRouter();
  console.log("suggestions", suggestions, inputValue);
  return (
    <>
      {suggestions?.words?.length > 0 && (
        <div className="mb-3">
          <h3 className="mb-2 text-sm font-semibold text-red">
            {locale === "ar" ? "الكلمات المقترحة" : "Suggedted words"}
          </h3>{" "}
          <div className="grid grid-cols-1 max-[570px]:grid-cols-3">
            {suggestions?.words?.slice(0, 9)?.map((word, idx) =>
              !IS_TYPESENSE_SEARCH ? (
                <button
                  onClick={() => {
                    applySearch(word);
                  }}
                  onMouseEnter={() =>
                    setTimeout(() => {
                      setSuggestedText(word);
                    }, 500)
                  }
                  key={`suggest_word_term_${word}_${(idx + 1) * 425}`}
                  className="flex gap-2 p-2 text-xs transition rounded-md hover:bg-slate-200 text-start align-items-center"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <AiOutlineSearch size="14px" className='text-red' />
                  ${highlightText(inputValue, word) || ""}
                    `,
                  }}
                />
              ) : (
                <button
                  dangerouslySetInnerHTML={{
                    __html: `
              <AiOutlineSearch size="14px" className='text-red' />
              ${highlightText(inputValue, word) || ""}
              
                `,
                  }}
                  onClick={() => {
                    applySearch(word);
                  }}
                  onMouseEnter={() => setSuggestedText(word)}
                  key={`suggest_word_term_${word}_${(idx + 1) * 425}`}
                  className="flex gap-2 p-2 text-xs transition rounded-md hover:bg-slate-200 text-start align-items-center"
                />
              )
            )}
          </div>
        </div>
      )}
      {suggestions?.brands?.length > 0 && (
        <SuggestedBrands
          title={locale === "ar" ? "الماركات المقترحة" : "Matching Brands"}
          data={suggestions?.brands}
          applySearch={applySearch}
          searchTerm={inputValue}
        />
      )}
      {suggestions?.categories?.length > 0 && (
        <SuggestedCategories
          title={locale === "ar" ? "الأقسام" : "Categories"}
          data={suggestions?.categories}
          applySearch={applySearch}
          searchTerm={inputValue}
        />
      )}
    </>
  );
};

export default SuggestWords;
