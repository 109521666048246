import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TbCategory } from "react-icons/tb";

const Functions = ({ selected, setSelected, locale }) => {
  return (
    <li
      onClick={() => setSelected(0)}
      className={`flex items-center justify-center max-[570px]:justify-start gap-2 p-1.5 border ${
        selected === 0 && "border-blue"
      } rounded-md cursor-pointer`}
    >
      <span className="flex flex-col items-center justify-center">
        <span className="flex items-center">
          <hr className="w-10 border border-green-600 max-[570px]:w-14" />
          <IoIosCheckmarkCircleOutline className="w-4 h-4 text-green-600" />
        </span>
      </span>
      <span className="flex items-center justify-center gap-1">
        <TbCategory />
        <span>{locale === "en" ? "Functions" : "الدوال"}</span>
      </span>
    </li>
  );
};

export default Functions;
