// contexts/AppContext.js
import React, { createContext, useMemo, useState, useEffect } from "react";
// import UseUser from "./user";
import store from "store";

// Create the context
export const AppContext = createContext();

// Create a provider component
export const AppContextProvider = ({ children }) => {
  const [categoryData, setCategoryData] = useState(
    store.get("categoryTreeData") &&
      store.get("categoryTreeData")?.cacheTime > Date.now()
      ? store.get("categoryTreeData")?.data || {}
      : {}
  );
  const [savedSearchWords, setSavedSearchWords] = useState(
    store.get("savedSearchWords") || []
  );
  const [targetProductDate, setTargetProductDate] = useState({});
  const [isCityHavefastDelivery, setIsCityHavefastDelivery] = useState(false);
  const [openSelectCityPopUp, setOpenSelectCityPopUp] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [regionId, setRegionId] = useState(null);
  const [scrollPositionPages, setScrollPositionPages] = useState({});
  const [applyScrollPositionPages, setApplyScrollPositionPages] = useState(false);

  // Forget Password
  const [otpInfo, setotpInfo] = useState();

  // Wishlist

  const [wishList, setwishList] = useState([]);

  // TeleSales Parameters

  const [adminID, setadminID] = useState();

  useEffect(() => {
    let wishlistFromLocalStorageParsed;
    if (typeof window !== "undefined") {
      wishlistFromLocalStorageParsed = JSON.parse(
        localStorage.getItem("wishListInLocalStorage")
      );
    }
    setwishList(wishlistFromLocalStorageParsed);
  }, []);

  useEffect(() => {
    const savedSearchWordsStore = store.get("savedSearchWords");
    if (savedSearchWordsStore) {
      setSavedSearchWords(savedSearchWordsStore);
    }
  }, []);

  const contextValue = useMemo(() => {
    return {
      categoryData,
      setCategoryData,
      isCityHavefastDelivery,
      setIsCityHavefastDelivery,
      otpInfo,
      setotpInfo,
      wishList,
      setwishList,
      targetProductDate,
      setTargetProductDate,
      openSelectCityPopUp,
      setOpenSelectCityPopUp,
      productInfo,
      setProductInfo,
      regionId,
      setRegionId,
      adminID,
      setadminID,
      scrollPositionPages,
      setScrollPositionPages,
      applyScrollPositionPages,
      setApplyScrollPositionPages,
      savedSearchWords,
      setSavedSearchWords,
    };
  }, [
    categoryData,
    setCategoryData,
    isCityHavefastDelivery,
    setIsCityHavefastDelivery,
    otpInfo,
    setotpInfo,
    wishList,
    setwishList,
    targetProductDate,
    setTargetProductDate,
    openSelectCityPopUp,
    setOpenSelectCityPopUp,
    productInfo,
    setProductInfo,
    regionId,
    setRegionId,
    adminID,
    setadminID,
    scrollPositionPages,
    setScrollPositionPages,
    applyScrollPositionPages,
    setApplyScrollPositionPages,
    savedSearchWords,
    setSavedSearchWords,
  ]);

  // const { handleAddress } = UseUser();

  // You can add any state variables or functions you need

  return (
    <AppContext.Provider value={{ ...contextValue }}>
      {children}
    </AppContext.Provider>
  );
};
