export const findObjectById = (data, idToFilter) => {
  if (idToFilter) {
    for (let i = 0; i < data?.length ?? [].length; i++) {
      if (String(data[i]?.id) === String(idToFilter)) {
        return data[i];
      } else if (Array.isArray(data[i]?.children_data)) {
        let found = findObjectById(data[i]?.children_data, idToFilter);
        if (found) return found;
      }
    }
  } else {
    return {};
  }
};

export function getAsideDealsData(id, data) {
  return {
    name: findObjectById(data, id)?.name || "",
    description: findObjectById(data, id)?.description || "",

    listOfSubProduct: {
      url_key: findObjectById(data, id)?.url_key || "",
      children_data:
        findObjectById(data, id)?.children_data?.map((item) => ({
          name: item?.name,
          product_count: item?.product_count,
          id: item?.id,
          url_key: item?.url_key,
        })) || [],
    },
  };
}

export const hadleFilterStructureData = (filters) => {
  const filterKeys = [];
  const filterColors = [];

  for (const key in filters) {
    if (filters[key]?.buckets?.length && key != "color") {
      filterKeys.push({
        title: key,
        list: filters[key]?.buckets,
      });
    }

    if (key == "color") {
      filterColors.push({
        title: key,
        list: filters[key]?.buckets,
      });
    }
  }

  return { filterKeys, filterColors };
};

const parseCategoryID = (url_key = "") =>
  url_key?.slice(url_key?.lastIndexOf("-c-") + 3);

// helper function to parse category meta title and desc
export const getCategoryMeta = (query = {}, categoryData) => {
  let data = {};
  const {
    categoryParent: category1,
    categoryChild: category2,
    subCategoryChildLevelOne: category3,
  } = query;

  // get
  const [categoryId1, categoryId2, categoryId3] = [
    parseCategoryID(category1),
    parseCategoryID(category2),
    parseCategoryID(category3),
  ];

  categoryData?.children_data?.find((category) => {
    if (categoryId1 && categoryId2 && categoryId3) {
      if (categoryId1 == category?.id) {
        const categoryData2 = category?.children_data
          ?.find((cat2) => cat2?.id == categoryId2)
          ?.children_data?.find((cat3) => cat3?.id == categoryId3);
        return (data = categoryData2);
      }
    } else if (categoryId1 && categoryId2) {
      if (categoryId1 == category?.id) {
        const categoryData2 = category?.children_data?.find(
          (cat2) => cat2?.id == categoryId2
        );
        return (data = categoryData2);
      }
    } else {
      if (categoryId1 == category?.id) {
        return (data = category);
      }
    }
  });
  return data;
};

export function getCategoryData(data, targetKey, targetValue) {
  if (!Array.isArray(data)) {
    return {};
  }
  for (const item of data) {
    if (item[targetKey] == targetValue) return item;

    if (item?.children_data && item?.children_data.length > 0) {
      const result = getCategoryData(
        item?.children_data,
        targetKey,
        targetValue
      );
      if (result?.id) return result;
    }
  }
  return {};
}

export function findCategoryPath(tree, targetUrlKey, path = "") {
  // Add the current category's url_key to the path
  const currentPath = `${path}/${tree?.url_key}`;

  // If the current category's url_key matches the target, return the path
  if (tree?.url_key === targetUrlKey) {
    // Remove the first part of the path (root category)
    const pathParts = currentPath.split("/").slice(2); // Remove the first two elements (empty string and the root)
    return `/${pathParts.join("/")}`;
  }

  // If there are children categories, recursively check each one
  if (tree.children_data) {
    for (const child of tree.children_data) {
      const result = findCategoryPath(child, targetUrlKey, currentPath);
      if (result) {
        return result; // Return the found path if the child category matches
      }
    }
  }

  // If no matching category was found, return null
  return null;
}

export function generateSlugs(categories, parentSlug = "") {
  if (!Array.isArray(categories)) return {};
  let slugs = {};

  categories?.forEach((category) => {
    // Build the current slug using the parent slug and the current category's url_key
    const currentSlug = parentSlug
      ? `${parentSlug}/${category?.url_key}`
      : category?.url_key;

    // Add the current slug to the slugs map
    slugs[category?.id] = currentSlug;

    // Recursively generate slugs for the child categories
    if (category?.children_data && category?.children_data.length > 0) {
      const childSlugs = generateSlugs(category?.children_data, currentSlug);
      slugs = { ...slugs, ...childSlugs };
    }
  });

  return slugs;
}

export const generatePaginationPageNumbers = (
  currentPage,
  pageCount,
  maxPagesToShow = 6
) => {
  const pages = [];

  pages.push(1);

  const start = Math.max(2, currentPage - Math.floor(maxPagesToShow / 2));
  const end = Math.min(
      pageCount - 1,
      currentPage + Math.floor(maxPagesToShow / 2)
  );

  if (start > 2) pages.push("...");

  for (let i = start; i <= end; i++) pages.push(i);

  if (end < pageCount - 1) pages.push("...");

  if (pageCount > 1) pages.push(pageCount);

  return pages;
};
