import { axiosES } from "@/utils/axios-algolia";
import axios from "axios";

const SendEmailToken = async (handshake, email) => {
  let data = JSON.stringify({ email });
  let config = {
    method: "post",
    url: "/customer/resetToken",
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + handshake,
    },
    data,
  };
  try {
    const req = await axiosES(config);
    return { data: req.data, status: req.status, error: null };
  } catch (error) {
    return {
      data: null,
      status: error?.response?.status,
      error: error.response?.data?.msg,
    };
  }
};

const otpForgetPassword = async (email, name = "unknown", otp = "0310") => {
  let data = JSON.stringify({
    receiver: email,
    name,
    otp,
  });
  let config = {
    method: "post",
    url: "/email/send/en/otp_forget_password",
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + process.env?.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
    data,
  };
  try {
    const req = await axiosES(config);
    return { data: req.data, status: req.status, error: null };
  } catch (error) {
    return {
      data: null,
      status: error?.response?.status,
      error: error.response?.data?.msg,
    };
  }
};

const changeNewPassword = async (
  handshake,
  email,
  newPassword,
  confirmPassword,
  resetToken
) => {
  let data = JSON.stringify({
    email,
    newPassword,
    confirmPassword,
    resetToken,
  });
  let config = {
    method: "post",
    url: "/customer/forgetPassword",
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + handshake,
    },
    data,
  };
  try {
    const req = await axiosES(config);
    return { data: req.data, status: req.status, error: null };
  } catch (error) {
    return {
      data: null,
      status: error?.response?.status,
      error: error.response?.data?.msg,
    };
  }
};

// client side funactions
const SEND_EMAIL_TOKEN = async (email) => {
  try {
    const req = await axios.post("/api/forget/sendEmailToken", { email });

    return req.data;
  } catch (error) {
    return error;
  }
};
const OTP_FORGET_PASSWORD = async (email, name, otp) => {
  try {
    const req = await axios.post("/api/forget/otpForgetPassword", {
      email,
      name,
      otp,
    });
    return req.data;
  } catch (error) {
    return error;
  }
};
const CHANGE_NEW_PASSWORD = async (
  email,
  newPassword,
  confirmPassword,
  resetToken
) => {
  try {
    const req = await axios.post("/api/forget/changeNewPassword", {
      email,
      newPassword,
      confirmPassword,
      resetToken,
    });
    return req.data;
  } catch (error) {
    return error;
  }
};

// export all function

// Function for API Directely

const resetTokenAPIRoute = async (params) => {
  let { email } = params;
  try {
    const req = await axios.post("/api/forget/new-send-email-token", { email });
    return req.data;
  } catch (error) {
    return error;
  }
};

const otpForgetPasswordAPIRoute = async (params) => {
  let { receiver, otp } = params;

  try {
    const req = await axios.post("/api/forget/new-send-email-message", {
      receiver,
      otp,
    });

    return req.data;
  } catch (error) {
    return error;
  }
};

const ForgetPasswordWithTokenAPIRoute = async (params) => {
  const { email, resetToken, newPassword, confirmPassword } = params;

  try {
    const req = await axios.post("/api/forget/new-confirm-change-password", {
      email,
      resetToken,
      newPassword,
      confirmPassword,
    });
    return req.data;
  } catch (error) {
    return error;
  }
};

export {
  SendEmailToken,
  otpForgetPassword,
  changeNewPassword,
  SEND_EMAIL_TOKEN,
  OTP_FORGET_PASSWORD,
  CHANGE_NEW_PASSWORD,
  resetTokenAPIRoute,
  otpForgetPasswordAPIRoute,
  ForgetPasswordWithTokenAPIRoute,
};
