const { useEffect, useReducer } = require("react");
import store from "store";
import { cartReducer, CheckoutContext } from "./reducers";
export const intialState = {
  cart: {},
  billing_address: {},
  editBillingAddress: null,
  shipping_methods: [],
  regions: [],
  mobiles: {},
  payments: [],
  tel: null,
};
export const CheckoutProvider = ({ children }) => {
  const storedData = store.get("checkout");
  const [checkout, dispatch] = useReducer(
    cartReducer,
    storedData ? storedData : intialState
  );

  useEffect(() => {
    store.set("checkout", checkout);
  }, [checkout]);

  return (
    <CheckoutContext.Provider value={{ checkout, dispatch }}>
      {children}
    </CheckoutContext.Provider>
  );
};
