import axiosRequest from "@/utils/axios-utils";

export const saveSearchTerm = async (handshake, searchText) => {
  let config = {
    method: "get",
    url: `/search/terms/save?q=${searchText}`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
export default async function handler(req, res) {
  const searchText = req.q;
  const { handshake } = req.cookies;
  const {
    data,
    status,
    error,
  } = await saveSearchTerm(handshake, searchText);
  return res.status(status).json({data, status, error });
}
