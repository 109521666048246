import React from "react";

const Keywords = ({ keywords }) => {
  const tags = keywords?.split(",").filter((word) => word?.trim()?.length > 3);
  return (
    <div className="flex flex-wrap gap-1 mt-1 text-[10px] text-gray-500 h-11 overflow-hidden">
      {tags?.map(
        (tag, index) =>
          tag && (
            <span
              key={index}
              className="px-1 text-center text-xs h-fit leading-5 text-gray-500 bg-gray-200 rounded-md"
            >
              {tag?.replace(/[,.،]/g, "")}
            </span>
          )
      )}
    </div>
  );
};

export default Keywords;
