/*
###############################
 01:---> CREATE CART,
 02:---> GET CART,
 03:---> ADD ITEM TO CART,
 04:---> UPDATE ITEM TO CART,
 05:---> DELETE CART ITEM,
 06:---> APPLY COUPON,
 07:---> DELETE COUPON,
 08:---> PLACE ORDER,
 09:---> MERGE MASKED CART
###############################
*/
import axios from "axios";

const CREATE_CART = async () => {
  try {
    const req = await axios.get("/api/cart/create");
    return req;
  } catch (error) {
    return error;
  }
};

const GET_CART = async () => {
  try {
    const req = await axios.get("/api/cart/getCart");
    return req.data;
  } catch (error) {
    return error;
  }
};
const CLEAR_CART = async () => {
  try {
    const req = await axios.get("/api/cart/clearCart");
    return req.data;
  } catch (error) {
    return error;
  }
};

const ADD_ITEM_TO_CART = async (sku, qty) => {
  try {
    const req = await axios.post("/api/cart/addItem", { sku, qty });
    return req?.data;
  } catch (error) {
    return error;
  }
};

const UPDATE_ITEM_TO_CART = async (itemId, qty) => {
  try {
    const req = await axios.post("/api/cart/updateItem", { itemId, qty });
    return req.data;
  } catch (error) {
    return error;
  }
};

const DELETE_CART_ITEM = async (itemId) => {
  try {
    const req = await axios.post("/api/cart/delateItem", { itemId });
    return req;
  } catch (error) {
    return error;
  }
};

const APPLY_COUPON = async (couponCode) => {
  try {
    const req = await axios.post("/api/cart/applyCoupon", { couponCode });
    return req;
  } catch (error) {
    return error;
  }
};

const DELETE_COUPON = async (couponCode) => {
  try {
    const req = await axios.post("/api/cart/deleteCoupon", { couponCode });
    return req;
  } catch (error) {
    return error;
  }
};

const PLACE_ORDER = async (method, email) => {
  try {
    const req = await axios.post("/api/cart/placeOrder", { method, email });
    return req;
  } catch (error) {
    return error;
  }
};

const MERGE_MASKED_CART = async (storeId, customerId) => {
  try {
    const req = await axios.post("/api/cart/mergeMaskedQuote", {
      storeId,
      customerId,
    });
    return req.data;
  } catch (error) {
    return error;
  }
};

const ADD_PRODUCT_FEES = async (data) => {
  try {
    const req = await axios.post(`/api/cart/addProductFees`, { data });
    return req?.data;
  } catch (error) {
    return error;
  }
};

const DEL_PRODUCT_FEES = async (data) => {
  try {
    const req = await axios.post(`/api/cart/delProductFees`, { data });
    return req?.data;
  } catch (error) {
    return error;
  }
};
const VALIDATE_ERP_VOUCHER = async (phoneno, voucher) => {
  try {
    const req = await axios.post(`/api/cart/validateErpVoucher`, {
      phoneno,
      voucher,
    });
    return req?.data;
  } catch (error) {
    return error;
  }
};

const APPLY_ERP_VOUCHER = async (phoneno, voucher) => {
  try {
    const req = await axios.post(`/api/cart/applyErpVoucher`, {
      phoneno,
      voucher,
    });
    return req?.data;
  } catch (error) {
    return error;
  }
};

const AVAILABLE_TO_CHECKOUT_V2 = async (cartItems = []) => {
  try {
    const req = await axios.post(`/api/cart/availableToCheckoutV2`, {
      cartItems,
    });
    return req?.data;
  } catch (error) {
    return error;
  }
};

export {
  CREATE_CART,
  GET_CART,
  CLEAR_CART,
  ADD_ITEM_TO_CART,
  UPDATE_ITEM_TO_CART,
  DELETE_CART_ITEM,
  APPLY_COUPON,
  DELETE_COUPON,
  PLACE_ORDER,
  MERGE_MASKED_CART,
  ADD_PRODUCT_FEES,
  DEL_PRODUCT_FEES,
  VALIDATE_ERP_VOUCHER,
  APPLY_ERP_VOUCHER,
  AVAILABLE_TO_CHECKOUT_V2
};
