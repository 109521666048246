const formatProductsRes = (d) => {
  const arr = Array.isArray(d) ? d : [];
  const res = arr?.map((product) => ({
    _id: product?.document?.id,
    _source: {
      ...product?.document,
      ...(product?.highlight?.name?.snippet
        ? {
            highlight: {
              "name.standard": [
                product?.highlight?.name?.snippet?.replaceAll(
                  "mark>",
                  "strong>"
                ),
              ],
            },
          }
        : {}),
    },
  }));
  return res || [];
};

const formatFacets = (list = []) => {
  const arr = Array.isArray(list) ? list : [];
  const res = arr?.reduce((obj, fac) => {
    obj[fac.field_name] = {
      buckets: (fac?.counts || [])?.map((item) => ({
        key: item?.highlighted,
        doc_count: item?.count || 0,
        label: item?.value,
        code: fac?.field_name,
      })),
    };

    return obj;
  }, {});
  return res;
};

const formatCategories = (arr = []) => {
  const array = Array.isArray(arr?.hits) ? arr?.hits : [];
  return array.map((item) => ({
    id: item?.document?.id,
    parent_id: item?.document?.id || null,
    path: "",
    name: item?.document?.name || "",
    url_key:
      item?.document?.url_key ||
      `${(item?.document?.name || "")
        ?.toLocaleLowerCase()
        ?.replaceAll(" ", "-")}-c-${item?.document?.id}`,
  }));
};

function formatResponse(productsRes = {}, categories, pageSize = 30) {
  const products = formatProductsRes(productsRes?.hits);
  const filters = formatFacets(productsRes.facet_counts);
  const cats = formatCategories(categories);
  return {
    products,
    filters,
    categorys: cats || [],
    totalProduct: productsRes?.found || 0,
    pages: Math.ceil((productsRes?.found ?? 0) / pageSize),
    currentpage: productsRes?.page || 1,
  };
}

function getSort(filters = []) {
  const sort = (filters || [])?.find((el) => el.type === "sort");
  const [sortBy, sortDir] = (sort?.code || "")
    ?.split("&")
    .map((key) => key?.split("=")[1]);
  return `${sortBy || "name"}:${sortDir || "asc"}`.toLocaleLowerCase();
}

function formatFilters(arr) {
  const filters = (arr || [])
    ?.filter((item) => item.type === "filter")
    ?.reduce((obj, item) => {
      if (!obj[item.code]) {
        obj[item.code] = [];
      }
      obj[item.code].push(item.key);
      return obj;
    }, {});
  const finalFilters = Object.entries(filters)?.map(
    ([key, value]) => `${key}:[${value.join(",")}]`
  );
  return finalFilters.join("&&");
}

export { formatResponse, getSort, formatFilters };
