// import Cookies from "js-cookie";
import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  loginMobileInitialValues,
  loginMobileValidationSchema,
} from "@/constants/Auth";
import { TextError } from "../Helpers/InputContainer";
// import browserStorage from "store";
// import { useCheckout } from "@/contexts/checkout";
// import { getSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { getCookie, setCookie } from "cookies-next";
import { loginByEmail } from "@/services/auth/login";
import toast from "react-hot-toast";
// import { event_mobile_number, event_user_login } from "@/utils/gtm";

function LoginMobile({ Context, setLogin, setLoginMobile, loginMobile }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const [remember, setRemember] = useState(false);
  const handleLogin = async (values) => {
    const handshake = getCookie("handshake");
    const res = await loginByEmail(handshake, values.username);
    console.log({ values, res });
    if (res?.data?.success !== "false" && res?.status === 200) {
      Context?.dispatch({ type: "otpMobileModal" });
      setCookie("email", values.username);
    } else {
      const error =
        res?.err?.response?.data?.error?.message?.errormsg ||
        res?.err?.response?.data?.error?.successmsg;
      res?.err?.response?.data?.error?.message?.successmsg;
      console.log("error_____________________", res);
      toast.error(
        error
          ? error
          : res?.data?.errormsg
          ? t(res?.data?.errormsg)
          : language === "en"
          ? "Something went wrong, please try again"
          : "حدث خطأ ما, الرجاء المحاولة مرة أخرى"
      );
    }
  };
  return (
    <Formik
      initialValues={loginMobileInitialValues}
      validationSchema={loginMobileValidationSchema(t)}
      onSubmit={handleLogin}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="grid grid-cols-2 mb-2">
            <button type="button" className="block w-full mb-1 font-semibold ">
              <span
                onClick={() => {
                  setLogin(false);
                  setLoginMobile(true);
                }}
                className="py-1 cursor-pointer hover:text-red hover:border-b-red"
              >
                {language === "en"
                  ? "Login using password"
                  : "تسجيل بإستخدام كلمة المرور"}
              </span>
            </button>
            <button
              type="button"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              <span
                onClick={() => {
                  setLogin(true);
                  setLoginMobile(false);
                }}
                className={`py-1 cursor-pointer hover:text-red ${
                  !loginMobile ? "border-b border-b-red" : ""
                } hover:border-b-red`}
              >
                {language === "en"
                  ? "Login via Otp"
                  : "تسجيل بإستخدام رمز التحقق"}
              </span>
            </button>
          </div>
          <div className="mb-2">
            <label
              htmlFor="username"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("Email")}
            </label>
            <Field
              name="username"
              className="w-full p-2 border rounded-md outline-0"
              placeholder={t("Email")}
              id="username"
            />
            <ErrorMessage name="username" component={TextError} />
          </div>
          <div className="flex items-center justify-between mb-2">
            <div
              onClick={() => setRemember(!remember)}
              className="flex items-center"
            >
              <div className="relative w-4 h-4 bg-white border border-gray-300 rounded-sm">
                {remember && (
                  <AiOutlineCheck
                    size={13}
                    className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-red"
                  />
                )}
              </div>
              <span className="mx-2 cursor-pointer text-zinc-600">
                {t("RememberMe")}
              </span>
            </div>
            <button
              onClick={() => Context?.dispatch({ type: "forgotModal" })}
              type="button"
              className="underline text-zinc-600"
            >
              {t("ForgotPassword")}
            </button>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full p-2 font-bold text-white rounded-md bg-red hover:bg-lightred disabled:cursor-wait"
          >
            {t(isSubmitting ? "Sending" : "Send")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default LoginMobile;
