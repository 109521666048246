import axios from "axios";

const registerApiRoute = async (data) => {
  let response;
    try {
      response = await axios({
        method: "POST",
        url: "/api/register",
        data,
      });
    } catch (error) {
      return error;
    }
  return response;
};

export default registerApiRoute;
