import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: 0, // Initial value set to 0 because window is undefined in SSR
    height: 0,
  });

  useEffect(() => {
    // Ensure this runs only on the client-side
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Set the initial size on mount
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let screen = "md";
  if (screenSize.width < 576) {
    screen = "sm"; // Small devices (landscape phones, 576px and up)
  } else if (screenSize.width < 768) {
    screen = "md"; // Medium devices (tablets, 768px and up)
  } else if (screenSize.width < 992) {
    screen = "lg"; // Large devices (desktops, 992px and up)
  } else if (screenSize.width < 1200) {
    screen = "xl"; // Extra large devices (large desktops, 1200px and up)
  } else {
    screen = "2xl"; // 2 Extra large devices (extra large desktops, 1400px and up)
  }

  return {
    screenSize: screen,
    isResponsive: screenSize.width < 576,
    screenWidth: screenSize.width,
  };
};

export default useScreenSize;
