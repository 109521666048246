import { AiOutlineMail } from "react-icons/ai";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { TextError } from "../Helpers/InputContainer";
import {
  forgotEmailInitialValues,
  forgotEmailValidationSchema,
} from "@/constants/Auth";
import { resetTokenAPIRoute } from "@/services/auth/forgetPassword";
import { useTranslation } from "next-i18next";

function ForgotPassword({ setState }) {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation("common");

  const handleSubmit = async ({ email }, setFieldError) => {
    const otpData = await resetTokenAPIRoute({ email }, setFieldError);
    if (otpData?.data?.data?.status == true) {
      setState((prev) => {
        return {
          ...prev,
          name: "verification-code",
          email,
          otp: otpData?.data?.data?.token,
        };
      });
    } else {
      setFieldError("email", t("EmailIsNotExist"));
    }
  };

  return (
    <section>
      <p className="my-3 text-zinc-600">{t("ForgotEmailDescription")}</p>
      <Formik
        initialValues={forgotEmailInitialValues}
        validationSchema={forgotEmailValidationSchema(t)}
        onSubmit={(values, { setFieldError }) =>
          handleSubmit(values, setFieldError)
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-2">
              <label
                className="block mb-1 font-semibold cursor-pointer"
                htmlFor="email"
              >
                {t("EmailAddress")}
              </label>
              <div className="relative">
                <Field
                  name="email"
                  className="w-full p-2 border rounded-md outline-0"
                  placeholder={t("EmailAddress")}
                  id="email"
                />
                <AiOutlineMail
                  size={24}
                  className={`absolute top-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } -translate-y-1/2 text-zinc-500`}
                />
              </div>
              <ErrorMessage name="email" component={TextError} />
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="w-full p-2 font-semibold text-white rounded-md disabled:cursor-not-allowed bg-red border-red hover:bg-lightred"
            >
              {t(isSubmitting ? "Sending" : "Send")}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default ForgotPassword;
