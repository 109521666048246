import { AiOutlineClose } from "react-icons/ai";
import { Toaster } from "react-hot-toast";
import { useState } from "react";

import ForgotPassword from "./ForgotPassword";
import Verification from "./Verification";
import ChangePassword from "./ChangePassword";
import { useTranslation } from "next-i18next";

function Forgot({ Context }) {
  const [state, setState] = useState({ name: "email", otp: "", email: "" });
  const { t } = useTranslation("common");
  return (
    <section className="fixed z-[3000] top-0 left-0 w-screen h-screen bg-lightblack flex items-center justify-center">
      <Toaster position="bottom-center" reverseOrder={true} />
      <div
        className={`bg-white p-3 rounded-md ${
          state !== "verification-code"
            ? "w-[600px] max-[600px]:w-full"
            : "w-[360px] max-[360px]:w-full"
        }`}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-extrabold">
            {state.name === "email"
              ? t("ForgotPassword")
              : state.name === "verification-code"
              ? t("VerificationCode")
              : t("ChangePassword")}
          </h1>
          <AiOutlineClose
            size={24}
            className="cursor-pointer"
            onClick={() => Context?.dispatch({ type: "forgotModal" })}
          />
        </div>
        {state.name === "email" ? (
          <ForgotPassword setState={setState} />
        ) : state.name === "verification-code" ? (
          <Verification {...{ state, setState }} />
        ) : (
          <ChangePassword Context={Context} state={state} />
        )}
      </div>
    </section>
  );
}

export default Forgot;
