import { memo } from "react";

export const ImgTag = memo(
  ({
    fetchpriority,
    src,
    alt,
    // maxWidthSrcset,
    srcSet,
    height,
    width,
    sizes,
    loading,
    className,
    ...props
  }) => {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        className={className || ""}
        src={src}
        alt={alt}
        // srcSet={`${src} ${maxWidthSrcset || ""}`}
        srcSet={srcSet}
        fetchpriority={fetchpriority || "auto"}
        height={height}
        width={width}
        sizes={sizes}
        loading={loading || "lazy"}
        role="img"
        {...props}
      />
    );
  }
);

ImgTag.displayName = "ImgTag";

export default ImgTag;

export const convertImg = (imgUrl, w, h) => {
  const first_part = imgUrl.split(".com/")[0];
  const last_part = imgUrl.split(".com/")[1];
  return `${first_part}.com/cdn-cgi/image/w=${w}&h=${h},fit=contain,format=auto,quality=75/${last_part}`;
};
