const DateCode = {
  1101: 1,
  1110: 2,
  1103: 3,
  1104: 3,
  1112: 4,
  1105: 5,
  1113: 6,
  1114: 7,
  1102: 8,
  1111: 9,
  1106: 10,
  1109: 11,
  1116: 12,
  1115: 13,
};

export default DateCode;
