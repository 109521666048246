import { Field, ErrorMessage } from "formik";
import { useState } from "react";

// Used in Login, Register, Update Email Component
function InputContainer({
  children,
  id,
  name,
  type,
  title,
  isFull,
  iconFillEye,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div
      className={`mb-[15px] flex flex-col ${
        isFull && "col-span-2"
      } max-[768px]:col-span-2`}
    >
      <label htmlFor={id} className="mx-2 mb-2 font-semibold cursor-pointer">
        {title}
      </label>
      <div className="relative">
        <Field
          id={id}
          name={name}
          type={
            type === "password" ? (showPassword ? "text" : "password") : "text"
          }
          placeholder={title}
          className="w-full py-2 px-3 border outline-none rounded-[10px]"
        />
        {iconFillEye
          ? type === "password" && (
              <button
                type="button"
                className={`curser-pointer`}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? children : iconFillEye}
              </button>
            )
          : children}
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
export default InputContainer;

export const TextError = (props) => {
  return <div className="my-1 text-red">{props.children}</div>;
};
