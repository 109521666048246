const IS_TYPESENSE_SEARCH =
  process.env.NEXT_PUBLIC_IS_TYPESENSE_SEARCH === "true";

const IS_TYPESENSE_GLOBAL =
  process.env.NEXT_PUBLIC_IS_TYPESENSE_GLOBAL === "true";

const PRODUCTS_AR = process.env.NEXT_PUBLIC_TYPESENSE_PRODUCTS_AR;
const PRODUCTS_EN = process.env.NEXT_PUBLIC_TYPESENSE_PRODUCTS_EN;
const CATEGORIES_AR = process.env.NEXT_PUBLIC_TYPESENSE_CATEGORIRES_AR;
const CATEGORIES_EN = process.env.NEXT_PUBLIC_TYPESENSE_CATEGORIRES_EN;
const SUGGESTIONS_AR = process.env.NEXT_PUBLIC_TYPESENSE_SUGGESSTIONS_AR;
const SUGGESTIONS_EN = process.env.NEXT_PUBLIC_TYPESENSE_SUGGESSTIONS_EN;

export {
  IS_TYPESENSE_GLOBAL,
  IS_TYPESENSE_SEARCH,
  PRODUCTS_AR,
  PRODUCTS_EN,
  CATEGORIES_AR,
  CATEGORIES_EN,
  SUGGESTIONS_AR,
  SUGGESTIONS_EN,
};
