import * as React from 'react'
const ArrowIcon = props => (
  <svg
    width={6}
    height={10}
    viewBox='0 0 6 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.23172 9.76877C-0.0765889 9.46046 -0.0765889 8.96059 0.23172 8.65228L3.884 5L0.23172 1.34772C-0.0765888 1.03941 -0.0765888 0.539539 0.23172 0.231232C0.540029 -0.0770769 1.0399 -0.0770769 1.3482 0.231232L5.55873 4.44176C5.86704 4.75007 5.86704 5.24993 5.55873 5.55824L1.3482 9.76877C1.0399 10.0771 0.540028 10.0771 0.23172 9.76877Z'
      fill='currentColor'
    />
  </svg>
)
export default ArrowIcon
