import { handshakeCookieName } from "@/constants";
import axios from "axios";
import { getCookie } from "cookies-next";

const client = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_BASEURL });

export const request = async ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer token`;
  const onSuccess = (response) => ({
    data: response.data,
    error: null,
    status: response?.status,
  });
  const onError = (error) => {
    // catch error here !!

    return { data: null, error: error?.message };
  };
  return client(options).then(onSuccess).catch(onError);
};

const axiosRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASEURL,
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosRequest.interceptors.request.use(
  async (config) => {
    if (typeof window === "object") {
      const locale = getCookie("NEXT_LOCALE") || "ar";
      const lang = window.location.pathname.startsWith('/en/') ? 'en' : locale;
      const cookieName = handshakeCookieName(lang);
      const handshake = getCookie(cookieName);
      console.log(
        "axios intetceptors config ===================>",
        getCookie("NEXT_LOCALE")
      , cookieName, handshake);
      if (handshake) {
        config.headers.Authorization = `Bearer ${handshake}`;
      }
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRequest.interceptors.response.use(
  (res) => {
    return Promise.resolve({ data: res.data, error: null, status: res.status });
  },
  (error) => {
    if (error.response) {
      // Handle HTTP errors

      return Promise.reject({
        data: null,
        err: error,
        error: error.response?.data?.message || error.message,
        status: error.response.status,
      });
    } else if (error.request) {
      // Handle network errors
      return Promise.reject({
        data: null,
        err: error,
        error: error.request?.data?.message || error.message,
        status: error.request.status,
      });
    } else {
      // Handle other errors
      return Promise.reject({
        data: null,
        err: error,
        error: error?.message,
        status: 500,
      });
    }
  }
);

export default axiosRequest;
