const keys = [
  "delivery_within_24_hours",
  "option_text_delivery_within_24_hours",
  "sku",
  "_media_",
  "attributes",
  "back_orders",
  "product_fees",
  "eligible_for_tabby",
  "eligible_for_tamara",
  "option_text_brand",
  "url_key",
  "image",
  "name",
  "prices_with_tax",
  "rewrite_url",
  "short_description",
  "description",
  "seller_id",
  "seller_logo",
  "seller_name",
  "_media_",
  "fast_delivery",
  "stock",
  "label",
  "custom_related_products",
  "option_text_brand",
  "_category_",
  "_configurables",
  "type_id",
  "entity_id",
  "suggest",
  "preorder_note",
  "not_for_sale",
  "1101",
  "1102",
  "1103",
  "1104",
  "1105",
  "1106",
  "1107",
  "1108",
  "1109",
  "1110",
  "1111",
  "1112",
  "1113",
  "1114",
  "1115",
  "1116",
  "1117",
];
export const selectedKeys = keys.map((item) => `select=${item}`).join("&");
