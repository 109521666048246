import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ForgetPasswordWithTokenAPIRoute } from "@/services/auth/forgetPassword";
import { TextError } from "../Helpers/InputContainer";
import { toast } from "react-hot-toast";
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from "@/constants/Auth";
import { useTranslation } from "next-i18next";
import { useState } from "react";

function ChangePassword({ Context, state }) {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation("common");
  const [show, setShow] = useState(false);
  const handleSubmit = async (values, { setSubmitting }) => {
    const changedPassword = await ForgetPasswordWithTokenAPIRoute({
      email: state.email,
      resetToken: state.otp,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
    });

    if (changedPassword?.data?.data == true) {
      toast.success(t("PasswordUpdatedSuccessfully"));
      setSubmitting(false);
      Context?.dispatch({ type: "forgotModal" });
      Context?.dispatch({ type: "authModal" });
    } else {
      toast.error(t("TryAgain"));
      setSubmitting(false);
    }
  };

  return (
    <section>
      <p className="my-3 text-zinc-600">{t("ForgotPasswordDescription")}</p>
      <Formik
        initialValues={changePasswordInitialValues}
        validationSchema={changePasswordValidationSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mb-2">
              <label
                className="block mb-1 font-semibold cursor-pointer"
                htmlFor="new-password"
              >
                {t("AskNewPassword")}
              </label>
              <div className="relative">
                <Field
                  name="newPassword"
                  className="w-full p-2 border rounded-md outline-0"
                  type={!show ? "password" : "text"}
                  placeholder={t("AskNewPassword")}
                  id="new-password"
                />
                {!show ? (
                  <AiOutlineEye
                    size={24}
                    className={`absolute top-1/2 ${
                      lang === "en" ? "right-1" : "left-1"
                    } -translate-y-1/2 text-zinc-500 cursor-pointer`}
                    onClick={() => setShow(!show)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={24}
                    className={`absolute top-1/2 ${
                      lang === "en" ? "right-1" : "left-1"
                    } -translate-y-1/2 text-zinc-500 cursor-pointer`}
                    onClick={() => setShow(!show)}
                  />
                )}
              </div>
              <ErrorMessage name="newPassword" component={TextError} />
            </div>
            <div className="mb-2">
              <label
                className="block mb-1 font-semibold cursor-pointer"
                htmlFor="rep-password"
              >
                {t("ConfirmPassword")}
              </label>
              <div className="relative">
                <Field
                  name="confirmPassword"
                  className="w-full p-2 border rounded-md outline-0"
                  type={!show ? "password" : "text"}
                  placeholder={t("ConfirmPassword")}
                  id="rep-password"
                />
                {!show ? (
                  <AiOutlineEye
                    size={24}
                    className={`absolute top-1/2 ${
                      lang === "en" ? "right-1" : "left-1"
                    } -translate-y-1/2 text-zinc-500 cursor-pointer`}
                    onClick={() => setShow(!show)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={24}
                    className={`absolute top-1/2 ${
                      lang === "en" ? "right-1" : "left-1"
                    } -translate-y-1/2 text-zinc-500 cursor-pointer`}
                    onClick={() => setShow(!show)}
                  />
                )}
              </div>
              <ErrorMessage name="confirmPassword" component={TextError} />
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="w-full p-2 font-semibold text-white rounded-md disabled:cursor-not-allowed bg-red border-red hover:bg-lightred"
            >
              {t(isSubmitting ? "Sending" : "ChangePassword")}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
}

export default ChangePassword;
