export const initialState = {
  modal: false,
  cartModal: false,
  authModal: false,
  otpMobileModal: false,
  forgotModal: false,
  tamaraModal: false,
  tabbyModal: false,
  tamaraPayload: 0,
  tabbyPayload: null,
  cartPayload: "",
  relatedProducts: [],
  productInfo: {},
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "cartModal":
      return {
        ...state,
        modal: !state.modal,
        cartModal: !state.cartModal,
        cartPayload: action.payload,
        relatedProducts: action.payloadRelatedProducts || [],
        productInfo: action?.productInfo,
      };
    case "authModal":
      return {
        ...state,
        modal: !state.modal,
        authModal: !state.authModal,
      };
    case "forgotModal":
      return {
        ...state,
        modal: state.authModal ? true : !state.modal,
        authModal: false,
        forgotModal: !state.forgotModal,
      };
    case "otpMobileModal":
      return {
        ...state,
        modal: state.authModal ? true : !state.modal,
        authModal: false,
        otpMobileModal: !state.otpMobileModal,
      };
    case "tamaraModal":
      return {
        ...state,
        modal: !state.modal,
        tamaraModal: !state.tamaraModal,
        tamaraPayload: action.payload,
      };
    case "tabbyModal":
      return {
        ...state,
        modal: !state.modal,
        tabbyModal: !state.tabbyModal,
        tabbyPayload: action.payload,
      };
    case "closeModal":
      return (state = initialState);
    default:
      return initialState;
  }
};
