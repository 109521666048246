
export const addToWishList = async (newProduct, wishList, globalState) => {
  let newArr =
    wishList && wishList.length > 0 ? [...wishList, newProduct] : [newProduct];
  console.log(newArr)
  localStorage.setItem("wishListInLocalStorage", JSON.stringify(newArr));
  globalState(newArr);
};

export const removeFromWishList = (removedProduct, wishList, globalState) => {
  let newArr = wishList.filter(
    (oldProduct) => oldProduct.sku != removedProduct.sku
  );
  localStorage.setItem("wishListInLocalStorage", JSON.stringify(newArr));
  globalState(newArr);

};

// function handleWishlistUpdate (wishlist){
//   const lineItems = wishlist.map(item => ({}))
// }