import { selectedKeys } from "@/contexts/global";
import axiosRequest from "@/utils/axios-utils";
import axios from "axios";

export const handleSearchApiRoute = async (params) => {
  const { word } = params;

  try {
    const res = await axios({
      method: "POST",
      url: `/api/search`,
      data: {
        word,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    return res?.data;
  } catch (error) {}
};

export const handleSearch = async (params) => {
  const { word, token, region_id, pageNo, sortFilterKeys } = params;
  const regionId =
    region_id && region_id != "NaN" && region_id != "undefined"
      ? region_id
      : "1101";
  const config = {
    method: "GET",
    url: `/facets/searchV2?search=${word}&pageSize=30&pageNo=${
      pageNo || "0"
    }&sortBy=position&sortDir=DESC&${selectedKeys}&select=${regionId}&regionId=${regionId}${
      sortFilterKeys ? `&${sortFilterKeys}` : ""
    }`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("seeeeeeeeeeeeeearch config", config);
  const res = await axiosRequest(config);
  console.log("elasticsearch response", res);
  return res;
};

export const fetchSortingFilteringProductsData = async (params) => {
  const { url } = params;
  try {
    const req = await axios.post("/api/sortingfilteringproductsdata", {
      url,
    });

    return req.data;
  } catch (error) {
    return error.message;
  }
};

export const getSortedFilteredProductsData = async (params) => {
  const { token, url } = params;

  const req = await axiosRequest(`/${url}&${selectedKeys}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("req in getSortedFilteredProductsData", req);
  return req;
};
