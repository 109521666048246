import { AiOutlineClose } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "next-i18next";
import {
  otpMobileInitialValues,
  otpMobileValidationSchema,
} from "@/constants/Auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextError } from "../Helpers/InputContainer";
import { verifyOTPByEmail } from "@/services/auth/login";
import { getCookie, getCookies, setCookie } from "cookies-next";
import { signIn } from "next-auth/react";
import { decodeJwt } from "@/utils/global";
import { useContext } from "react";
import AWSPersonalizeCtx from "@/contexts/aws-personalize/aws-personalize-context";
import { useCheckout } from "@/contexts/checkout";

function OtpMobileModal({ Context }) {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation("common");
  const { handshake } = getCookies();

  const { firstActionAfterLoginHandler } = useContext(AWSPersonalizeCtx);
  const { dispatch } = useCheckout();

  const handleSubmit = async ({ otp }, { resetForm }) => {
    const email = getCookie("email") ? getCookie("email") : null;
    if (email) {
      const { data, error } = await verifyOTPByEmail(
        handshake,
        email,
        otp,
        locale
      );

      if (error || data?.success == "false") {
        toast.error(
          data?.errormsg?.includes("Invalid")
            ? locale === "en"
              ? data?.errormsg
              : "الرمز غير صحيح"
            : locale === "en"
            ? "The attempt failed, please try again."
            : "فشلة المحاوله برجاء المحاوله مره اخرى"
        );
      } else {
        const mage = data?.token;
        const userData = data?.user;

        const login = await signIn("credentials", {
          user: JSON.stringify({
            ...userData,
          }),
          token: mage,
          redirect: false,
        });
        if (login.ok) {
          toast.success(
            locale == "en"
              ? "User Logged In SuccesFully"
              : " تم تسجيل الدخول بنجاح"
          );
          dispatch({ type: "RESET_CHECKOUT" });
          resetForm();
          const decodeMage = decodeJwt(mage);
          setCookie("mage", mage, {
            expires: new Date(decodeMage?.exp * 1000),
          });
          setCookie("email", null);
          firstActionAfterLoginHandler(); // for aws integration
          Context?.dispatch({ type: "otpMobileModal" });
          return;
        }
      }
    }
    return;
  };
  return (
    <section className="fixed z-[3000] top-0 left-0 w-screen h-screen bg-lightblack flex items-center justify-center">
      <Toaster position="bottom-center" reverseOrder={true} />
      <div className="bg-white p-3 rounded-md w-[600px] max-[600px]:w-full">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-extrabold">{t("VerificationCode")}</h1>
          <AiOutlineClose
            size={24}
            className="cursor-pointer"
            onClick={() => Context?.dispatch({ type: "otpMobileModal" })}
          />
        </div>
        <section>
          <p className="my-3 text-zinc-600">{t("OtpDescription")}</p>
          <Formik
            initialValues={otpMobileInitialValues}
            validationSchema={otpMobileValidationSchema(t)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-2">
                  <label
                    className="block mb-1 font-semibold cursor-pointer"
                    htmlFor="otp_number"
                  >
                    {t("Otp")}
                  </label>
                  <div className="relative">
                    <Field
                      name="otp"
                      className="w-full p-2 border rounded-md outline-0"
                      placeholder={t("Otp")}
                      id="otp_number"
                    />
                  </div>
                  <ErrorMessage name="otp" component={TextError} />
                </div>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full p-2 font-semibold text-white rounded-md disabled:cursor-not-allowed bg-red border-red hover:bg-lightred"
                >
                  {t(isSubmitting ? "Sending" : "Verify")}
                </button>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </section>
  );
}

export default OtpMobileModal;
