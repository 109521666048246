import { useRouter } from "next/router";
import { AiOutlineClose } from "react-icons/ai";
import ImgTag from "../Helpers/ImgTag";

function TamaraModal({ Context }) {
  const { locale } = useRouter();

  const TamarasOptions = [
    {
      id: 1,
      option: 2,
    },
    {
      id: 2,
      option: 3,
    },
    {
      id: 3,
      option: 4,
    },
  ];
  return (
    <div className=" fixed z-[3000] top-0 left-0 w-screen h-screen bg-lightblack flex items-center justify-center">
      <div className="tamara-modal-cont bg-white p-3 rounded-xl w-[600px] max-[600px]:w-full max-h-[95vh] overflow-y-auto">
        <div className="flex items-center justify-between">
          <ImgTag
            src={
              locale === "en"
                ? `${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=32&h=32,fit=contain,format=auto,quality=75/tamara-title.svg`
                : `${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=32&h=32,fit=contain,format=auto,quality=75/tamara-title-ar.svg`
            }
            className="flex-1 object-contain w-8 h-8 text-center"
            height={200}
            width={200}
            alt="Tamara Widget"
          />
          <AiOutlineClose
            onClick={() =>
              Context?.dispatch({ type: "tamaraModal", payload: "" })
            }
            size={28}
            className="cursor-pointer bg-[white] rounded-[50%] p-[6px] duration-[500] hover:scale-[105%] hover:duration-[500] hover:shadow-md"
          />
        </div>
        <h2 className=" text-[1.5rem] font-bold text-center mt-[1.2rem] mb-[.6rem]">
          {/*{locale === "en"
            ? `Split your bill into 4 payments of ${Context?.state.tamaraPayload || 0
            } SAR interest-free`
            : `قسم فاتورتك على 4 دفعات بقيمة ${Context?.state.tamaraPayload || 0
            } ر.س بدون فوائد`}*/}

          {locale === "en" ? `Pay easier with Tamara` : `ادفع بسهولة مع تمارا`}
        </h2>
        {/*<div className="grid grid-cols-4 gap-2 my-5 max-[570px]:grid-cols-2 max-[420px]:grid-cols-1">
          <div className="flex flex-col items-center w-full">
            <ImgTag
              src="/svgs/tamara-shopping-cart.svg"
              className="object-contain w-16 h-16"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="my-3 text-center">
              {locale === "en"
                ? "Add the item to your cart"
                : "أضف المنتجات إلى سلة التسوق"}
            </h5>
          </div>
          <div className="flex flex-col items-center w-full">
            <ImgTag
              src="/svgs/tamara-checkout.svg"
              className="object-contain w-16 h-16"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="my-3 text-center">
              {locale === "en"
                ? "Select Tamara at checkout"
                : "اختر تمارا عند الدفع"}
            </h5>
          </div>
          <div className="flex flex-col items-center w-full">
            <ImgTag
              src="/svgs/tamara-details.svg"
              className="object-contain w-16 h-16"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="my-3 text-center">
              {locale === "en" ? "Fill in your details" : "أضف بياناتك"}
            </h5>
          </div>
          <div className="flex flex-col items-center w-full">
            <ImgTag
              src="/svgs/tamara-first-payment.svg"
              className="object-contain w-16 h-16"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="my-3 text-center">
              {locale === "en"
                ? "Complete your first payment"
                : "أكمل دفعتك الأولى"}
            </h5>
          </div>
        </div>*/}

        <div className="splitted-cont flex flex-row items-start justify-center gap-[2%] my-[1rem]">
          {TamarasOptions.map((el) => {
            return <SplitItem key={el.id} el={el} locale={locale} />;
          })}
        </div>
        <p className="text-xs text-zinc-500 text-center pb-4 border-b border-[#0000000d] mb-4">
          {/*{locale === "en"
            ? "and pay the remaining in 2 months or according to the payment plan you selected"
            : "أكمل دفعاتك المتبقية خلال شهرين أو وفقًا لطريقة الدفع التي اخترتها"}*/}
        </p>
        <h2 className="text-xl font-bold text-center">
          {locale === "en" ? "Why Tamara?" : "لماذا اختار تمارا كوسيلة دفع؟"}
        </h2>
        <div className="grid grid-cols-3 gap-1 my-5 max-[360px]:grid-cols-1">
          <div className="flex flex-col items-center w-full">
            <ImgTag
              // src="/svgs/tamara-interest-free.svg"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=28&h=28,fit=contain,format=auto,quality=75/tamara-interest-free.svg`}
              className="object-contain h-7 w-7"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="text-center my-1 text-zinc-500 text-[14px]">
              {locale === "en" ? "Interest free" : "بدون فوائد"}
            </h5>
          </div>
          <div className="flex flex-col items-center w-full">
            <ImgTag
              // src="/svgs/no-hidden-fees.svg"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=28&h=28,fit=contain,format=auto,quality=75/no-hidden-fees.svg`}
              className="object-contain h-7 w-7"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="text-center my-1 text-zinc-500 text-[14px]">
              {locale === "en" ? "No hidden fees" : "بدون رسوم"}
            </h5>
          </div>
          <div className="flex flex-col items-center w-full">
            <ImgTag
              // src="/svgs/quick-and-easy.svg"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=28&h=28,fit=contain,format=auto,quality=75/quick-and-easy.svg`}
              className="object-contain h-7 w-7"
              height={120}
              width={120}
              alt="Img!"
            />
            <h5 className="text-center my-1 text-zinc-500 text-[14px]">
              {locale === "en" ? "Quick and easy" : "سهلة وسريعة"}
            </h5>
          </div>
        </div>
        <a
          className="block text-center underline text-blue"
          href="https://tamara.co/en"
          target="_blank"
        >
          {locale === "en" ? "Learn more about Tamara" : "إعرف أكثر عن تمارا"}
        </a>
        {locale === "en" ? (
          <div className="my-5 text-xs text-center text-zinc-500">
            (1){" "}
            <a
              href="https://www.tamara.co/en/terms-and-conditions"
              target="_blank"
              className="underline"
            >
              Terms and conditions
            </a>{" "}
            apply, (2) Tamara is{" "}
            <a
              href="https://www.tamara.co/en/shariah-compliance"
              target="_blank"
              className="underline"
            >
              Sharia-compliant
            </a>
            , (3) Eligible for customers in Saudi Arabia, (4) Your final payment
            plan may vary depending on your credit history.
          </div>
        ) : (
          <div className="my-5 text-xs text-center text-zinc-500">
            (1) تطبّق{" "}
            <a
              href="https://www.tamara.co/en/terms-and-conditions"
              target="_blank"
              className="underline"
            >
              الشروط والأحكام
            </a>{" "}
            , (2) موافقة{" "}
            <a
              href="https://www.tamara.co/en/shariah-compliance"
              target="_blank"
              className="underline"
            >
              الشريعة الإسلامية
            </a>
            , (3) متوفرة للعملاء في السعودية (4) قد تختلف خطة الدفع المقدمة لك
            تبعًا لتاريخك الائتماني مع تمارا*.
          </div>
        )}
      </div>
    </div>
  );
}

export default TamaraModal;

export function SplitItem({ el, locale }) {
  if (locale == "ar") {
    return (
      <div className="split-item-component w-[31%] flex flex-col items-center justify-start bg-[white] rounded-[10px] py-[8px] px-[4px] min-h-[140px]">
        <div class="tamara-popup__payment-type-icon">
          <div class="tamara-popup__installment-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.58464,3.20801C3.78464,3.20801 3.12631,3.86633 3.1263,4.67466L3.1263,5.208L2.5013,5.208C1.7013,5.208 1.04297,5.86634 1.04297,6.67467L1.04297,14.1663C1.04297,14.9747 1.7013,15.633 2.5013,15.633L15.8346,15.633C16.6346,15.633 17.293,14.9747 17.293,14.1663L17.293,13.633L17.918,13.633C18.718,13.633 19.3763,12.9747 19.3763,12.1663L19.3762,5.19134C19.3762,4.0997 18.4928,3.20806 17.4095,3.20801L4.58464,3.20801zM17.4096,4.45801C17.8096,4.45806 18.1262,4.78304 18.1262,5.19134L18.1263,12.1663C18.1263,12.2913 18.0346,12.383 17.918,12.383L17.293,12.383L17.293,6.67467C17.293,5.86634 16.643,5.208 15.8346,5.208L4.3763,5.208L4.3763,4.67466C4.37631,4.54967 4.46797,4.45801 4.58464,4.45801L17.4096,4.45801zM3.7513,11.258C3.33464,11.258 2.99297,10.9163 2.99297,10.4997C2.99297,10.083 3.33464,9.74134 3.7513,9.74134C4.16797,9.74134 4.50963,10.083 4.50963,10.4997C4.50963,10.9163 4.16797,11.258 3.7513,11.258zM13.7513,11.258C13.3346,11.258 12.993,10.9163 12.993,10.4997C12.993,10.083 13.3346,9.74134 13.7513,9.74134C14.168,9.74134 14.5096,10.083 14.5096,10.4997C14.5096,10.9163 14.168,11.258 13.7513,11.258z"
                fill="black"
              ></path>
            </svg>
            <div class="number-of-installments">{el.option}</div>
          </div>
        </div>
        <h4 class="tamara-popup__payment-name text-[.85rem] mt-[.3rem] mb-[.4rem] font-[600]">
          {el.option == 2
            ? "قسّمها على دفعتين"
            : el.option == 3
            ? "قسّمها على 3 دفعات"
            : "قسّمها على 4 دفعات "}
        </h4>
        <p class="tamara-popup__payment-description text-center text-[12px] leading-[.9rem] mb-[.5rem] max-w-[80%]">
          {el.option == 2
            ? " ادفع جزء من المبلغ الآن والباقي خلال شهر حسب خطة الدفع، بدون فوائد ورسوم خفية!*"
            : el.option == 3
            ? "   ادفع جزء من المبلغ الآن والباقي خلال شهرين حسب خطة الدفع، بدون فوائد ورسوم خفية!*"
            : "ادفع جزء من المبلغ الآن والباقي خلال 3 أشهر حسب خطة الدفع، بدون فوائد ورسوم خفية*"}
        </p>
      </div>
    );
  } else {
    return (
      <div className="split-item-component w-[31%] flex flex-col items-center justify-start bg-[white] rounded-[10px] py-[8px] px-[4px]  min-h-[155px]">
        <div class="tamara-popup__payment-type-icon">
          <div class="tamara-popup__installment-icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.58464,3.20801C3.78464,3.20801 3.12631,3.86633 3.1263,4.67466L3.1263,5.208L2.5013,5.208C1.7013,5.208 1.04297,5.86634 1.04297,6.67467L1.04297,14.1663C1.04297,14.9747 1.7013,15.633 2.5013,15.633L15.8346,15.633C16.6346,15.633 17.293,14.9747 17.293,14.1663L17.293,13.633L17.918,13.633C18.718,13.633 19.3763,12.9747 19.3763,12.1663L19.3762,5.19134C19.3762,4.0997 18.4928,3.20806 17.4095,3.20801L4.58464,3.20801zM17.4096,4.45801C17.8096,4.45806 18.1262,4.78304 18.1262,5.19134L18.1263,12.1663C18.1263,12.2913 18.0346,12.383 17.918,12.383L17.293,12.383L17.293,6.67467C17.293,5.86634 16.643,5.208 15.8346,5.208L4.3763,5.208L4.3763,4.67466C4.37631,4.54967 4.46797,4.45801 4.58464,4.45801L17.4096,4.45801zM3.7513,11.258C3.33464,11.258 2.99297,10.9163 2.99297,10.4997C2.99297,10.083 3.33464,9.74134 3.7513,9.74134C4.16797,9.74134 4.50963,10.083 4.50963,10.4997C4.50963,10.9163 4.16797,11.258 3.7513,11.258zM13.7513,11.258C13.3346,11.258 12.993,10.9163 12.993,10.4997C12.993,10.083 13.3346,9.74134 13.7513,9.74134C14.168,9.74134 14.5096,10.083 14.5096,10.4997C14.5096,10.9163 14.168,11.258 13.7513,11.258z"
                fill="black"
              ></path>
            </svg>
            <p class="number-of-installments">{el.option}</p>
          </div>
        </div>
        <h4 class="tamara-popup__payment-name text-[.85rem] mt-[.3rem] mb-[.4rem] font-[600]">
          {el.option == 2
            ? "Split in 2 "
            : el.option == 3
            ? " Split in 3"
            : "  Split in 4"}
        </h4>
        <p class="tamara-popup__payment-description text-center text-[12px] leading-[.9rem] mb-[.5rem] max-w-[80%]">
          {el.option == 2
            ? " Pay a fraction now and the rest in a month. No hidden fees, no interest!*"
            : el.option == 3
            ? "Pay a fraction now and the rest in 2 payments over 2 months. No hidden fees, no interest!*"
            : "Pay a fraction now and the rest in 3 payments over the next 3 months. No hidden fees, no interest!*"}
        </p>
      </div>
    );
  }
}
