import CurrencyIcon from "@/components/icons/CurrencyIcon";
import { validateProductPrice } from "@/utils/product";
import React from "react";

const ProductPrice = ({ price: prices_with_tax, locale }) => {
  const price = validateProductPrice(prices_with_tax);
  return (
    <>
      <div
        className={` flex items-center ${
          price?.discounted_percentage === 0
            ? "justify-center"
            : "justify-between text-[14px]"
        } ${locale === "en" ? "flex-row" : "flex-row-reverse"} min-h-8  `}
      >
        <p
          className={`text-red flex items-center gap-1 text-center ${
            price.discounted_percentage === 0 ? "text-[20px] " : "text-[16px] "
          }`}
        >
          <span className="font-semibold">{price?.discounted_price}</span>
          <CurrencyIcon
            className={
              price.discounted_percentage === 0 ? "size-5" : "size-[14px]"
            }
          />
        </p>
        {price.discounted_percentage === 0 ? null : (
          <p className="text-zinc-600 flex items-center gap-1 line-through text-[14px] mx-2">
            <span className="font-semibold">{price?.original_price}</span>
            <CurrencyIcon className="size-[14px]" />
          </p>
        )}
      </div>
      <div
        className={`h-4 mb-2 w-11/12 px-1.5 rounded-full mx-auto flex items-center justify-between ${
          price?.discounted_percentage && "bg-[#95FBDE]"
        } text-[10px] `}
      >
        {price?.discounted_percentage > 0 && (
          <>
            <span>
              {locale === "en" ? "OFF" : "خصم"} {price?.discounted_percentage}%
            </span>
            <span className="flex items-center gap-0.5">
              <span>
                {locale === "en" ? "Save" : "وفر "} {price?.discount_amount}
              </span>
              <CurrencyIcon className="w-2 h-2" />
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default ProductPrice;
