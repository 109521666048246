import {
  CATEGORIES_AR,
  CATEGORIES_EN,
  PRODUCTS_AR,
  PRODUCTS_EN,
  SUGGESTIONS_AR,
  SUGGESTIONS_EN,
} from "@/utils/typesense/config";

const FACETS = [
  "brand",
  "categories_filter",
  "tv_screen_size",
  "display_size_filter",
  "processor_manufacturer_compute",
  "hard_drive_type_computer_filte",
  "mobile_os",
  "computer_accessories_type_filt",
  "processor_type_computer_filter",
  "network",
  "headset_type",
  "clearness_type",
  "monitor_type",
  "ram_computer_filter",
  "compatibility_mobile_accessori",
  // "fast_delivery",
  "hard_capacity_computer_filter",
];

const DEFAULT_CONFIGS = (isStopTypeCorrection) => {
  return {
    typo_tokens_threshold: isStopTypeCorrection ? 0 : 1,
    num_typos: 2,
    min_len_1typo: 4,
    min_len_2typo: 4,
  };
};

const COLLECTIONS = (locale = "ar") => {
  return {
    products_ar: PRODUCTS_AR,
    products_en: PRODUCTS_EN,
    categories_ar: CATEGORIES_AR,
    categories_en: CATEGORIES_EN,
    suggesstion: locale === "ar" ? SUGGESTIONS_AR : SUGGESTIONS_EN,
  };
};

export { FACETS, DEFAULT_CONFIGS, COLLECTIONS };
