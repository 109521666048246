import { BsEnvelope } from "react-icons/bs";
import { AiOutlineEye, AiFillEyeInvisible } from "react-icons/ai";
import { object, string, ref, number } from "yup";

const passwordObject = (t, lang) => ({
  id: "password",
  type: "password",
  name: "password",
  icon: (
    <AiOutlineEye
      size="19px"
      className={`absolute top-1/2 ${
        lang === "en" ? "right-2" : "left-2"
      } -translate-y-1/2 text-zinc-400`}
    />
  ),
  iconFillEye: (
    <AiFillEyeInvisible
      size="19px"
      className={`absolute top-1/2 ${
        lang === "en" ? "right-2" : "left-2"
      } -translate-y-1/2 text-zinc-400`}
    />
  ),
  title: t("Password"),
  name: "password",
  isFull: true,
});

const emailObject = (t, lang, name = "email", title = "EmailAddress") => ({
  id: "email-address",
  type: "email",
  name,
  icon: (
    <BsEnvelope
      size="19px"
      className={`absolute top-1/2 ${
        lang === "en" ? "right-2" : "left-2"
      } -translate-y-1/2 text-zinc-400`}
    />
  ),
  title: t(title),
  isFull: true,
});

export const AuthConstants = (t, lang) => {
  return [emailObject(t, lang), passwordObject(t, lang)];
};

export const RegisterAuthConstants = (t, lang) => {
  return [
    {
      id: "first-name",
      type: "text",
      icon: "",
      name: "firstName",
      title: t("FirstName"),
      isFull: false,
    },
    {
      id: "last-name",
      type: "text",
      icon: "",
      name: "lastName",
      title: t("LastName"),
      isFull: false,
    },
    emailObject(t, lang),
    emailObject(t, lang, "confirmEmail", t("confirmEmailAddress")),
    passwordObject(t, lang),
    {
      id: "confirm-password",
      type: "password",
      icon: (
        <AiOutlineEye
          size="19px"
          className={`absolute top-1/2 ${
            lang === "en" ? "right-2" : "left-2"
          } -translate-y-1/2 text-zinc-400`}
        />
      ),
      iconFillEye: (
        <AiFillEyeInvisible
          size="19px"
          className={`absolute top-1/2 ${
            lang === "en" ? "right-2" : "left-2"
          } -translate-y-1/2 text-zinc-400`}
        />
      ),
      title: t("ConfirmPassword"),
      name: "confirmPassword",
      isFull: true,
    },
  ];
};

export const loginInitialValues = {
  password: "",
  username: "",
};

export const loginMobileInitialValues = {
  phone_number: "",
};

export const registerInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  terms: "",
  phone_number: "",
};

export const loginValidationSchema = (t) =>
  object().shape({
    username: string()
      .matches(
        /^(05\d{8}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        t(
          "Please enter a valid email or 10-digit mobile number starting with 05"
        )
      )
      .required(t("EmailRequired")),
    password: string().required(t("RequiredPassword")),
  });

export const loginMobileValidationSchema = (t) =>
  object().shape({
    username: string()
      .matches(
        /^(05\d{8}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        t(
          "Please enter a valid email or 10-digit mobile number starting with 05"
        )
      )
      .required(t("EmailRequired")),
    // phone_number: string()
    //   .label("Phone number")
    //   .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
    //   .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
    //   .min(10)
    //   .max(10)
    //   .required(t("TelephoneRequired")),
  });

export const registerValidationSchema = (t) =>
  object().shape({
    firstName: string().required(t("FirstNameRequired")),
    lastName: string().required(t("LastNameRequired")),
    terms: string().required(t("YouMustAcceptTerms")),
    email: string().email(t("InvalidEmail")).required(t("EmailRequired")),
    phone_number: string()
      .label("Phone number")
      .matches(/^(?!.*[\u0660-\u0669])[0-9]+$/, t("telephoneError1"))
      .matches(/^(05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/, t("telephoneError2"))
      .min(10)
      .max(10)
      .required(t("TelephoneRequired")),
    password: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
    confirmPassword: string()
      .oneOf([ref("password"), null], t("PasswordsMustMatch"))
      .required(t("ConfirmPasswordRequired")),
  });

export const forgotEmailInitialValues = {
  email: "",
};

export const otpMobileInitialValues = {
  otp: "",
};

export const forgotEmailValidationSchema = (t) =>
  object().shape({
    email: string().email(t("NotValidEmail")).required(t("RequiredEmail")),
  });

export const otpMobileValidationSchema = (t) =>
  object().shape({
    otp: string().required(t("RequiredOtp")),
  });

export const verificationCodeInitialValues = {
  first: "",
  second: "",
  third: "",
  forth: "",
  fifth: "",
};

export const verificationCodeValidationSchema = () =>
  object().shape({
    first: number().min(0, "-").max(9, "--").required("---"),
    second: number().min(0, "-").max(9, "--").required("---"),
    third: number().min(0, "-").max(9, "--").required("---"),
    forth: number().min(0, "-").max(9, "--").required("---"),
    fifth: number().min(0, "-").max(9, "--").required("---"),
  });

export const changePasswordInitialValues = {
  newPassword: "",
  confirmPassword: "",
};

export const changePasswordValidationSchema = (t) =>
  object().shape({
    newPassword: string()
      .min(8, t("HasAtLeast8Characters"))
      .matches(/[A-Z]/, t("ContainsOneUppercaseLetter"))
      .matches(/[a-z]/, t("ContainsOneLowercaseLetter"))
      .matches(/[0-9]/, t("ContainsOneNumber"))
      .required(t("PasswordRequired")),
    confirmPassword: string()
      .oneOf([ref("newPassword"), null], t("PasswordsMustMatch"))
      .required(t("ConfirmPasswordRequired")),
  });
