import {
  AiOutlineHome,
  // AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineHeart,
  AiOutlineWhatsApp,
} from "react-icons/ai";

// Used in FixedBox Componant in Layout Folder
export const FixedButtons = [
  {
    icon: <AiOutlineHome size={20} />,
    link: "/",
    name: "home",
  },
  {
    icon: <AiOutlineUser size={20} />,
    link: "",
    name: "Account",
  },
  // {
  //   icon: <AiOutlineShoppingCart size={20} />,
  //   link: "/cart",
  //   name: "Cart",
  // },
  {
    icon: <AiOutlineHeart size={20} />,
    link: "/user/favourites",
    name: "MyFavourites",
  },
  {
    icon: <AiOutlineWhatsApp size={20} />,
    link: "https://api.whatsapp.com/send/?phone=966920008171&text&type=phone_number&app_absent=0",
    name: "Whatsapp",
  }
];

export const NavConst = [
  { name: "AirCondition", link: "" },
  { name: "HomeDevices", link: "" },
  { name: "SmallHomeDevices", link: "" },
  { name: "KitchenDevices", link: "" },
  { name: "PersonalCare", link: "" },
  { name: "Electronics", link: "" },
];

// used in Bottom Footer
export const BottomFooterConst = [
  { title: "AboutCompany", link: "/about-us", needLocale: true },
  { title: "Services", link: "/static/services", needLocale: true },
  { title: "Exhibitions", link: "/store", needLocale: false },
  {
    title: "TaxCertificate",
    link: "/static/certificates/company-taxes-certificate",
    needLocale: false,
  },
];
