import { AiOutlineClose } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "next-i18next";
import {
  otpMobileInitialValues,
  otpMobileValidationSchema,
} from "@/constants/Auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextError } from "../Helpers/InputContainer";

function Verification({ state, setState }) {
  const { t } = useTranslation("common");
  const handleSubmit = async ({ otp }) => {
    if (otp === state.otp) {
      setState((prev) => {
        return { ...prev, name: "change-password" };
      });
    } else {
      toast.error(t("Invalid OTP."));
    }
  };
  return (
    <section className="fixed z-[3000] top-0 left-0 w-screen h-screen bg-lightblack flex items-center justify-center">
      <Toaster position="bottom-center" reverseOrder={true} />
      <div className="bg-white p-3 rounded-md w-[600px] max-[600px]:w-full">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-extrabold">{t("VerificationCode")}</h1>
          <AiOutlineClose
            size={24}
            className="cursor-pointer"
            onClick={() =>
              setState((prev) => {
                return { ...prev, modal: "forgot" };
              })
            }
          />
        </div>
        <section>
          <p className="my-3 text-zinc-600">{t("OtpDescription")}</p>
          <Formik
            initialValues={otpMobileInitialValues}
            validationSchema={otpMobileValidationSchema(t)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-2">
                  <label
                    className="block mb-1 font-semibold cursor-pointer"
                    htmlFor="otp_number"
                  >
                    {t("Otp")}
                  </label>
                  <div className="relative">
                    <Field
                      name="otp"
                      className="w-full p-2 border rounded-md outline-0"
                      placeholder={t("Otp")}
                      id="otp_number"
                    />
                  </div>
                  <ErrorMessage name="otp" component={TextError} />
                </div>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full p-2 font-semibold text-white rounded-md disabled:cursor-not-allowed bg-red border-red hover:bg-lightred"
                >
                  {t(isSubmitting ? "Sending" : "Verify")}
                </button>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </section>
  );
}

export default Verification;
