const gtmEvent = (event, data) => {
  if(typeof window != undefined){
    window.dataLayer = window.dataLayer || []
    console.log(event, data)
    setTimeout(() => { 
      window?.dataLayer?.push({
        event,
        ecommerce: data,
      });
     }, 1000)
  }
};

const event_category_viewed = (categoryname, categoryid, productcount, image) =>
  gtmEvent("category_viewed", {
    categoryname,
    categoryid,
    productcount,
    image,
  });

  const event_view_promotion = (creative_name, creative_slot, promotion_id, promotion_name,currency,items) =>
  gtmEvent("view_promotion", {
    creative_name,
    creative_slot,
    promotion_id,
    promotion_name,
    currency,
    items
  });

  const event_select_promotion = (creative_name, creative_slot, promotion_id, promotion_name,currency,items) =>
  gtmEvent("select_promotion", {
    creative_name,
    creative_slot,
    promotion_id,
    promotion_name,
    currency,
    items
  });

  const event_page_viewed = (currency, language, is_login, user_id, email, phone) =>
  gtmEvent("page_viewed", {
    currency,
    language,
    is_login,
    user_id,
    email,
    phone,
  });

const event_sub_category_viewed = (
  subcategoryname,
  subcategoryid,
  productcount,
  categoryname,
  categoryid,
  type
) =>
  gtmEvent("sub_category_viewed", {
    subcategoryname,
    subcategoryid: subcategoryid?.toString(),
    productcount,
    categoryname, 
    categoryid: categoryid?.toString(),
    type,
  });

const event_product_viewed = (
  skuid,
  skuname,
  price,
  image,
  brand,
  categoryid,
  categoryname,
  subcategoryid,
  subcategoryname,
  type
) =>
  gtmEvent("product_viewed", {
    skuid: skuid?.toString(),
    skuname,
    price,
    image,
    brand,
    categoryid: categoryid?.toString(),
    categoryname,
    subcategoryid: subcategoryid?.toString(),
    subcategoryname,
    type,
  });

const event_add_to_cart = (
  skuid,
  skuname,
  quantity,
  price,
  image,
  brand,
  type,
  categoryid,
  categoryname,
  subcategoryid,
  subcategoryname
) =>
  gtmEvent("add_to_cart", {
    skuid: skuid.toString(),
    skuname,
    quantity,
    price,
    image,
    brand,
    type,
    categoryid: categoryid?.toString(),
    categoryname,
    subcategoryid: subcategoryid?.toString(),
    subcategoryname,
  });

const event_remove_from_cart = (
  skuid,
  skuname,
  quantity,
  price,
  brand,
  type,
  image,
  categoryid,
  categoryname,
  subcategoryid,
  subcategoryname
) =>
  gtmEvent("remove_from_cart", {
    skuid,
    skuname,
    quantity,
    price,
    brand,
    type,
    image,
    categoryid : categoryid?.toString(),
    categoryname,
    subcategoryid : subcategoryid?.toString(),
    subcategoryname,
  });

const event_add_to_wishlist = (
  skuid,
  skuname,
  price,
  brand,
  type,
  image,
  categoryid,
  categoryname,
  subcategoryid,
  subcategoryname
) =>
  gtmEvent("add_to_wishlist", {
    skuid,
    skuname,
    quantity : 1,
    price,
    brand,
    type,
    image,
    categoryid: categoryid?.toString() ,
    categoryname,
    subcategoryid: subcategoryid?.toString() ,
    subcategoryname,
  });

const event_remove_from_wishlist = (
  skuid,
  skuname,
  price,
  brand,
  image,
  type,
  categoryid,
  categoryname,
  sub_categoryid,
  subcategoryname
) =>
  gtmEvent("remove_from_wishlist", {
    skuid,
    skuname,
    quantity : 1,
    price,
    brand,
    image,
    type,
    categoryid: categoryid?.toString(),
    categoryname,
    sub_categoryid: sub_categoryid?.toString(),
    subcategoryname,
  });

const event_cart_updated = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total
) =>{
  console.log('items', lineitems)
  const getImageUrl = (item) => {
    const productImage = item?.extension_attributes?.product_image;
    const image = item?.image;
    const media = item?._media_;
    if (productImage && productImage.startsWith('http')) return productImage;
    if (productImage) return `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${productImage}`;
    if (media && media.length > 0) return media?.at(0).image;
    if(image && Array.isArray(image) && image?.at(0)?.startsWith('http')) return image?.at(0)
    if(image && image?.at(0)?.startsWith('http')) return image
    if(image) return `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${image}`;
    return null;
  }
  gtmEvent("cart_updated", {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      Image: getImageUrl(item),
      price: item?.price_incl_tax,
      quantity: item?.qty,
      categoryname    : item?.extension_attributes?.category_names?.at(0)?.name || item.categoryname || '',
      subcategoryname : item?.extension_attributes?.category_names?.at(1)?.name || item.subcategoryname || ''
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
  });}

const event_wishlist_updated = (wishlist) => 
{  
  console.log('wishlist', wishlist)
  const total = wishlist.reduce(
    (total, item) =>
      total + item?.prices_with_tax?.price,
    0
  )
  const lineitems = wishlist.map((item) => ({
    sku: Array.isArray(item?.sku) ? item?.sku.at(0) : item?.sku,
    name: Array.isArray(item?.name) ? item?.name.at(0) : item?.name,
    image:item?.image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.image}` : '',
    price: item?.prices_with_tax?.price,
    quantity: 1,
    categoryname    : item?._category_?.main_category?.name || '',
    subcategoryname : item?._category_?.sub_categories?.at(0)?.name || ''
  }))
  gtmEvent("wishlist_updated", {
    lineitems,
    numberofproducts: wishlist.length,
    skuid: wishlist.map((item) => item.sku).join(","),
    skuname: wishlist.map((item) => Array.isArray(item.name) ? item?.name.at(0) : item?.name).join(","),
    subtotal : .85 * total,
    tax : .15 * total,
    total,
  })};

const event_cart_viewed = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  category,
  subcategory,
  subtotal,
  tax,
  total
) =>
  gtmEvent("cart_viewed", {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      image: item?.extension_attributes?.product_image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`: '',
      quantity: item?.qty,
      price: item?.price_incl_tax,
      categoryname    : item?.extension_attributes?.category_names?.at(0).name,
      subcategoryname : item?.extension_attributes?.category_names?.at(1)?.name
    })),
    numberofproducts,
    skuid,
    skuname,
    category,
    subcategory,
    subtotal,
    tax,
    total,
  });

const event_wishlist_viewed = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total
) =>
  gtmEvent("wishlist_viewed", {
    lineitems: lineitems?.map((item) => ({
      name: item?.name.at(0),
      image: item?.extension_attributes?.product_image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`: '',
      price: item?.prices_with_tax?.price,
      quantity: 1,
      brand: item?.option_text_brand.at(0),
      categoryname    : item?.extension_attributes?.category_names?.at(0).name,
      subcategoryname : item?.extension_attributes?.category_names?.at(1)?.name
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
  });

const event_product_searched = (keyword, total_items) =>
  gtmEvent("product_searched", { keyword, total_items });

const event_checkout_initiated = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total,
  categoryname,
  subcategoryname
) =>
  gtmEvent("checkout_initiated", {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      image: item?.extension_attributes?.product_image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`: '',
      quantity: item?.qty,
      price: item?.price_incl_tax,
      categoryname    : item?.extension_attributes?.category_names?.at(0).name,
      subcategoryname : item?.extension_attributes?.category_names?.at(1)?.name
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
    categoryname,
    subcategoryname,
  });

const event_checkout_address_saved = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total,
  shippingcharges,
  city,
  district,
  categoryname,
  subcategoryname,
  email,
  phoneNumber,
  firstName,
  lastName,
) =>
  gtmEvent("checkout_address_saved", {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      image: item?.extension_attributes?.product_image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`: '',
      quantity: item?.qty,
      price: item?.price_incl_tax,
      categoryname    : item?.extension_attributes?.category_names?.at(0).name,
      subcategoryname : item?.extension_attributes?.category_names?.at(1)?.name
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
    shippingcharges,
    city,
    district,
    categoryname,
    subcategoryname,
    email,
    phoneNumber,
    firstName,
    lastName,
  });

const event_checkout_started = (
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total,
  shippingcharges,
  paymentmode,
  city,
  district,
  categoryname,
  subcategoryname
) =>
  gtmEvent("checkout_started", {
    lineitems: lineitems?.map((item) => ({
      name: item?.name,
      image: item?.extension_attributes?.product_image ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${item?.extension_attributes?.product_image}`: '',
      quantity: item?.qty,
      price: item?.price_incl_tax,
      categoryname    : item?.extension_attributes?.category_names?.at(1)?.name,
      subcategoryname : item?.extension_attributes?.category_names?.at(2)?.name
    })),
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
    shippingcharges,
    paymentmode,
    city,
    district,
    categoryname,
    subcategoryname,
  });

const event_payment_failed = (amount, mode, reason) =>
  gtmEvent("payment_failed", { amount, mode, reason });

const event_purchase = (
  orderid,
  lineitems,
  numberofproducts,
  skuid,
  skuname,
  subtotal,
  tax,
  total,
  shippingcharges,
  paymentmode,
  city,
  district,
  categoryname,
  subcategoryname,
  coupon,
  customer
) =>
  {
    console.log("inside the event")
    gtmEvent("purchase", {
    orderid,
    lineitems,
    numberofproducts,
    skuid,
    skuname,
    subtotal,
    tax,
    total,
    shippingcharges,
    paymentmode,
    city,
    district,
    categoryname,
    subcategoryname,
    coupon,
    customer
  });}
  
const event_mobile_number = (mobilenumber) =>
  gtmEvent("mobile_number", { mobilenumber });

const event_user_login = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent("user_login", {
    firstname,
    lastname,
    mobilenumber,
    email,
    totalordercount,
    Totalltv,
  });
const event_user_logged_in = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent("user_logged_in", {
    firstname,
    lastname,
    mobilenumber,
    email,
    totalordercount,
    Totalltv,
  });

const event_user_signup = (
  firstname,
  lastname,
  mobilenumber,
  email,
  totalordercount,
  Totalltv
) =>
  gtmEvent("user_signup", {
    firstname,
    lastname,
    mobilenumber,
    email,
    totalordercount,
    Totalltv,
  });
const event_user_logout = (
  firstname,
  lastname,
  email,
  userid
) =>
  gtmEvent("user_logout", {
    firstname,
    lastname,
    email,
    userid
  });

  const event_newsletter_subscribed = (
    email,
  ) =>
    gtmEvent("newsletter_subscribed", {
      email,
    });

  // =========================================| NEW TEAM REQUIREMENTS |=========================================
  const event_page_view = (language, user) => {
    gtmEvent('page_view', {
      currency: 'SAR',
      language,
      is_login:user?.id ? 'yes' : 'no',
      user_id: user?.id,
      email:user?.email,
      phone: user?.telephone,
    })
  }
export {
  event_category_viewed,
  event_view_promotion,
  event_select_promotion,
  event_sub_category_viewed,
  event_product_viewed,
  event_add_to_cart,
  event_remove_from_cart,
  event_add_to_wishlist,
  event_remove_from_wishlist,
  event_cart_updated,
  event_wishlist_updated,
  event_cart_viewed,
  event_wishlist_viewed,
  event_product_searched,
  event_checkout_initiated,
  event_checkout_address_saved,
  event_checkout_started,
  event_payment_failed,
  event_purchase,
  event_mobile_number,
  event_user_login,
  event_user_signup,
  event_page_viewed,
  event_newsletter_subscribed,
  event_user_logout,
  event_user_logged_in,
  event_page_view
};
