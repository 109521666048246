import { useEffect, useRef, useState } from "react";
import { signOut } from "next-auth/react";
import { AiOutlineDashboard, AiOutlineUser } from "react-icons/ai";
import { MdKeyboardArrowDown, MdLogout } from "react-icons/md";
import browserStorage from "store";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { setCookie, deleteCookie } from "cookies-next";

function UserAuth({ session }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");
  const router = useRouter();

  const [hoverDash, sethoverDash] = useState(false);
  const [hoverLogOut, sethoverLogOut] = useState(false);

  // Outside CLick
  const containerRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // show telesales mark!

  const [telesalesHeaderUserShow, settelesalesHeaderUserShow] =
    useState("hidden");
  console.log("user", session?.user);
  return (
    <div
      className="relative flex flex-col items-start justify-center"
      onMouseOver={() => settelesalesHeaderUserShow("")}
      onMouseOut={() => settelesalesHeaderUserShow("hidden")}
    >
      <div
        ref={containerRef}
        className={`border p-1.5 rounded-md mx-2 relative min-w-[150px] max-w-[150px] max-[991px]:hidden ${
          process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme" && "bg-white"
        } ${
          session.user.telesales ? "header-user-border-telesales" : "border-red"
        } `}
      >
        <div
          onClick={() => setOpen(!open)}
          className="flex items-center justify-between cursor-pointer"
        >
          <div className="flex items-center flex-1">
            <AiOutlineUser size="20px" className="text-red" />
            <span
              title={`${session?.user?.firstname} ${session?.user?.lastname}`}
              className="mx-1 overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {session?.user?.firstname}
            </span>
          </div>
          <MdKeyboardArrowDown
            size="20px"
            className={`mt-0.5 ${open ? "rotate-180" : "rotate-0"}`}
          />
        </div>
        {open && (
          <div className="absolute top-full left-0.5 w-full mt-1 z-30">
            <div className="p-1 duration-100 bg-white border rounded-md border-red hover:bg-red">
              <button
                onClick={() => {
                  router.push("/user/profile");
                  setOpen(!open);
                  sethoverDash(false);
                }}
                onMouseOver={() => sethoverDash(true)}
                onMouseOut={() => sethoverDash(false)}
                className="flex items-center"
              >
                {hoverDash ? (
                  <>
                    <div className="duration-100 text-red">
                      <AiOutlineDashboard
                        size="22px"
                        className="text-white duration-100"
                      />
                    </div>
                    <span className="mx-1 text-white duration-100">
                      {t("Dashboard")}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="duration-100 text-red">
                      <AiOutlineDashboard
                        size="22px"
                        className="duration-100"
                      />
                    </div>
                    <span className="mx-1 duration-100">{t("Dashboard")}</span>
                  </>
                )}
              </button>
            </div>
            <div
              className="p-1 my-1 duration-100 bg-white border rounded-md border-red hover:bg-red"
              onMouseOver={() => sethoverLogOut(true)}
              onMouseOut={() => sethoverLogOut(false)}
            >
              <button
                onClick={() => {
                  setOpen(!open);
                  sethoverLogOut(false);
                  deleteCookie("mage");
                  deleteCookie("quote");
                  deleteCookie("region_id");
                  browserStorage.remove("cart");
                  browserStorage.remove("checkout");
                  browserStorage.set(
                    "usr",
                    JSON.stringify({
                      firstname: session?.user?.firstname,
                      lastname: session?.user?.lastname,
                      email: session?.user?.email,
                      id: session?.user?.id,
                    })
                  );
                  setCookie("out", "true");
                  setTimeout(() => {
                    signOut({ redirect: false });
                    router.reload();
                  }, 0);
                }}
                className="flex items-center"
              >
                {hoverLogOut ? (
                  <>
                    <div className="">
                      <MdLogout
                        size="1.4rem "
                        className="text-white duration-100"
                      />
                    </div>
                    <span className="mx-1 text-white duration-100">
                      {t("LogOut")}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="duration-100 text-red">
                      <MdLogout size="22px" className="duration-100" />
                    </div>
                    <span className="mx-1 duration-100">{t("LogOut")}</span>
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      {session.user.telesales ? (
        <p
          className={`text-center min-w-[100px] text-[12px] absolute top-[-12px] left-[50%] translate-x-[-50%] bg-[#1C278F] rounded-[4px] text-[white] px-[4px] py-[2px] ${telesalesHeaderUserShow}`}
        >
          {router.locale == "en" ? "Telesales Account" : "حساب مبيعات"}
        </p>
      ) : null}
    </div>
  );
}

export default UserAuth;