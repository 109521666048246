import axiosRequest from "@/utils/axios-utils";

// get products by skus bulk with full data of all products
const getProductsBySku = async (skuBulk, token) => {
  const transformSKus = skuBulk
    ? skuBulk.map((sku) => "sku=" + sku).join("&")
    : "";
  console.log({ transformSKus, token });
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "/search/get-products-by-skus?" + transformSKus,
    headers: {
      Authorization: "bearer " + token,
    },
  };

  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

// Get Amasty Related Product By Skus
export const getAmastyProductsBySkus = async (token, sku, collection_type) => {
  try {
    const req = await axiosRequest({
      url: `/amasty/related-products-by-sku?collection_type=${collection_type}&limit=10&sku=${sku}`,
      headers: {
        Authorization: "bearer " + token,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};

// get products by skus with specific response data using selected atteributes
const getProductsBySkuV2 = async (skuBulk, token, selectedAtteributes) => {
  const transformSKus = skuBulk
    ? skuBulk.map((sku) => "sku=" + sku).join("&")
    : "";

  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "/search/v2/get-products-by-skus?" + transformSKus,
    headers: {
      Authorization: "bearer " + token,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => "select=" + select)
      .join("&");
    config.url += `&${atterbiutes}`;
  }
  const req = await axiosRequest(config);
  return req;
};

// Get Product By Category
export const getProductsByCategory = async (token, categoryId) => {
  try {
    const req = await axiosRequest({
      url: `/search/get-products-by-category?id=${categoryId}`,
      headers: {
        Authorization: "bearer " + token,
      },
    });
    return req;
  } catch (error) {
    return error;
  }
};
// // Get Product By Category
// export const getProductsByCategoryFacets = async (token, categoryId, region_id) => {
//  const config = {
//   url: `/facets/categoryV2/${categoryId}?pageSize=20`,
//   headers: {
//     Authorization: "Bearer " + token,
//   },
// }
// if(region_id) config.url += `&select=${region_id}&regionId=${region_id}`

//   try {
//     const req = await axiosRequest(config);

//     return req.data?.products;
//   } catch (error) {
//     return error.message;
//   }
// };

// get all products
const getAllProducts = async (
  token,
  pageNo = 0,
  pageSize = 9999,
  selectedAtteributes
) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `search/get-products?pageSize=${pageSize}&pageNo=${pageNo}`,
    headers: {
      Authorization: "bearer " + token,
    },
  };
  if (selectedAtteributes) {
    const atterbiutes = selectedAtteributes
      .map((select) => "select=" + select)
      .join("&");

    config.url += `&${atterbiutes}`;
  }
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const getAllProductsReWrite = async (token) => {
  let config = {
    method: "GET",
    url: `/search/get-products?pageSize=100000&pageNo=0&select=rewrite_url`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const req = await axiosRequest(config);
    return { data: req.data, error: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
};

export {
  getProductsBySku,
  getProductsBySkuV2,
  getAllProducts,
  getAllProductsReWrite,
};
