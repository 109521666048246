import { handleFilterArrayToString } from "@/services/category/getProductsData";
import {
  IS_TYPESENSE_GLOBAL,
  IS_TYPESENSE_SEARCH,
} from "@/utils/typesense/config";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import useSWR from "swr";

const defaultFilters = [
  { text: "Recommended", code: "sortBy=position&sortDir=ASC", type: "sort" },
];

const useCategoryAndSearch = (
  fetchKey,
  fetchFunction,
  swrOptions,
  initialProducts = [],
  isFromSearch
) => {
  const { locale } = useRouter();

  const [updatedProducts, setUpdatedProducts] = useState(initialProducts || []);
  const showMoreRef = useRef(null);

  const [pageIndex, setPageIndex] = useState(checkDefaultPage(isFromSearch));
  const [isShowMore, setIsShowMore] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [activeFilters, setActiveFilters] = useState(defaultFilters);

  const cachingKey = `${fetchKey}/${pageIndex}/${handleFilterArrayToString(
    activeFilters
  )}/${locale}`;

  // reset page index when change the lang
  const previousLangValue = useRef(null);
  useEffect(() => {
    if (previousLangValue.current !== locale) {
      setPageIndex(checkDefaultPage(isFromSearch));
      previousLangValue.current = locale;
    }
  }, [locale, isFromSearch]);

  // get data with swr
  const { data: fetchData, isValidating: isLoadingData } = useSWR(
    cachingKey,
    fetchFunction,
    swrOptions
  );

  // pagination
  useEffect(() => {
    if (!isLoadingData && isShowMore && updatedProducts?.length > 0) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !isFetchingMore) {
            setIsFetchingMore(true);

            setPageIndex((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 }
      );

      if (showMoreRef.current) {
        observer.observe(showMoreRef.current);
      }

      return () => observer.disconnect();
    }
  }, [isLoadingData, isShowMore, updatedProducts, isFetchingMore]);

  // Add a small delay to ensure DOM updates before unlocking in pagination
  useEffect(() => {
    let timeoutId;

    if (!isLoadingData) {
      timeoutId = setTimeout(() => {
        setIsFetchingMore(false);
      }, 2000); // Adjust this delay as needed
    }

    // Cleanup function to clear the timeout if necessary
    return () => clearTimeout(timeoutId);
  }, [isLoadingData]);

  // handle selected filters
  const filterKeysHandler = useCallback((data) => {
    setPageIndex(checkDefaultPage(isFromSearch));
    setIsShowMore(true);
    switch (data.type) {
      case "price": // used in category page
        // data obj example => {range: [0, 1000], type: 'price'} `minPrice=0&maxPrice=1000`
        setActiveFilters((prev) => {
          const isPriceKeyExist = prev.find((item) => item.type === "price");

          if (isPriceKeyExist) {
            return prev.map((item) =>
              item.type === "price" ? { ...item, range: data.range } : item
            );
          }

          return [...prev, data];
        });
        break;

      case "sort": // used in category page
        // data obj example => {text: 'Recommended', code: 'sortBy=position&sortDir=ASC', type: 'sort'}
        setActiveFilters((prev) => {
          const isSortKeyExist = prev.find((item) => item.type === "sort");

          if (isSortKeyExist) {
            return prev.map((item) =>
              item.type === "sort"
                ? { ...item, code: data.code, text: data.text }
                : item
            );
          }

          return [...prev, data];
        });
        break;

      case "filter": // used in search - category page
        // data obj example => {key: 85, code: 'brand', type: 'filter'}
        setActiveFilters((prev) => {
          const isFilterKeyExist = prev.find(
            (item) => item?.code === data?.code && item?.key === data?.key
          );

          if (isFilterKeyExist) {
            const newFilters = prev.filter(
              (item) => !(item?.key === data?.key && item?.code === data?.code)
            );
            return newFilters;
          }

          return [...prev, data];
        });
        break;
    }
  }, [isFromSearch]);

  const handleResetFilter = () => {
    setActiveFilters(defaultFilters);
  };

  return {
    activeFilters,
    onFilter: filterKeysHandler,
    pageIndex,
    setPageIndex,
    fetchData,
    isLoadingData,
    setIsShowMore,
    setIsFetchingMore,
    showMoreRef,
    updatedProducts,
    setUpdatedProducts,
    cachingKey,
    setActiveFilters,
    onRestFilters: handleResetFilter,
  };
};

export default useCategoryAndSearch;

const checkDefaultPage = (isFromSearch) => {
  if (!!isFromSearch) {
    return IS_TYPESENSE_SEARCH ? 1 : 0;
  } else {
    return IS_TYPESENSE_GLOBAL ? 1 : 0;
  }
};
