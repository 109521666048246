import axiosRequest from "@/utils/axios-utils";
import axios from "axios";
import Cookies from "js-cookie";

export const getLocation = async (ipAddress) => {
  const response = await axios.get(
    `https://pro.ip-api.com/json/${ipAddress}?key=WNakn9Rq8qNjizH`
  );
  return response.data;
};

// get home page structure data
export const getHomePageStracture = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "/media/json/v3/home",
  };
  try {
    const req = await axiosRequest(config);
    return { status: req.status, error: null, data: req.data };
  } catch (error) {
    return { data: [], status: error?.response?.status, error: error.message };
  }
};

export const localeHandShake = async (locale, city) => {
  let transformLocale = "";
  // let transformLocale = locale.toLowerCase().replaceAll('-', '_')

  if (city?.includes("jeddah")) {
    if (locale?.includes("ar")) {
      transformLocale = "ar-SA-jed";
    } else {
      transformLocale = "en-SA-jed";
    }
  } else if (city?.includes("dammam")) {
    if (locale?.includes("ar")) {
      transformLocale = "ar-SA-dmm";
    } else {
      transformLocale = "en-SA-dmm";
    }
  } else if (city?.includes("riyadh")) {
    if (locale?.includes("ar")) {
      transformLocale = "ar-SA-ruh";
    } else {
      transformLocale = "en-SA-ruh";
    }
  } else {
    if (locale?.includes("ar")) {
      transformLocale = "ar-SA";
    } else {
      transformLocale = "en-SA";
    }
  }

  let data = JSON.stringify({
    store: transformLocale.toLowerCase().replaceAll("-", "_"),
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "/handshake",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const req = await axiosRequest(config);
    return req.data;
  } catch (error) {
    return error.message;
  }
};

export const getCategoryData = async (lang) => {
  const response = await axios({
    method: "get",
    url: "/api/category/categorynames?lang=" + lang,
  });

  return response.data;
};

// get token from API endpoint handshake
export const getToken = async (locale) => {
  try {
    const { data } = await axios(`/api/handshake?locale=${locale}`);
    data.token && Cookies.set("handshake", data.token, { expires: 29 });
    return data.token;
  } catch (error) {
    return null;
  }
};

export const postFetcher = async (url, { arg }) => {
  const updatedArgs = {};

  for (let updatedArg of Object.keys(arg)) {
    updatedArgs[updatedArg] = arg[updatedArg];
  }

  try {
    const res = await axios.post(url, updatedArgs);
    return res?.data || null;
  } catch (err) {
    return err;
  }
};

export const getFetcher = async (url) => {
  try {
    const req = await axios.get(url);
    return req.data;
  } catch (error) {
    return error;
  }
};

export const getRegions = async ({ token }) => {
  let config = {
    method: "get",
    url: "/regions/sa",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

export const SAVE_SEARCH_TERM = async (searchText) => {
  try {
    const { data } = await axios(`/api/search/term?q=${searchText}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getFooterData = async (token) => {
  let config = {
    method: "get",
    url: "/media/json/getFooterJson",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axiosRequest(config);
    return res;
  } catch (error) {
    return { data: [], status: error?.response?.status, error: error.message };
  }
};

export const activeCart = async (handshake, order_id) => {
  let config = {
    method: "post",
    url: "/cart/active",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data: JSON.stringify({ order_id }),
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
