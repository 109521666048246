import { labelPositions, modifyCss } from "@/utils/product";
import React from "react";
import { validateImgSrc } from "@/utils/global";
import NextImage from "../NextImage";

const ProductLabel = ({ label, fromTopTen, fromCategory = true }) => {
  const settingsKey = fromCategory ? "category_settings" : "frontend_settings";
  return (
    <>
      <div
        className={`absolute z-10 ${
          !fromTopTen ? `` : `end-[55%] max-[768px]:end-auto`
        } ${labelPositions(label?.[settingsKey]?.position)}`}
        style={{
          ...modifyCss(label?.[settingsKey]?.style),
          width: `${
            label?.[settingsKey]?.image_size
              ? `${label?.[settingsKey]?.image_size}%`
              : "auto"
          }`,
        }}
      >
        {label?.[settingsKey]?.image && (
          <NextImage
            src={validateImgSrc(
              `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/amasty/amlabel/${label?.[settingsKey]?.image}`
            )}
            className="object-contain w-full h-auto"
            height={105}
            width={209}
            alt="Img !"
          />
        )}
        {label?.[settingsKey]?.label_text != "" && (
          <div
            className={`${
              label?.[settingsKey]?.image &&
              "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
            }`}
          >
            <span>{label?.[settingsKey]?.label_text}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductLabel;
