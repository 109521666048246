import axiosRequest from "@/utils/axios-utils";
import { decodeJwt } from "@/utils/global";
import { CookieExpireAfter } from "@/utils/global/CookieExpire";
import { setCookie } from "cookies-next";

export const loginByEmail = async (token, email) => {
  const data = JSON.stringify({
    mobilenumber: "",
    otptype: "login",
    email: email,
    resendotp: "",
    oldmobile: "",
  });
  const config = {
    method: "post",
    url: "/customer/otpSend",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const fetchHandshake = async ({ token, locale }) => {
  if (token && locale == decodeJwt(token)?.store && decodeJwt(token)?.exp > Date.now()) {
    return token

  } else {
    const response = await axiosRequest({
      method: "POST",
      url: "/handshake",
      data: { store: locale },
    });
  
    setCookie("handshake", response.data.token, {
      expires: CookieExpireAfter(10),
    });
    
    setCookie("ahmed__", response.data.token, {
      expires: CookieExpireAfter(10),
    });

    return response.data.token
  }
};

export const verifyOTPByEmail = async (token, email, otpcode, locale) => {
  const handshake = await fetchHandshake({ token, locale })
  
  const data = JSON.stringify({
    mobilenumber: "",
    email,
    otpcode,
    otptype: "login",
    oldmobile: "",
  });
  const config = {
    method: "post",
    url: "/customer/otpVerfiy",
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
    data,
  };

  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};
