import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import { setCookie } from "cookies-next";

const TopHeader = () => {
  const { locale } = useRouter();
  const [active, setActive] = useState(
    typeof window !== "undefined" && window.sessionStorage.getItem("mobile-app")
  );
  useEffect(() => {
    if (!active) {
      setActive(true);
      window.sessionStorage.setItem("mobile-app", "true");
      setCookie('mobile-app', true)
    } else {
      setCookie('mobile-app', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={
        active === true
          ? "hidden max-[768px]:flex bg-blue py-1 px-2 text-white items-center justify-between"
          : "hidden"
      }
    >
      <div className="flex items-center gap-2 text-xs">
        <p className="font-bold text-white uppercase">
          {locale === "en"
            ? "Download the app for the best offers"
            : "نزل التطبيق لافضل العروض"}
        </p>
        <button
          aria-label="Mobile App Download button"
          className="border border-white px-3 py-1.5 rounded-md"
          onClick={() => window.open("https://almanea.page.link/app")}
        >
          {locale === "en" ? "Continue" : "إستمرار"}
        </button>
      </div>
      <CloseIcon
        onClick={() => {
          setActive(false);
          if (typeof window !== "undefined") {
            window.sessionStorage.setItem("mobile-app", "false");
          }
        }}
        className="w-3.5 h-3.5 text-white cursor-pointer"
      />
    </div>
  );
};

export default TopHeader;
