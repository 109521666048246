import { AppContext } from "@/contexts/appContexts";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "next-i18next";
import { useContext, useState } from "react";

import useHomeJson from "@/hooks/useHomeJson";
import Cookies from "js-cookie";

const SelectCityPopUp = ({
  isModalOpen,
  // setIsModalOpen,
  setRegionId,
  setAvailableDates,
  regionsData,
}) => {
  const [searchText, setSearchText] = useState("");
  const {
    setIsCityHavefastDelivery,
    setTargetProductDate,
    productInfo,
    setOpenSelectCityPopUp,
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: locale },
  } = useTranslation("common");
  const homeJson = useHomeJson(locale);
  console.log({ productInfo });
  return (
    <>
      {isModalOpen ? (
        <div className="fixed top-0 left-0 z-[3000] h-screen w-screen bg-lightblack flex items-center justify-center">
          <div className="bg-white max-h-[90vh] overflow-y-auto p-5 rounded-md w-[450px] max-[450px]:w-full">
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-bold">{t("ChooseCity")}</h1>
              <AiOutlineClose
                size={24}
                className="cursor-pointer"
                onClick={() => setOpenSelectCityPopUp(false)}
              />
            </div>
            <div className="my-2">
              <p className="font-bold text-zinc-600">
                {t("ChooseDeliveryCity")}
              </p>
              <p className="text-zinc-500">{t("DeliveryTimeVary")}</p>
            </div>
            <input
              className="outline-0 border rounded-md p-1.5 mb-2 w-full"
              placeholder={t("ChooseCity")}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <ul className="p-1.5 max-h-[300px] overflow-y-auto red-scrollbar">
              {regionsData
                ? regionsData
                    .slice(1)
                    .map(({ label, value }) => ({ label, value }))
                    .filter((city) =>
                      city.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map((city) => (
                      <li
                        onClick={async () => {
                          Cookies.set("region_id", city.value);
                          setRegionId(city.value);
                          await import("@/utils/global")
                            .then(({ fastDeliveryAndAvailableDatesLogic }) => {
                              fastDeliveryAndAvailableDatesLogic({
                                setAvailableDates,
                                setTargetProductDate,
                                homeJson,
                                setIsCityHavefastDelivery,
                                id: city.value,
                                locale,
                              });
                              if (productInfo?.product?.sku) {
                                productInfo?.addToCartRef?.current?.click();
                                setOpenSelectCityPopUp(false);
                              }
                              if (productInfo?.searchInputRef) {
                                const searchInput =
                                  document.getElementById("search-box");
                                searchInput && searchInput.focus();
                                setSearchText("");
                                setOpenSelectCityPopUp(false);
                              }
                            })
                            .catch((err) =>
                              console.log("Error formatDate", err)
                            );
                          // .finally(() => {
                          //   if (productInfo?.product?.sku) {
                          //     console.log("Hereeee !!!!!");
                          //     productInfo?.addToCartRef?.current?.click();
                          //     setOpenSelectCityPopUp(false);
                          //   }
                          //   if (productInfo?.searchRef) {
                          //     productInfo?.searchRef?.current?.focus();
                          //     setTimeout(() => {
                          //       setSearchText("");
                          //       setOpenSelectCityPopUp(false);
                          //     }, 0);
                          //   }
                          // });
                        }}
                        key={city.value}
                        className="pb-2 cursor-pointer text-zinc-600 hover:text-red"
                      >
                        {city.label}
                      </li>
                    ))
                : null}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SelectCityPopUp;
