import CompareContext from "@/contexts/compare/compare-context";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useClient from "@/hooks/global/useClient";
import { MdOutlineCompare } from "react-icons/md";

const AddToCompare = ({ product, className, children, fromPDP }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const compareCtx = useContext(CompareContext);
  const { isClient } = useClient();

  const [inCompare, setInCompare] = useState(false);
  useEffect(() => {
    const existingProduct = compareCtx.compareProducts.find(
      (compareProduct) => compareProduct.sku === product.sku
    );

    setInCompare(!!existingProduct);
  }, [product?.sku, compareCtx?.compareProducts]);

  return (
    <button
      title={isClient && language === "ar" ? " قارن المنتج" : "Compare product"}
      className={`${
        children ? "w-40 px-1.5 py-3 max-md:py-2.5 max-md:min-h-12" : "py-2.5"
      } border rounded-full flex justify-center items-center size-9  text-lg max-md:text-base gap-2 ${
        inCompare
          ? !fromPDP
            ? "bg-red text-white"
            : "text-red/80"
          : !fromPDP
          ? "text-red/80 "
          : ""
      } ${className || ""} `}
      onClick={() => {
        if (compareCtx.compareProducts.length >= 4 && !inCompare) {
          return toast.error(
            t(
              "Maximum 4 products can be compared, please remove one or more from compare to add another."
            )
          );
        }
        compareCtx.addProductToCompare(product);
      }}
    >
      <MdOutlineCompare />
      {children || ""}
    </button>
  );
};

export default AddToCompare;
