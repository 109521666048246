function getCurrentDateTime() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
}

const selectedAtteributes = [
  "sku",
  "name",
  "option_text_brand",
  "prices_with_tax",
  "image",
  "_media_",
  "stock",
  "label",
  "product_fees",
  "rewrite_url",
  "attributes",
  "eligible_for_tabby",
  "eligible_for_tamara",
  "product_fees",
  "option_text_eligible_for_tabby",
  "option_text_eligible_for_tamara",
  "back_orders",
  "show_outofstock",
  "type_id",
  "1105",
  "1106",
  "1104",
  "1111",
  "1103",
  "1101",
  "1115",
  "1102",
  "1108",
  "1117",
  "1116",
  "1113",
  "1112",
  "1110",
  "1114",
  "1107",
  "1109",
];

const filterOutOfStockProducts = (arr = []) =>
  arr?.length ? arr.filter((product) => product?.stock.is_in_stock) : [];
export { getCurrentDateTime, selectedAtteributes, filterOutOfStockProducts };

export const product_meta_desc = (title, locale) => {
  return locale === "ar"
    ? `تسوق ${title} من متجر المنيع في السعودية. أحصل على أفضل الاسعار وتوصيل مجاني للطلبات الاعلى من 299 ريال.`
    : `Shop ${title} from Almanea store in Saudi Arabia. Get the best prices and free delivery on orders above 299 SAR.`;
};

export const labelPositions = (pos) => {
  const positions = {
    0: "left-0 top-0",
    1: "left-1/2 -translate-x-1/2 top-0",
    2: "right-0 top-0",
    3: "left-0 top-1/2 -translate-y-1/2",
    4: "left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2",
    5: "right-0 top-1/2 -translate-y-1/2",
    6: "left-0 bottom-0",
    7: "left-1/2 -translate-x-1/2 bottom-0",
    8: "right-0 bottom-0",
  };

  return positions?.[pos];
};

export const handleSetAndFetchProducts = async (
  cachedProducts,
  cachedRelatedUpSaleSkus,
  setSameBrandProducts,
  setRelatedProducts
) => {
  const allProducts = cachedProducts?.map(({ _source }) => _source);

  // handle up sale products
  const filterUpSaleProducts = allProducts?.filter((item) =>
    cachedRelatedUpSaleSkus?.upSale?.includes(item?.sku)
  );
  const availableUpSaleProducrs =
    filterOutOfStockProducts(filterUpSaleProducts);
  setSameBrandProducts(availableUpSaleProducrs);

  // handle related products
  const filterRelatedProducts = allProducts?.filter((item) =>
    cachedRelatedUpSaleSkus?.related?.includes(item?.sku)
  );
  const availableRelatedProducrs = filterOutOfStockProducts(
    filterRelatedProducts
  );
  setRelatedProducts(availableRelatedProducrs);
};

export const modifyCss = (style) => {
  const styleObj = style.split(";").reduce((acc, curr) => {
    const [key, value] = curr.split(":");

    const handleKey = key
      ?.trim()
      .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

    return { ...acc, [handleKey]: value?.trim() };
  }, {});

  return styleObj;
};

function formatPrice(price) {
  if (isNaN(price)) return null;
  const fixedPrice = Number(price)?.toFixed(2);
  const [IntPrice, floatPrice] = String(fixedPrice)?.split(".");
  return Number(floatPrice === "00" ? IntPrice : fixedPrice);
}
export const validatePrice = (prices) => {
  const now = new Date();
  const startDate = prices?.discounted_price_from
    ? new Date(prices?.discounted_price_from)
    : null;
  const endDate = prices?.discounted_price_to
    ? new Date(prices?.discounted_price_to)
    : null;

  let originalPrice = prices?.original_price;
  let specialPrice = null;
  let discount = null;

  if (endDate && now >= startDate && now <= endDate) {
    specialPrice = prices?.price;
    discount = ((originalPrice - specialPrice) / originalPrice) * 100;
  }

  return {
    originalPrice: formatPrice(+originalPrice),
    specialPrice:
      originalPrice === specialPrice ? null : formatPrice(+specialPrice),
    discount: specialPrice === originalPrice ? null : formatPrice(discount),
  };
};

export function truncateToOneDecimalPlaces(number) {
  if(isNaN(number)) return null;
  const price = Number(number)?.toFixed(2);
  return Number(price.replace(/\.00$/, ""))?.toLocaleString();
}

export function validateProductPrice(prices) {
  const discounted_percentage =  prices?.discounted_percentage;
  const discounted_price =  prices?.discounted_price;
  const original_price =  prices?.original_price;
  const price =  prices?.price;

  // Calculate discount amount if discounted_price exists
  const discount_amount = discounted_price
    ? original_price - discounted_price
    : 0;

  // Return the values in the required format
  return {
    original_price: Math.round(original_price)?.toLocaleString(),
    discounted_price: Math.round(discounted_price || price)?.toLocaleString(),
    discounted_percentage: Math.round(discounted_percentage),
    discount_amount: Math.round(discount_amount)?.toLocaleString(),
  };
}