import { memo, useContext, useState, useEffect, useRef } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { AppContext } from "@/contexts/appContexts";
import { useSession } from "next-auth/react";
import { toast } from "react-hot-toast";
import {
  addToWishList,
  removeFromWishList,
} from "@/utils/wishlist/addAndRemoveWishlist";
import { useRouter } from "next/router";
import Link from "next/link";
import AddtoCart from "./AddtoCart";
import {
  event_add_to_wishlist,
  event_product_viewed,
  event_remove_from_wishlist,
  event_wishlist_updated,
} from "@/utils/gtm";
import CloseIcon from "../icons/CloseIcon";
import AddToCompare from "../compare/AddToCompare";
import PreOrderLabel from "./PreOrderLabel";
import ProductLabel from "./ProductLabel";
import NextImage from "../NextImage";
import { validateImgSrc } from "@/utils/global";
import {
  DeliveryDate,
  Keywords,
  ProductPrice,
  ProductMsgs,
} from "./product-card";
import { getCookie } from "cookies-next";

function ProductCard({
  product,
  highlight = {},
  handleClick = null,
  customHeight = null,
  t,
  isShowCompareBtn = true,
  onRemoveCompare = null,
  fromTopTen = false,
  isPriority = false,
}) {
  const regionId = getCookie("region_id");
  const { locale, push } = useRouter();
  const [isFav, setIsFav] = useState(false);

  const { wishList, setwishList } = useContext(AppContext);
  // Add to wishlist
  useEffect(() => {
    if (product && wishList) {
      const foundItem = wishList.find((item) => item.sku == product.sku);
      if (foundItem) {
        setIsFav(true);
      } else {
        setIsFav(false);
      }
    }
  }, [wishList, product]);

  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;
      swiper.autoplay.stop();

      // Add event listeners for mouseover and mouseleave
      swiperRef.current.addEventListener('mouseover', () => {
        swiper.autoplay.start();
      });

      swiperRef.current.addEventListener('mouseleave', () => {
        swiper.autoplay.stop();
      });
    }
  }, []);

  const [hoverOfProduct, sethoverOfProduct] = useState();

  let imageInArrayChecker = product?._media_?.image?.length
    ? product?._media_?.image?.at(0)?.image
    : product?.image
    ? `${process.env.NEXT_PUBLIC_IMAGE_BASEURL}/media/catalog/product${product?.image[0]}`
    : `https://images-r2.dev-almanea.com/default.webp`;

  // console.log("imageInArrayChecker", imageInArrayChecker);

  const { data: session } = useSession();
  const customer_id_session = session?.user?.id;

  // GTM

  const viewProduct = () => {
    const productName = Array.isArray(product?.name)
      ? product?.name?.at(0)
      : product?.name;
    const brand = Array.isArray(product?.option_text_brand)
      ? product?.option_text_brand?.at(0)
      : product?.option_text_brand;
    event_product_viewed(
      product?.sku,
      productName,
      product?.prices_with_tax?.price,
      imageInArrayChecker,
      brand,
      product?._category_?.main_category?.category_id,
      product?._category_?.main_category?.name,
      product?._category_?.sub_categories?.at(0)?.category_id,
      product?._category_?.sub_categories?.at(0)?.name,
      product?.type_id
    );
    push(`/product/${product?.rewrite_url}`);
  };

  const handleWishlist = () => {
    {
      const {
        sku,
        name,
        prices_with_tax: { price },
        option_text_brand,
        _category_,
      } = product;
      const productName = Array.isArray(name) ? name?.at(0) : name;
      const brand = Array.isArray(option_text_brand)
        ? option_text_brand?.at(0)
        : option_text_brand;
      const mainCategoryName = _category_?.main_category?.name;
      const mainCategoryId = _category_?.main_category?.category_id;
      const subCategoryId = _category_?.sub_categories?.at(0)?.category_id;
      const subCategoryName = _category_?.sub_categories?.at(0)?.name;
      const qty = regionId ? product[regionId]?.value : product["1101"]?.value;
      if (isFav) {
        removeFromWishList(product, wishList, setwishList, customer_id_session);
        event_remove_from_wishlist(
          sku,
          productName,
          qty,
          price,
          brand,
          imageInArrayChecker,
          product?.type_id,
          mainCategoryId,
          mainCategoryName,
          subCategoryId,
          subCategoryName
        );
        event_wishlist_updated(wishList.filter((elem) => elem.sku != sku));
        toast.success(
          locale === "en"
            ? "Product Deleted From Wishlists"
            : "تمت الإزالة من المفضلة"
        );
        setIsFav(!isFav);
      } else {
        addToWishList(product, wishList, setwishList, customer_id_session);
        event_add_to_wishlist(
          sku,
          productName,
          price,
          brand,
          product?.type_id,
          imageInArrayChecker,
          mainCategoryId,
          mainCategoryName,
          subCategoryId,
          subCategoryName
        );

        setTimeout(() => {
          event_wishlist_updated([...(wishList || []), product]);
        }, 250);
        toast.success(
          locale === "en"
            ? "Product Added To Wishlists"
            : "تمت الإضافة إلى المفضلة"
        );
        setIsFav(!isFav);
      }
    }
  };

  return (
    <div className="relative h-full overflow-hidden bg-white rounded-xl">
      <div
        className={`text-zinc-700 p-2  relative flex ${
          !fromTopTen
            ? "flex-col min-h-[27.6rem] border rounded-xl "
            : customHeight
            ? "flex-col min-h-[30rem] border rounded-xl "
            : "flex-row gap-2 max-[768px]:flex-col max-[768px]:min-h-[27.6rem] max-[768px]:border"
        } justify-between ${hoverOfProduct} overflow-hidden`}
        onMouseOver={() => !fromTopTen && sethoverOfProduct("productCardHover")}
        onMouseOut={() => sethoverOfProduct("")}
      >
        <div
          className={`relative ${
            fromTopTen
              ? "w-1/2 border max-[768px]:w-full max-[768px]:border-0"
              : "h-48"
          }`}
        >
          {product?._media_?.image && (
            <div
              className={`relative flex items-center justify-center w-full ${
                !fromTopTen
                  ? "h-full pt-10 mb-3 "
                  : " min-h-[182px] max-h-[182px] max-[570px]:h-full"
              } overflow-hidden cursor-pointer`}
              onClick={() => {
                handleClick && handleClick();
                viewProduct();
                push(`/product/${product?.rewrite_url}`);
              }}
            >
              <NextImage
                priority={isPriority}
                src={validateImgSrc(imageInArrayChecker)}
                className={`w-11/12 ${
                  !fromTopTen ? "h-full object-contain" : "h-auto"
                } transition-all duration-500 hover:scale-105`}
                width={300}
                height={250}
                alt="product image"
              />

              {product?.label
                ?.filter(
                  (el) =>
                    el &&
                    el?.status === "1" &&
                    new Date(el?.active_from) <
                      new Date() <
                      new Date(el?.active_to)
                )
                ?.map((label, i) => (
                  <ProductLabel label={label} fromTopTen={fromTopTen} key={i} />
                ))}
            </div>
          )}

          <div className="absolute top-0 right-0 flex flex-col text-red">
            <button
              onClick={handleWishlist}
              className="p-1 bg-white rounded-full"
              aria-label="Fav Btn"
            >
              {!isFav ? (
                <AiOutlineHeart className="text-red" size={20} />
              ) : (
                <AiFillHeart className="text-red" size={20} />
              )}
            </button>
          </div>
        </div>
        <div
          className={`${
            fromTopTen &&
            "flex-1 py-2 px-1 max-[768px]:p-0 max-[768px]:flex-none"
          }`}
        >
          <div className="flex items-center justify-between">
            <p className="text-sm text-zinc-500">
              {product?.option_text_brand || ""}
            </p>
            {/* <div
              className="flex items-center"
              dir={locale === "en" ? "ltr" : "rtl"}
            >
              {[1, 2, 3, 4, 5].map((idx) => (
                <AiFillStar size={15} className="text-yellow-500" key={idx} />
              ))}
            </div> */}
          </div>
          <Link
            href={`/product/${product?.rewrite_url}`}
            onClick={() => {
              handleClick && handleClick();
              viewProduct();
            }}
            title={product?.name || ""}
            className="cursor-pointer font-semibold text-[15px] mt-2 line-clamp-2 text-start min-h-10"
          >
            {highlight && "name.standard" in highlight ? (
              <span
                className="mx-2 font-semibold text-zinc-500"
                dangerouslySetInnerHTML={{
                  __html: Array.isArray(highlight["name.standard"])
                    ? highlight["name.standard"]?.at(0)
                    : highlight["name.standard"] || "",
                }}
              />
            ) : (
              product?.name || ""
            )}
          </Link>
          {false && <Keywords
            keywords={product?.keyword_tags || product?.suggest?.join()}
          />}
          <DeliveryDate
            {...{
              t,
              locale,
              fast_delivery: product?.fast_delivery,
              back_orders: product?.back_orders,
              delivery_within_24_hours: product?.delivery_within_24_hours,
              option_text_delivery_within_24_hours:
                product?.option_text_delivery_within_24_hours,
            }}
          />
          <PreOrderLabel
            isPreOrder={product?.back_orders === 101}
            label={product?.preorder_note}
            className="mt-1 text-xs"
          />
          <ProductPrice
            price={product?.prices_with_tax}
            t={t}
            locale={locale}
          />
          <ProductMsgs data={product?.product_messages} locale={locale} />
          <div className="flex items-center justify-between gap-2">
            <AddtoCart {...{ product, t }} />
            {isShowCompareBtn && <AddToCompare product={product} />}
          </div>
        </div>
      </div>
      {onRemoveCompare && (
        <div
          onClick={onRemoveCompare}
          className="absolute cursor-pointer -top-5 -right-4 z-50 bg-white border rounded-md p-2 border-[#CDCDCD]"
        >
          <CloseIcon stroke="black" strokeWidth={2} width={14} height={14} />
        </div>
      )}
    </div>
  );
}

export default memo(ProductCard);