import axiosRequest from "@/utils/axios-utils";
import Cookies from "js-cookie";

/*
###############################
 01:---> CREATE CART,
 02:---> GET CART,
 03:---> ADD ITEM TO CART,
 04:---> UPDATE ITEM TO CART,
 05:---> DELETE CART ITEM,
 06:---> APPLY COUPON,
 07:---> DELETE COUPON,
 08:---> PLACE ORDER,
 09:---> MERGE MASKED CART
###############################
*/

// 01- ============= create cart
const createCart = async (handshake, mage) => {
  let config = {
    method: "get",
    url: "/cart/create",
    headers: {
      Authorization: "Bearer " + handshake,
    },
  };

  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};

// 02- =============  get cart
const getCart = async (handshake, mage, quote) => {
  let config = {
    method: "get",
    url: "/cart",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }

  try {
    const req = await axiosRequest(config);
    Cookies.set("quote", req.data.data.quote);
    // if (quote !== req.data.data.quote) {

    // }
    return req;
  } catch (error) {
    return error;
  }
};

// 03- ============= Add item to cart
const addItemToCart = async (
  handshake,
  mage,
  quote,
  sku,
  qty,
  region_id,
  product_option
) => {
  let data = {
    sku,
    qty,
    extension_attributes: {
      region: region_id,
    },
  };
  if (product_option) {
    data.product_option = product_option;
  }

  let config = {
    method: "post",
    url: "/cart/add",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: JSON.stringify(data),
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }
  try {
    const req = await axiosRequest(config);

    try {
      return req;
    } catch (error) {
      return req;
    }

    return req;
  } catch (error) {
    return error;
  }
};
// 04- ============= Add multi items to cart
const addMultiItemsToCart = async (handshake, mage, quote, skus, region_id) => {
  const cartItems = skus.map((sku) => ({
    sku,
    qty: 1,
    extension_attributes: { region: region_id },
  }));
  let config = {
    method: "post",
    url: "/cart/multiplev2",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: JSON.stringify({ cartItems }),
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }
  try {
    const req = await axiosRequest(config);

    try {
      return req;
    } catch (error) {
      return req;
    }
  } catch (error) {
    return error;
  }
};

// 04- ============= Update cart item
const updateCartItem = async (handshake, mage, quote, itemId, qty) => {
  let data = JSON.stringify({
    qty,
    itemId,
  });
  let config = {
    method: "put",
    url: "/cart/updateItem",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: data,
  };

  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};

// 05- ============= Delete cart item
const deleteCartItem = async (handshake, mage, quote, itemId) => {
  let data = JSON.stringify({ itemId });
  let config = {
    method: "delete",
    url: "/cart/deleteItem",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: data,
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};
// 06- ============= Apply coupon code
const applyCoupon = async (handshake, mage, quote, couponCode) => {
  let data = JSON.stringify({ couponCode });

  let config = {
    method: "put",
    url: "/cart/applyCoupon",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: data,
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }
  console.log("========================================>>apply coupon", config);
  try {
    const req = await axiosRequest(config);

    // const req = await axios
    return req;
  } catch (error) {
    return error;
  }
};
// 07- ============= Delete coupon
const deleteCoupon = async (handshake, mage, quote, couponCode) => {
  let data = JSON.stringify({ couponCode });
  let config = {
    method: "delete",
    url: "/cart/deleteCoupon",
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: data,
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};

// 08- ============= Place order
const placeOrder = async (
  handshake,
  mage,
  quote,
  method,
  email,
  additional_data
) => {
  let data = {
    email,
    paymentMethod: {
      method,
    },
  };

  if (additional_data) {
    data.paymentMethod.additional_data = { ...additional_data };
  }

  let config = {
    method: "post",
    url: "/cart/placeOrder",
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: JSON.stringify(data),
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }
  console.log(
    "placeorder ===================================>>>",
    data,
    config
  );

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};

// 08- ============= create order
const createOrder = async (
  handshake,
  mage,
  quote,
  method,
  email,
  additional_data
) => {
  let data = {
    // email,
    paymentMethod: {
      method,
    },
  };

  if (additional_data) {
    data.paymentMethod.additional_data = { ...additional_data };
  }

  let config = {
    method: "put",
    url: "/cart/createOrder",
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + handshake,
      quote: "Bearer " + quote,
    },
    data: JSON.stringify(data),
  };
  if (mage) {
    config.headers.mage = "Bearer " + mage;
  }
  console.log(
    "placeorder ===================================>>>",
    data,
    config
  );

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};

// 09- ============= merge guest user cart to logged in user cart

const mergeMaskedCart = async (handshake, mage, quote, storeId, customerId) => {
  let data = JSON.stringify({ customerId, storeId });

  let config = {
    method: "put",
    url: "/mstore/guest-carts/" + quote,
    headers: {
      Authorization: "Bearer " + handshake,
      mage: "Bearer " + mage,
    },
    data: data,
  };

  try {
    const req = await axiosRequest(config);

    return req;
  } catch (error) {
    return error;
  }
};

const addProductFees = async (handshake, quote, data) => {
  let config = {
    method: "post",
    url: `/fees/setproductfees/${quote}`,
    headers: {
      Authorization: "Bearer " + handshake,
      admin: "Bearer " + process.env.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
    data: JSON.stringify({ feeData: data }),
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const delProductFees = async (handshake, quote, data) => {
  let config = {
    method: "post",
    url: `/fees/removeproductfees/${quote}`,
    headers: {
      Authorization: "Bearer " + handshake,
      admin: "Bearer " + process.env.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
    data: JSON.stringify({ feeData: data }),
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const validateErpVoucher = async (handshake, phoneno, voucher) => {
  let config = {
    method: "post",
    url: "/erp/validate-erp-voucher",
    headers: {
      Authorization: "Bearer " + handshake,
      admin: "Bearer " + process.env.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
    data: JSON.stringify({ phoneno, voucher }),
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const applyErpVoucher = async (handshake, quoteId, phoneno, voucher) => {
  const isMask = quoteId?.match(/^\d+$/) ? false : true;
  let config = {
    method: "post",
    url: "/erp/apply-erp-voucher",
    headers: {
      Authorization: "Bearer " + handshake,
      admin: "Bearer " + process.env.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
    data: JSON.stringify({ phoneno, voucher, quoteId, isMask }),
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const clearCart = async (handshake, quote) => {
  const isMask = quote?.match(/^\d+$/) ? "0" : "1";

  let config = {
    method: "get",
    url: `/cart/clearCart/${quote}/${isMask}`,
    headers: {
      Authorization: "Bearer " + handshake,
      admin: "Bearer " + process.env.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const availableToCheckoutV2 = async (handshake, regionId, cartItems) => {
  let config = {
    method: "post",
    url:
      process.env.NEXT_PUBLIC_API_BASEURL +
      "/mstore/available-to-checkout-region-v2",
    headers: {
      Authorization: "Bearer " + handshake,
      admin: "Bearer " + process.env.NEXT_PUBLIC_MAGENTO_ADMIN_TOKEN,
    },
    data: JSON.stringify({
      region: regionId,
      skus: cartItems,
    }),
  };
  console.log(
    "===================================== availableToCheckoutV2",
    config
  );
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const applyRedeemGift = async (handshake, quoteId, voucher, pin, amount) => {
  const isMask = quoteId?.match(/^\d+$/) ? "0" : "1";
  const data = JSON.stringify({
    voucher,
    pin,
    quoteId,
    amount,
    isMask,
  });
  let config = {
    method: "post",
    url: "/gota-gift/redeem-voucher",
    headers: {
      Authorization: "Bearer " + handshake,
    },
    data,
  };
  console.log(
    "===================================== apply redeem gift",
    config
  );
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const removeRedeemGift = async (handshake, quoteId) => {
  const isMask = quoteId?.match(/^\d+$/) ? "0" : "1";
  const data = JSON.stringify({
    quoteId,
    isMask,
  });
  let config = {
    method: "post",
    url: "/gota-gift/reverse-voucher",
    headers: {
      Authorization: "Bearer " + handshake,
    },
    data,
  };
  console.log(
    "===================================== remove redeem gift",
    config
  );
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

const cartVoucherVisibility = async (handshake) => {
  let config = {
    method: "get",
    url: `/voucher/visibility`,
    maxBodyLength: Infinity,
    headers: {
      Authorization: "Bearer " + handshake,
    },
  };

  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};

// export all functions
export {
  createCart,
  getCart,
  addItemToCart,
  addMultiItemsToCart,
  updateCartItem,
  deleteCartItem,
  applyCoupon,
  deleteCoupon,
  placeOrder,
  createOrder,
  mergeMaskedCart,
  addProductFees,
  delProductFees,
  validateErpVoucher,
  applyErpVoucher,
  clearCart,
  availableToCheckoutV2,
  applyRedeemGift,
  removeRedeemGift,
  cartVoucherVisibility,
};
