import { useState } from "react";
import { useTranslation } from "next-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";

function FilterBy({ filterBy, activeFilters, onFilter }) {
  const { t } = useTranslation("common");
  const [active, setActive] = useState(filterBy?.map(() => false));

  return (
    <ul>
      {filterBy?.map((itemParent, keyParent) => (
        <li key={keyParent} className="p-2 border-t first:border-t-0">
          <div
            onClick={() =>
              setActive([
                ...active.slice(0, keyParent),
                !active[keyParent],
                ...active.slice(keyParent + 1, active.length),
              ])
            }
            className="flex items-center justify-between text-zinc-500 cursor-pointer"
          >
            <span className="font-semibold">{t(itemParent?.title)}</span>
            <MdKeyboardArrowDown
              size="19px"
              className={`${active[keyParent] ? "rotate-180" : "rotate-0"}`}
            />
          </div>
          {active[keyParent] && (
            <div className="p-2">
              {itemParent?.list?.map((itemChild, keyChild) => (
                // bind label with input using data comming from api here
                <div
                  key={keyChild}
                  className="flex items-center justify-between mb-1 last:mb-0 cursor-pointer"
                  onClick={() =>
                    onFilter({
                      key: itemChild?.key,
                      code: itemChild?.code,
                      type: "filter",
                    })
                  }
                >
                  <label htmlFor={itemChild?.label}>{itemChild?.label}</label>
                  <input
                    checked={activeFilters?.find(
                      (fv) =>
                        itemChild?.key === fv.key && itemChild?.code === fv.code
                    )}
                    name={itemChild?.label}
                    id={itemChild?.label}
                    type="checkbox"
                  />
                </div>
              ))}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}

export default FilterBy;
