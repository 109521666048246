// import SelectCity from "./SelectCity";
// import Style from "@/styles/layout.module.css";
import { AppContext } from "@/contexts/appContexts";
import { useRouter } from "next/router";
import {
  // Fragment,
  useContext,
  useState,
} from "react";
// import { RiArrowLeftSLine } from "react-icons/ri";
import Link from "next/link";
// import { event_sub_category_viewed } from "@/utils/gtm";
import { megaMenuChecker } from "@/utils/global";
import useClient from "@/hooks/global/useClient";
// import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowIcon from "../icons/ArrowIcon";
import useScreenSize from "@/hooks/global/useDisplay";

// const HeaderItem = ({ item, onSearch }) => {
//   // const { locale: lang } = useRouter();
//   return (
//     <div className="px-1 py-4 text-white cursor-pointer">
//       <Link
//         href={`/${item?.url_key}`}
//         onClick={() => onSearch("")}
//         className="text-[14px] bg-white py-[10px] px-[20px] rounded-xl font-bold text-blue py-4 px-1"
//       >
//         {item?.name}
//       </Link>
//       {/* {(item?.view_type === "vertical" || !item?.view_type) &&
//         item?.children_data?.length > 0 &&
//         // this for disable dropdown any way
//         false && (
//           <div
//             className={`
//           hidden group-hover:block absolute
//           border-t-4 border-red shadow
//           bg-white text-black
//           ${Style.singleNavContent}
//         `}
//           >
//             <ul
//               className="bg-white p-2 w-[200px]"
//               style={{ boxSizing: "border-box" }}
//             >
//               {item?.children_data.map(
//                 (subCategory, i) =>
//                   subCategory?.include_in_menu === true &&
//                   subCategory?.show_in_mega_menu === true &&
//                   subCategory?.product_count > 0 && (
//                     <Fragment key={i}>
//                       <li
//                         className={`
//                     mb-1.5 last:mb-0
//                     pb-1.5 last:pb-0 relative
//                     border-b last:border-b-0
//                   `}
//                       >
//                         <span className="block w-full group">
//                           <span
//                             className={`flex items-center justify-between ${
//                               subCategory?.children_data.length > 0 &&
//                               Style.hasSubSub
//                             }`}
//                           >
//                             <Link
//                               href={`/${item?.url_key}/${subCategory?.url_key}`}
//                               onClick={() => {
//                                 onSearch("");
//                                 event_sub_category_viewed(
//                                   subCategory?.name,
//                                   subCategory?.id,
//                                   subCategory?.product_count,
//                                   item?.name,
//                                   item?.id,
//                                   null
//                                 );
//                               }}
//                               className="flex-1 cursor-pointer"
//                             >
//                               {subCategory?.name}
//                             </Link>
//                             {subCategory?.children_data.length > 0 && (
//                               <RiArrowLeftSLine
//                                 size="20px"
//                                 className={
//                                   lang === "en" ? "rotate-180" : "rotate-0"
//                                 }
//                               />
//                             )}
//                           </span>
//                           {subCategory?.children_data.length > 0 && (
//                             <ul
//                               className={`hover:z-50 absolute -top-3 ${
//                                 lang === "en" ? "left-full" : "right-full"
//                               } bg-white p-2 w-[200px] border-t-4 border-red shadow ${
//                                 Style.SubSub
//                               }`}
//                             >
//                               {subCategory?.children_data.map((subSub, idx) => (
//                                 <li
//                                   key={idx}
//                                   className={`
//                             mb-1.5 last:mb-0
//                             pb-1.5 last:pb-0 relative
//                             border-b last:border-b-0
//                           `}
//                                 >
//                                   <span className="flex items-center justify-between">
//                                     <Link
//                                       href={`/${item?.url_key}/${subCategory?.url_key}/${subSub?.url_key}`}
//                                       onClick={() => {
//                                         onSearch("");
//                                         event_sub_category_viewed(
//                                           subSub?.name,
//                                           subSub?.id,
//                                           subSub?.product_count,
//                                           subCategory?.name,
//                                           subCategory?.id,
//                                           null
//                                         );
//                                       }}
//                                       className="cursor-pointer"
//                                     >
//                                       {subSub.name}
//                                     </Link>
//                                   </span>
//                                 </li>
//                               ))}
//                             </ul>
//                           )}
//                         </span>
//                       </li>
//                     </Fragment>
//                   )
//               )}
//             </ul>
//           </div>
//         )}
//       {(item?.view_type === "horizontal" || item?.view_type === "mix") &&
//         item?.children_data?.length > 0 && (
//           <div
//             className={`
//             hidden group-hover:grid absolute
//             border-t-4 border-red shadow
//             bg-white text-black overflow-y-auto
//             grid-cols-4 top-full left-0 w-full min-h-[200px]
//             ${lang === "en" ? Style.navContent : Style.navContentAr}
//           `}
//           >
//             {item?.children_data.map((subCategory, idx) => (
//               <div key={idx} className="py-2 bg-white px-7">
//                 <Link
//                   href={`/${subCategory?.url_key}`}
//                   onClick={() => {
//                     onSearch("");
//                     event_sub_category_viewed(
//                       subCategory?.name,
//                       subCategory?.id,
//                       subCategory?.product_count,
//                       item?.name,
//                       item?.id,
//                       null
//                     );
//                   }}
//                   className="font-semibold"
//                 >
//                   {subCategory?.name}
//                 </Link>
//               </div>
//             ))}
//           </div>
//         )} */}
//     </div>
//   );
// };

function Nav({
  // t,
  onSearch,
  // citiesToSlect,
  // setcitiesToSlect,
  // setAvailableDates,
  // isLoadingRegions,
  // homeJson,
}) {
  const { categoryData /* setTargetProductDate */ } = useContext(AppContext);
  const { push, locale } = useRouter();

  const { isClient } = useClient();
  const { screenWidth } = useScreenSize();

  const [swiper, set_swiper] = useState(false);
  const filtedCategoryData = categoryData?.children_data?.filter((item) =>
    megaMenuChecker(item)
  );

  const handleShowArrows = (width) => {
    if (width > 991 && width < 1400) {
      return filtedCategoryData?.length > 6;
    }

    if (width > 1400 && width < 1501) {
      return filtedCategoryData?.length > 8;
    }

    if (width > 1500 && width < 1801) {
      return filtedCategoryData?.length > 10;
    }

    if (width > 1800 && width < 1901) {
      return filtedCategoryData?.length > 11;
    }

    if (width > 1900 && width < 2101) {
      return filtedCategoryData?.length > 12;
    }

    if (width > 2100 && width < 2201) {
      return filtedCategoryData?.length > 13;
    }

    if (width > 2200 && width < 2301) {
      return filtedCategoryData?.length > 14;
    }

    if (width > 2300 && width < 2401) {
      return filtedCategoryData?.length > 15;
    }

    if (width > 2400 && width < 2501) {
      return filtedCategoryData?.length > 16;
    }
  };

  return (
    <nav
      className={`pt-1 pb-3 ${
        process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme"
          ? "bg-red bg-[url('https://imgs.dev-almanea.com/media/almanea-pattern-bg-min.webp')] bg-fixed bg-center"
          : "bg-white"
      } max-[991px]:hidden`}
    >
      <div className="flex items-center justify-between w-11/12 mx-auto">
        <div
          className={`w-[93%] max-[570px]:w-[98%] mx-auto py-1 max-[991px]:py-2 relative max-[991px]:w-[98%] flex items-center justify-center z-0`}
        >
          {isClient && (
            <>
              {handleShowArrows(screenWidth) ? (
                <button
                  onClick={() => swiper?.slidePrev()}
                  className="absolute z-40 grid bg-black rounded-full -left-10 w-9 h-9 bg-opacity-60 place-items-center"
                >
                  <ArrowIcon className="text-white rotate-180" />
                </button>
              ) : null}
              <Swiper
                key={locale}
                onInit={(e) => set_swiper(e)}
                dir={locale === "en" ? "ltr" : "rtl"}
                spaceBetween={20}
                slidesPerView="auto"
                // centeredSlides
                // breakpoints={{
                //   320: { slidesPerView: 3, enabled: true },
                //   511: { slidesPerView: 3, enabled: true },
                //   641: { slidesPerView: 3.5, enabled: true },
                //   991: { slidesPerView: 4, enabled: true },
                //   1440: {
                //     slidesPerView: 5.5,
                //     enabled: filtedCategoryData?.length >= 7,
                //   },
                // }}
              >
                {filtedCategoryData?.map((item, idx) => {
                  return (
                    <Link key={idx} href={`/${item?.url_key}`}>
                      <SwiperSlide
                        onClick={() => {
                          onSearch("");
                          push(`/${item?.url_key}`);
                        }}
                        className={`nav-slider z-0 text-[14px] ${
                          process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme"
                            ? "bg-white text-red hover:text-white hover:bg-red"
                            : "bg-blue text-white hover:text-blue hover:bg-white"
                        } py-1 px-4 rounded-2xl my-1 font-bold  transition-all duration-500 cursor-pointer text-center overflow-hidden whitespace-nowrap border p-1.5 w-fit`}
                        title={item?.name || ""}
                      >
                        {item?.name}
                      </SwiperSlide>
                    </Link>
                  );
                })}
              </Swiper>
              {handleShowArrows(screenWidth) ? (
                <button
                  onClick={() => swiper?.slideNext()}
                  className="absolute z-40 grid bg-black rounded-full -right-10 w-9 h-9 bg-opacity-60 place-items-center"
                >
                  <ArrowIcon className="text-white" />
                </button>
              ) : null}
            </>
          )}
        </div>
        {/* <SelectCity
          {...{
            t,
            locale,
            citiesToSlect,
            setcitiesToSlect,
            setAvailableDates,
            setTargetProductDate,
            isLoadingRegions,
            isLoadingRegions,
            homeJson,
          }}
        /> */}
      </div>
    </nav>
  );
}

export default Nav;
