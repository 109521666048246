import { ModalContext } from "@/pages/_app";
import { useContext } from "react";

import Auth from "./Auth";
import Forgot from "./Forgot";
import CartInfo from "./CartInfo";
import TabbyModal from "./TabbyModal";
import TamaraModal from "./TamaraModal";
import OtpMobileModal from "./OtpMobileModal";
import Verification from "./Verification";

function Modals() {
  const Context = useContext(ModalContext);
  if (Context?.state.modal)
    if (Context?.state.cartModal) {
      return <CartInfo Context={Context} />;
    } else if (Context?.state.authModal) {
      return <Auth Context={Context} />;
    } else if (Context?.state.otpMobileModal) {
      return <OtpMobileModal Context={Context} />;
    } else if (Context?.state.forgotModal) {
      return <Forgot Context={Context} />;
    } else if (Context?.state.forgotOtpModal) {
      return <Verification Context={Context} />;
    } else if (Context?.state.tamaraModal) {
      return <TamaraModal Context={Context} />;
    } else if (Context?.state.tabbyModal) {
      return <TabbyModal Context={Context} />;
    }
}

export default Modals;
