import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

// import Style from "@/styles/home.module.css";
import { AppContext } from "@/contexts/appContexts";
import Cookies from "js-cookie";
import { fastDeliveryAndAvailableDatesLogic } from "@/utils/global";
import { useRouter } from "next/router";
import Spinner from "../icons/Spinner";
import { CLEAR_CART } from "@/services/cart";
import { useCart } from "@/contexts/cart/CartContext";
import toast from "react-hot-toast";

function SelectCity({
  responsive,
  AvailableDates,
  citiesToSlect,
  homeJson,
  t,
  setAvailableDates,
}) {
  const { setIsCityHavefastDelivery, setTargetProductDate, setRegionId } =
    useContext(AppContext);
  const { locale } = useRouter();
  const lang = locale;
  const dropdownRef = useRef(null);
  const regionId = Cookies.get("region_id");
  const quote = Cookies.get("quote");
  const [active, setActive] = useState(false);
  const [isLoadingRegions, setIsLoadingRegions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selctedCity, setSelectedCity] = useState("");
  const { dispatch } = useCart();
  useEffect(() => {
    const city = Cookies.get("region_id")
      ? citiesToSlect?.find((item) => item.value === Cookies.get("region_id"))
          ?.label
      : "";
    setSelectedCity(city);
    setRegionId(Cookies.get("region_id") || null);
    const fastDeliveryRegionsIds =
      Array.isArray(homeJson?.data?.data) &&
      homeJson?.data?.data?.length &&
      homeJson?.data?.data[0]["fast-delivery-region-ids"];

    if (citiesToSlect?.length) {
      setIsLoadingRegions(false);
    }
    if (
      fastDeliveryRegionsIds?.length &&
      fastDeliveryRegionsIds?.includes(+regionId)
    ) {
      setIsCityHavefastDelivery(true);
    } else {
      setIsCityHavefastDelivery(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    regionId,
    citiesToSlect,
    setIsCityHavefastDelivery,
    AvailableDates,
    setTargetProductDate,
    homeJson?.data?.data,
    t,
    locale,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckIfIsCityHavefastDeliveryAndUpdateProductDate = useCallback(
    (id) => {
      return fastDeliveryAndAvailableDatesLogic({
        setAvailableDates,
        setTargetProductDate,
        homeJson,
        setIsCityHavefastDelivery,
        // setSelectedCity,
        id,
        // label,
        locale: lang,
        setRegionId,
      });
    },
    [
      homeJson,
      setIsCityHavefastDelivery,
      setTargetProductDate,
      lang,
      setAvailableDates,
      setRegionId,
    ]
  );
  return (
    <div className={`relative ${responsive && "hidden max-[991px]:block"}`}>
      <button
        className={`relative border border-red w-full bg-white rounded-md p-1.5 flex items-center justify-between gap-4 ${
          responsive && "max-w-[130px]"
        }`}
        onClick={() => {
          setIsLoadingRegions(true);
          citiesToSlect && setIsLoadingRegions(false);
          setActive(!active);
        }}
      >
        <span
          className="max-md:text-xs whitespace-nowrap"
          suppressHydrationWarning
        >
          {selctedCity || t("ChooseCity")}
        </span>
        <IoMdArrowDropdown size="20px" />
      </button>
      {active ? (
        <>
          <div
            ref={dropdownRef}
            className="p-2 bg-white z-10 absolute top-[120%] w-60 rtl:-translate-x-1/4 ltr:translate-x-1/4 h-fit border-t-4 max-md:border-t-2 border-red rtl:left-0 ltr:right-0"
          >
            <div className="relative z-10 bg-white ">
              <input
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                className="w-full p-1 border rounded-lg outline-none max-md:p-1 max-md:placeholder:text-xs max-md:text-xs border-slate-200 bg-slate-100 focus:border-red"
                placeholder={
                  lang === "en"
                    ? "Select or write city name"
                    : "أكتب أو إختر المدينة"
                }
                id="dropdown-input"
                value={searchText}
              />
            </div>
            <div
              className="h-48 overflow-x-auto red-scrollbar no-back-scroll"
              id="dropdown-menu"
            >
              {!isLoadingRegions ? (
                citiesToSlect ? (
                  citiesToSlect
                    .filter((city) =>
                      city.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map((city) => (
                      <div
                        onClick={() => {
                          if (quote && regionId && regionId !== city.value) {
                            CLEAR_CART().then(({ data }) => {
                              if (data?.data?.status) {
                                dispatch({ type: "RESET_CART" });
                                toast.success(
                                  data?.msg || "cart cleared successfully"
                                );
                              }
                            });
                          }
                          Cookies.set("region_id", city.value);
                          setTimeout(() => {
                            setSearchText("");
                            setActive(false);
                            handleCheckIfIsCityHavefastDeliveryAndUpdateProductDate(
                              city?.value,
                              city?.label
                            );
                          }, 500);
                          // reload()
                        }}
                        key={city.value}
                        className="p-2 max-md:text-xs border-b-2 border-b-slate-200 last:border-b-0 last:pb-0 cursor-pointer hover:bg-gray-100 me-[.5rem]"
                      >
                        {city.label}
                      </div>
                    ))
                ) : null
              ) : (
                <span className="flex items-center justify-center gap-2">
                  <Spinner width={24} />
                </span>
              )}
            </div>
            <div
              style={{
                borderColor: "transparent red transparent transparent",
              }}
              className={`w-0 border-[9px] max-md:border-4 max-md:-top-2.5 start-1/2 h-0 rotate-90 z-0 absolute -top-5`}
            />
          </div>{" "}
        </>
      ) : null}
    </div>
  );
}

export default SelectCity;
