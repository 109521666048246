import FinalSearchSuggestion from "./final/FinalSearchSuggestion";
import InitialSearchSuggestion from "./initial/InitialSearchSuggestion";

const SearchTermsBox = ({
  openSearchTermsRef,
  inputValue,
  applySearch,
  clearSearch,
}) => {
  return (
    <div
      ref={openSearchTermsRef}
      className={`absolute inset-y-10 z-[999]  w-full start-0`}
    >
      <div className=" bg-white rounded-lg shadow-lg  max-h-[70vh] overflow-y-auto p-2 mx-2 red-scrollbar">
        {!inputValue && (
          <InitialSearchSuggestion
            applySearch={applySearch}
            clearSearch={clearSearch}
          />
        )}

        {inputValue && (
          <FinalSearchSuggestion
            applySearch={applySearch}
            inputValue={inputValue}
            clearSearch={clearSearch}
          />
        )}
      </div>
    </div>
  );
};

export default SearchTermsBox;
