import axiosRequest from "@/utils/axios-utils";
import { formatFilters, formatResponse, getSort } from "@/utils/typesense";
import { COLLECTIONS, DEFAULT_CONFIGS, FACETS } from "./config";

const searches = (locale, searchTerm, page, pageSize, filter_by, sort_by) => {
  const collections = COLLECTIONS(locale);
  const skuRegex = /^\d+$/;
  const productModelRegex = /^(?=.*\d)[A-Z0-9\-\/\.\(\)]+$/;
  const isStopTypeCorrection =
    skuRegex.test(searchTerm) || productModelRegex.test(searchTerm);

  const payload = [
    {
      query_by: "name,sku,description,brand",
      exclude_fields: "embedding,category_*",
      highlight_full_fields: "name,sku,bestseller,description",
      collection: collections.products_ar,
      q: searchTerm,
      page,
      per_page: pageSize,
      facet_by: FACETS.join(),
      filter_by,
      sort_by,
      ...DEFAULT_CONFIGS(isStopTypeCorrection),
    },
    {
      query_by: "name,sku,description,brand",
      exclude_fields: "embedding,category_*",
      highlight_full_fields: "name,sku,bestseller,description",
      collection: collections.products_en,
      q: searchTerm,
      page,
      per_page: pageSize,
      facet_by: FACETS.join(),
      filter_by,
      sort_by,
      ...DEFAULT_CONFIGS(isStopTypeCorrection),
    },
    {
      query_by: "*",
      exclude_fields: "embedding",
      highlight_full_fields: "name,sku,bestseller,description",
      collection: collections.categories_ar,
      q: searchTerm,
      page,
      per_page: pageSize,
      ...DEFAULT_CONFIGS(isStopTypeCorrection),
    },
    {
      query_by: "*",
      exclude_fields: "embedding",
      highlight_full_fields: "name,sku,bestseller,description",
      collection: collections.categories_en,
      q: searchTerm,
      page,
      per_page: pageSize,
      ...DEFAULT_CONFIGS(isStopTypeCorrection),
    },
    {
      query_by: "*",
      exclude_fields: "embedding",
      highlight_full_fields: "name",
      collection: collections.suggesstion,
      q: searchTerm,
      page,
      per_page: pageSize,
      ...DEFAULT_CONFIGS(isStopTypeCorrection),
    },
  ];

  return payload;
};

export const typeSenseMultiSearch = async (
  searchTerm,
  page = 1,
  pageSize = 30,
  filters,
  locale = "ar",
  region_id
) => {
  const regionId =
    region_id && region_id != "NaN" && region_id != "undefined"
      ? region_id
      : "1101";

  const sort_by = `${getSort(filters)?.replace("position", "name")}`;
  const filter_by = formatFilters(filters)
    ? `${formatFilters(filters)}&&price:>5&&${regionId}.value:>0`
    : `price:>5&&${regionId}.value:>0`;

  const searchesArr = searches(
    locale,
    searchTerm,
    page,
    pageSize,
    filter_by,
    sort_by
  );
  let data = JSON.stringify({
    searches: searchesArr,
  });

  let config = {
    method: "post",
    baseURL: process.env.NEXT_PUBLIC_TYPESENSE_BASEURL,
    url: "/multi_search",
    headers: {
      "Content-Type": "application/json",
      "X-TYPESENSE-API-KEY":
        process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_ONLY_API_KEY,
    },
    data,
  };

  try {
    const req = await axiosRequest(config);
    const productsRes =
      req?.data?.results?.at(0)?.found > req?.data?.results?.at(1).found
        ? req?.data?.results?.at(0)
        : req?.data?.results?.at(1);
    const categories =
      req?.data?.results?.at(2)?.found > req?.data?.results?.at(3).found
        ? req?.data?.results?.at(2)
        : req?.data?.results?.at(3);
    const res = formatResponse(productsRes || {}, categories, pageSize);
    const suggest = [
      ...new Set(
        (Array.isArray(req?.data?.results?.at(4).hits)
          ? req?.data?.results?.at(4)?.hits
          : []
        )
          ?.map((item) => item?.document?.q)
          .filter((item) => item?.length >= 3 && item !== searchTerm)
      ),
    ].slice(0, 15);
    console.log("typesense response", { ...res, suggest });
    return {
      data: { ...res, suggestedWords: suggest },
      status: req?.status,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
      error: error.response?.data,
      status: error.response.status,
    };
  }
};
