import { FixedButtons } from "@/constants/Layout";
import { useState, useContext } from "react";
import { ModalContext } from "@/pages/_app";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import Link from "next/link";

function FixedBox({ t }) {
  const { push } = useRouter();
  const { data: session } = useSession();
  const [active, setActive] = useState(false);
  const Context = useContext(ModalContext);
  return (
    <div className="fixed z-20 hidden text-white max-[991px]:block bottom-20 start-4">
      <ul className="flex items-center justify-between p-0 m-0 max-[991px]:flex-col">
        {FixedButtons.map((btn) => (
          <li
            key={btn.name}
            className={`${active ? "block" : "hidden"} 
              bg-red border border-red 
              p-4 mb-2 shadow-lg rounded-full`}
          >
            {btn.name !== "Account" ? (
              <Link
                href={btn.link}
                className="flex flex-col items-center whitespace-nowrap"
              >
                {btn.icon}
                <span className="hidden mt-1" suppressHydrationWarning>
                  {t(btn.name)}
                </span>
              </Link>
            ) : (
              <button
                aria-label={t(btn.name)}
                onClick={() =>
                  !session?.user
                    ? Context?.dispatch({ type: "authModal" })
                    : push("/user/profile")
                }
                className="flex flex-col items-center whitespace-nowrap"
              >
                {btn.icon}
                <span suppressHydrationWarning className="hidden mt-1">
                  {t(btn.name)}
                </span>
              </button>
            )}
          </li>
        ))}
      </ul>
      <button
        aria-label="Toggle Btn"
        onClick={() => setActive(!active)}
        className="flex flex-col items-center p-4 mb-2 text-white border rounded-full shadow-lg bg-red border-red whitespace-nowrap"
      >
        {!active ? <AiOutlinePlus size={20} /> : <AiOutlineMinus size={20} />}
      </button>
    </div>
  );
}

export default FixedBox;
