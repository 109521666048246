import axiosRequest from "@/utils/axios-utils";

export const topSearchTermsReq = async (handshake) => {
  let config = {
    method: "get",
    url: `/terms/topSearchTerms`,
    headers: {
      Authorization: `Bearer ${handshake}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req;
  } catch (error) {
    return error;
  }
};