import { selectedKeys } from "@/contexts/global";
import axiosRequest from "@/utils/axios-utils";
const getProductsData = async (params) => {
  const {
    token,
    categoryID,
    pageSize = 30,
    pageNo = 0,
    currentSortFilterKeys = "sortBy=position&sortDir=ASC",
    region_id = "",
  } = params;
  const regionId =
    region_id && region_id != "NaN" && region_id != "undefined"
      ? region_id
      : "1101";
  let url = `/facets/categoryV2/${categoryID}?pageSize=${pageSize}&pageNo=${pageNo}&${currentSortFilterKeys}&${selectedKeys}&regionId=${regionId}&select=${regionId}`;
  // console.log("url in getProductsData", url);
  try {
    const req = await axiosRequest({
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return req;
  } catch (error) {
    // console.error("Error in getProductsData:", error?.response?.data || error);
    return error;
  }
};

export const handleFilterArrayToString = (filters) => {
  if (!filters || Array.isArray(filters) === false) return "";

  const sortedFilters = (filters || [])?.sort((a, b) =>
    a?.code?.localeCompare(b.code)
  );

  let str = "";
  sortedFilters.forEach((item) => {
    switch (item.type) {
      case "price":
        str += `&minPrice=${item?.range?.[0]}&maxPrice=${item?.range?.[1]}`;
        break;
      case "sort":
        str += `&${item?.code}`;
        break;
      case "filter":
        str += `&${item?.code}=${item?.key}`;
        break;
      default:
    }
  });

  return str.slice(1);
};

export default getProductsData;

export const getProductsByCategoryIdV2 = async (
  handshake,
  id,
  locale,
  sortBy = "position",
  sortDir = "ASC",
  region_id,
  pageNo = 0,
  pageSize = 15
) => {
  const token = handshake ? handshake : undefined;
  const regionId =
    region_id && region_id != "NaN" && region_id != "undefined"
      ? region_id
      : "1101";
  const params = new URLSearchParams({
    select: regionId,
    regionId,
    pageSize,
    pageNo,
    sortBy,
    sortDir,
  }).toString();
  let config = {
    method: "GET",
    url: `/facets/categoryV2/${id}?${params}&${selectedKeys}&select=${regionId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const req = await axiosRequest(config);
    return req.data;
  } catch (error) {
    return error;
  }
};
