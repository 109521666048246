import { CgArrowTopRight } from "react-icons/cg";
import FilterBy from "./FilterBy";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Link from "next/link";

function Side({
  searchCategories,
  suggestWords,
  onSearch,
  filterBy,
  activeFilters,
  onFilter,
}) {
  const { t } = useTranslation("common");
  const { locale } = useRouter();

  return (
    <div className="col-span-3 p-2 bg-white rounded-md max-[768px]:hidden">
      {suggestWords?.length > 0 && (
        <div className="mb-3">
          <h3 className="mb-2 text-lg font-semibold text-red">
            {locale === "ar" ? "كلمات مقترحة" : "Suggested words"}
          </h3>{" "}
          <div className="flex flex-wrap gap-2">
            {suggestWords?.map((word, idx) => (
              <button
                onClick={() => onSearch(word)}
                key={`search_term_${word}_${(idx + 1) * 425}`}
                className="flex items-center justify-center flex-grow p-1 mb-1 border rounded-md cursor-pointer border-lightred hover:text-white hover:bg-lightred"
              >
                {word || ""}
              </button>
            ))}
          </div>
        </div>
      )}
      {/* <div className="mb-3">
        <h3 className="mb-2 text-lg font-semibold text-red">
          {t("WhatLooking")}
        </h3>
        <div className="grid grid-cols-2 max-[1200px]:grid-cols-1 gap-2">
          {suggestions?.map((item, key) =>
            item?.highlight && "name.standard" in item?.highlight ? (
              <div
                key={key}
                className="flex items-center justify-center p-1 mb-1 border rounded-md cursor-pointer border-lightred"
                onClick={() => {
                  push(`/product/${item?.rewrite_url || ""}`);
                  setProducts([]);
                  onSearch("");
                }}
              >
                <AiOutlineSearch size="20px" className="w-16 text-zinc-400" />
                <span
                  className="mx-2 font-semibold text-zinc-500 line-clamp-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      item?.highlight && "name.standard" in item?.highlight
                        ? item?.highlight["name.standard"]
                        : "",
                  }}
                />
              </div>
            ) : null
          )}
        </div>
      </div> */}
      {searchCategories?.length ? (
        <div className="mb-4 border rounded-md">
          <h4 className="px-2 py-1 text-lg font-semibold border-b text-red">
            {locale === "ar" ? "التصنيفات" : "Categories"}
          </h4>
          <div className="flex flex-col px-3">
            {searchCategories?.map((category, idx) => (
              <div
                onClick={() => onSearch("")}
                key={idx}
                className="flex items-center gap-1 my-1 hover:text-slate-600"
              >
                <CgArrowTopRight />
                <Link href={`/${category?.url_key}`}>{category?.name}</Link>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="border rounded-md">
        <h4 className="px-2 py-1 text-lg font-semibold border-b text-red">
          {t("FilterResults")}
        </h4>
        <FilterBy
          {...{
            filterBy,
            activeFilters,
            onFilter,
          }}
        />
      </div>
    </div>
  );
}

export default Side;
