import axios from "axios";

const getDeliveryDate = async (cityId) => {
  try {
    const req = await axios({
      method: "POST",
      data: { cityId },
      url: "/api/header/getAllCityDeliveryDate",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return req;
  } catch (error) {
    return error;
  }
};

export default getDeliveryDate;
