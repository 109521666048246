import { useState } from "react";
import {
  registerInitialValues,
  registerValidationSchema,
} from "@/constants/Auth";
import { Field, Form, Formik, ErrorMessage } from "formik";
import {
  AiOutlineEye,
  AiOutlineCheck,
  AiOutlineMail,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { TextError } from "../Helpers/InputContainer";
import { toast } from "react-hot-toast";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { BiPhoneCall } from "react-icons/bi";
import { registerApiRoute } from "@/services/register";
import { event_mobile_number, event_user_signup } from "@/utils/gtm";
import Link from "next/link";
// import { getSession } from "next-auth/react";

function Register({ setLogin }) {
  const { t } = useTranslation("common");
  const { locale: lang } = useRouter();
  const [accept, setAccept] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    const updatedData = {
      ...values,
      profile_picture: "string",
      dateOfBirth: "2001-01-01",
      gender: "1",
      phone_number: "966".concat(values.phone_number.slice(1)),
    };

    registerApiRoute(updatedData)
      .then((res) => {
        if (!res?.data?.status && res?.data?.status !== 200) {
          toast.error(res?.response?.data?.error?.error?.message);
        }

        const { firstName, lastName, email, phone_number } = updatedData;
        console.log("signup event", {
          firstName,
          lastName,
          phone_number,
          email,
        });
        event_user_signup(firstName, lastName, phone_number, email, null, null);

        toast.success(t("LoginWithNewAccount"));
        setLogin(true);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={registerInitialValues}
      validationSchema={registerValidationSchema(t)}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        handleSubmit(values, setSubmitting, setFieldError);
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="grid grid-cols-2 gap-3">
          <div className="max-[450px]:col-span-2">
            <label
              htmlFor="first-name"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("FirstName")}
            </label>
            <Field
              minLength={3}
              className="w-full p-2 border rounded-md outline-0"
              placeholder={t("FirstName")}
              id="first-name"
              name="firstName"
            />
            <ErrorMessage name="firstName" component={TextError} />
          </div>
          <div className="max-[450px]:col-span-2">
            <label
              htmlFor="last-name"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("LastName")}
            </label>
            <Field
              minLength={3}
              className="w-full p-2 border rounded-md outline-0"
              placeholder={t("LastName")}
              id="last-name"
              name="lastName"
            />
            <ErrorMessage name="lastName" component={TextError} />
          </div>
          <div className="col-span-2">
            <label
              htmlFor="email"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("Email")}
            </label>
            <div className="relative">
              <Field
                type="email"
                className="w-full p-2 border rounded-md outline-0"
                placeholder={t("Email")}
                id="email"
                name="email"
              />
              <AiOutlineMail
                size={24}
                className={`absolute top-1/2 -translate-y-1/2 ${
                  lang === "en" ? "right-1" : "left-1"
                } text-zinc-400 cursor-pointer`}
              />
            </div>
            <ErrorMessage name="email" component={TextError} />
          </div>
          <div className="col-span-2">
            <label
              htmlFor="phone_number"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("telephoneInput")}
            </label>
            <div className="relative">
              <Field
                type="tel"
                className={`w-full p-2 outline-0 rounded-md border ${
                  lang === "ar" ? "text-end" : "text-start"
                }`}
                placeholder={t("telephoneInput")}
                id="phone_number"
                name="phone_number"
                onChange={({ target }) => {
                  setFieldValue("phone_number", target?.value);
                  console.log("mobile", target?.value);
                  if (
                    target.value?.length === 10 &&
                    target.value?.startsWith("05")
                  ) {
                    const mobile = "966".concat(target.value.slice(1));
                    console.log({ mobile });
                    event_mobile_number(mobile);
                  }
                }}
              />
              <BiPhoneCall
                size={24}
                className={`absolute top-1/2 -translate-y-1/2 ${
                  lang === "en" ? "right-1" : "left-1"
                } text-zinc-400 cursor-pointer`}
              />
            </div>
            <ErrorMessage name="phone_number" component={TextError} />
          </div>
          <div className="col-span-2">
            <label
              htmlFor="password"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("Password")}
            </label>
            <div className="relative">
              <Field
                type={!show ? "password" : "text"}
                className="w-full p-2 border rounded-md outline-0"
                placeholder={t("Password")}
                id="password"
                name="password"
              />
              {!show ? (
                <AiOutlineEye
                  size={24}
                  onClick={() => setShow(!show)}
                  className={`absolute top-1/2 -translate-y-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } text-zinc-400 cursor-pointer`}
                />
              ) : (
                <AiOutlineEyeInvisible
                  size={24}
                  onClick={() => setShow(!show)}
                  className={`absolute top-1/2 -translate-y-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } text-zinc-400 cursor-pointer`}
                />
              )}
            </div>
            <ErrorMessage name="password" component={TextError} />
          </div>
          <div className="relative col-span-2">
            <label
              htmlFor="rep-password"
              className="block w-full mb-1 font-semibold cursor-pointer"
            >
              {t("ConfirmPassword")}
            </label>
            <div className="relative">
              <Field
                type={!showConfirm ? "password" : "text"}
                className="w-full p-2 border rounded-md outline-0"
                placeholder={t("ConfirmPassword")}
                id="rep-password"
                name="confirmPassword"
              />
              {!showConfirm ? (
                <AiOutlineEye
                  size={24}
                  onClick={() => setShowConfirm(!showConfirm)}
                  className={`absolute top-1/2 -translate-y-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } text-zinc-400 cursor-pointer`}
                />
              ) : (
                <AiOutlineEyeInvisible
                  size={24}
                  onClick={() => setShowConfirm(!show)}
                  className={`absolute top-1/2 -translate-y-1/2 ${
                    lang === "en" ? "right-1" : "left-1"
                  } text-zinc-400 cursor-pointer`}
                />
              )}
            </div>
            <ErrorMessage name="confirmPassword" component={TextError} />
          </div>
          <div className="flex items-center col-span-2">
            <div
              onClick={() => {
                setFieldValue("terms", !accept === false ? "" : !accept);
                setAccept(!accept);
              }}
              className="relative w-4 h-4 bg-white border border-gray-300 rounded-sm"
            >
              {accept && (
                <AiOutlineCheck
                  size={13}
                  className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-red"
                />
              )}
            </div>
            <Field type="checkbox" name="terms" className="hidden" />
            <span
              onClick={() => {
                setFieldValue("terms", !accept === false ? "" : !accept);
                setAccept(!accept);
              }}
              className="mx-2 cursor-pointer text-zinc-600"
            >
              {t("AcceptTerms")} {` `}
            </span>
            <Link
              href="/static/terms-conditions"
              className="underline cursor-pointer text-red"
            >
              {t("Terms")}
            </Link>
          </div>
          <ErrorMessage
            name="terms"
            component="p"
            className="-mt-2 text-sm text-red"
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full col-span-2 p-2 font-bold text-white rounded-md bg-red hover:bg-lightred disabled:cursor-wait"
          >
            {t(isSubmitting ? "Logining" : "Register")}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default Register;
