import { useRouter } from "next/router";
import { AiOutlineClose } from "react-icons/ai";
import ImgTag from "../Helpers/ImgTag";
import Circle from "../TabbyCircle";
import CurrencyIcon from "../icons/CurrencyIcon";

function TabbyModal({ Context }) {
  console.log("Context", Context);
  const { locale } = useRouter();
  return (
    <div className="fixed z-[3000] top-0 left-0 w-screen h-screen bg-lightblack flex items-center justify-center">
      <div className="bg-white max-[360px]:p-1 rounded-xl w-[800px] max-[600px]:w-full max-h-[95vh] overflow-y-auto p-4">
        <div className="flex items-center justify-between">
          <ImgTag
            // src="/svgs/tabby-title.svg"
            src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=32&h=32,fit=contain,format=auto,quality=75/tabby-title.svg`}
            className="flex-1 object-contain w-8 h-8 text-center"
            height={200}
            width={200}
            alt="Tabby Widget"
          />
          <AiOutlineClose
            onClick={() =>
              Context?.dispatch({ type: "tabbyModal", payload: null })
            }
            size={20}
            className="cursor-pointer"
          />
        </div>
        <div className="my-2 text-center">
          <h2 className="flex items-center gap-1 text-xl font-bold">
            <span>
              {locale === "en"
                ? `Split in ${
                    Context?.state.tabbyPayload?.installments
                  } interest-free payments of ${(
                    Context?.state.tabbyPayload?.price /
                    Context.state.tabbyPayload?.installments
                  )?.toFixed(2)}`
                : `أو قسمها على ${
                    Context?.state.tabbyPayload?.installments
                  } دفعات بدون فوائد بقيمة ${(
                    Context?.state.tabbyPayload?.price /
                    Context.state.tabbyPayload?.installments
                  )?.toFixed(2)}`}
            </span>
            <CurrencyIcon className="w-auto h-[1cap]" />
          </h2>
          <p className="text-zinc-600">
            {locale === "en"
              ? "Get what you love, split the cost. No fees."
              : "اشترِ ما تحب، وقسّم المبلغ. بدون رسوم."}
          </p>
        </div>
        {Context.state?.tabbyPayload?.installments === 6 ? (
          <div className="flex flex-wrap items-center justify-between gap-4 p-4">
            {Array.from({ length: 6 }).map((_, idx) => (
              <Circle
                key={idx}
                idx={idx}
                amount={(Context?.state.tabbyPayload?.price / 6 || 0)?.toFixed(
                  2
                )}
                locale={locale}
              />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-4 my-4">
            <div className="mx-auto">
              <div className="h-[50px] w-[50px] border border-zinc-700 rounded-full mx-2 relative">
                <div
                  className={`w-1/2 h-1/2 absolute rounded-tr-full bg-zinc-700 top-0 ${
                    locale === "en" ? "end-0 start-auto" : "start-0 end-auto"
                  }`}
                />
              </div>
              <h5 className="flex items-center gap-1 font-semibold text-center">
                <span>{Context?.state.tabbyPayload?.price} </span>
                <CurrencyIcon className="w-auto h-[1cap]" />
              </h5>
              <h6 className="text-sm text-center text-zinc-500">
                {locale === "en" ? "Today" : "اليوم"}
              </h6>
            </div>
            <div className="mx-auto">
              <div className="h-[50px] w-[50px] border border-zinc-700 rounded-full p-0.5 mx-2 relative">
                <div
                  className={`w-1/2 h-full absolute rounded-tr-full rounded-br-full bg-zinc-700 top-0 ${
                    locale === "en" ? "end-0 start-auto" : "start-0 end-auto"
                  }`}
                />
              </div>
              <h5 className="flex items-center gap-1 font-semibold text-center">
                <span>{Context?.state.tabbyPayload?.price} </span>
                <CurrencyIcon className="w-auto h-[1cap]" />
              </h5>
              <h6 className="text-sm text-center text-zinc-500">
                {locale === "en" ? "In 1 month" : "بعد شهر"}
              </h6>
            </div>
            <div className="mx-auto">
              <div className="h-[50px] w-[50px] border border-zinc-700 rounded-full p-0.5 mx-2 relative">
                <div
                  className={`w-1/2 h-1/2 absolute rounded-tr-full bg-zinc-700 top-0 ${
                    locale === "en" ? "end-0 start-auto" : "start-0 end-auto"
                  }`}
                />
                <div
                  className={`w-full h-1/2 absolute rounded-bl-full rounded-br-full bg-zinc-700 bottom-0 ${
                    locale === "en" ? "end-0 start-auto" : "start-0 end-auto"
                  }`}
                />
              </div>
              <h5 className="flex items-center gap-1 font-semibold text-center">
                {Context?.state.tabbyPayload?.price}{" "}
                <CurrencyIcon className="w-auto h-[1cap]" />
              </h5>
              <h6 className="text-sm text-center text-zinc-500">
                {locale === "en" ? "In 2 months" : "بعد شهرين"}
              </h6>
            </div>
            <div className="mx-auto">
              <div className="bg-white p-0.5 h-[50px] w-[50px] border border-zinc-700 rounded-full mx-auto">
                <div className="h-full w-full border-zinc-700 rounded-full p-0.5 bg-zinc-700 border"></div>
              </div>
              <h5 className="flex items-center gap-1 font-semibold text-center">
                {Context?.state.tabbyPayload?.price}{" "}
                <CurrencyIcon className="w-auto h-[1cap]" />
              </h5>
              <h6 className="text-sm text-center text-zinc-500">
                {locale === "en" ? "In 3 months" : "بعد 3 أشهر"}
              </h6>
            </div>
          </div>
        )}
        <h2 className="py-3 text-2xl font-semibold border-t">
          {locale === "en" ? "How it works:" : "طريقة الإستخدام :"}
        </h2>
        <ol>
          <li className="mb-2">
            {" "}
            <span className="w-2 h-2 p-1 bg-gray-200 rounded-md">1</span>
            <span className="mx-2">
              {locale === "en"
                ? "Choose tabby at checkout"
                : "إختر تابى قبل اتمام عملية الشراء"}
            </span>
          </li>
          <li className="mb-2">
            {" "}
            <span className="w-2 h-2 p-1 bg-gray-200 rounded-md">2</span>
            <span className="mx-2">
              {locale === "en"
                ? "Enter your information and add your debit or credit card."
                : "أدخل بياناتك و بيانات البطاقة البنكية أو البطاقة الائتمانية."}
            </span>
          </li>
          <li className="mb-2">
            {" "}
            <span className="w-2 h-2 p-1 bg-gray-200 rounded-md">3</span>
            <span className="mx-2">
              {locale === "en"
                ? `Split your purchase in ${Context.state?.tabbyPayload?.installments} monthly payments.`
                : `قسّم مشترياتك على ${Context.state?.tabbyPayload?.installments} دفعات شهرية.`}
            </span>
          </li>
          <li className="mb-2">
            {" "}
            <span className="w-2 h-2 p-1 bg-gray-200 rounded-md">4</span>
            <span className="mx-2">
              {locale === "en"
                ? "We’ll send reminders when your next payment is due."
                : "سنقوم بإرسال رسالة على هاتفك لتذكيرك بموعد الدفعة القادمة."}
            </span>
          </li>
        </ol>
        <div>
          <h5 className="pt-3 text-center border-t text-zinc-500">
            {locale === "en"
              ? "Supported payment methods"
              : "طرق الدفع المدعومة"}
          </h5>
          <div className="flex items-center justify-center">
            <ImgTag
              // src="/svgs/master-card.svg"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=40&h=40,fit=contain,format=auto,quality=75/master-card.svg`}
              className="object-contain w-10 h-10"
              height={50}
              width={50}
              alt="Master Card"
            />
            <ImgTag
              // src="/svgs/visa.svg"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=40&h=40,fit=contain,format=auto,quality=75/visa.svg`}
              className="object-contain w-10 h-10 mx-1"
              height={50}
              width={50}
              alt="Visa"
            />
            <ImgTag
              // src="/svgs/mada.svg"
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=40&h=40,fit=contain,format=auto,quality=75/mada.svg`}
              className="object-contain w-10 h-10"
              height={50}
              width={50}
              alt="Mada"
            />
            <ImgTag
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=40&h=40,fit=contain,format=auto,quality=75/american-express.svg`}
              // src="/svgs/american-express.svg"
              className="object-contain w-10 h-10 mx-1"
              height={50}
              width={50}
              alt="American Express"
            />
            <ImgTag
              src={`${process.env.NEXT_PUBLIC_CLOUD_FLAIR}/w=40&h=40,fit=contain,format=auto,quality=75/apple-pay.svg`}
              // src="/svgs/apple-pay.svg"
              className="object-contain w-10 h-10"
              height={50}
              width={50}
              alt="Apple Pay"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabbyModal;
