import { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import SocialMediaIcon from "../icons/SocialMediaIcon";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const SocialMedia = ({ selected, setSelected, locale }) => {
  const [open, setOpen] = useState(false);
  return (
    <li
      onClick={() => setSelected(4)}
      className={`flex items-center justify-center max-[570px]:justify-start gap-2 p-1.5 border ${
        selected === 4 && "border-blue"
      } rounded-md cursor-pointer`}
    >
      <span className="flex flex-col items-center justify-center">
        <span
          className={`flex ${
            open ? "flex-row" : "flex-row-reverse"
          } items-center`}
          onClick={() => setOpen(!open)}
        >
          <hr
            className={`w-10 max-[570px]:w-14 border ${
              open ? "border-green-600" : "border-red"
            }`}
          />
          {open ? (
            <IoIosCheckmarkCircleOutline className="w-4 h-4 text-green-600" />
          ) : (
            <IoCloseCircleOutline className="w-4 h-4 text-red" />
          )}
        </span>
      </span>
      <span className="flex items-center justify-center gap-1">
        <SocialMediaIcon className="w-4 h-4" />
        <span>{locale === "en" ? "Social Media" : "التواصل "}</span>
      </span>
    </li>
  );
};

export default SocialMedia;
