import NextImage from "@/components/NextImage";
import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const size = 16;

const ProductMsgs = ({ data, locale }) => {
  return (
    <Swiper
      centeredSlides={true}
      slidesPerView={1}
      loop
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      direction={"vertical"}
      modules={[Autoplay]}
      className="h-5 mb-1"
    >
      {data?.map((item) => (
        <SwiperSlide key={item.key} className="flex justify-center gap-2 h-5">
          <NextImage
            src={item?.icon}
            alt={item?.text}
            width={size}
            height={size}
            className="object-contain size-4"
          />
          <span className="text-xs line-clamp-1 flex-grow">
            {item?.[`message_${locale}`] || ""}
          </span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductMsgs;
