import { useRouter } from "next/router";
import Spinner from "./icons/Spinner";

const Loading = () => {
  const { locale } = useRouter();
  return (
    <div className="flex items-center gap-1 justify-center">
      <Spinner className="size-8" />
      <span>{locale === "en" ? "Loading..." : "جار التحميل..."}</span>
    </div>
  );
};

export default Loading;
